import { UpdateColumn } from '../../Column/types';
import { updateColumn } from '../../Column/api';
import { useUpdateCardMutation } from '../../Workspace/Board/helpers/useUpdateCardMutation';
import { DropResult } from 'react-beautiful-dnd';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useStickerCreateMutation } from './useStickerCreateMutation';
import { useAppSelector } from '../../../store/rootReducer';
import { useCallback } from 'react';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { Board } from '../../Workspace/Board/types';

export const useOnDragEnd = () => {
  const cardMutate = useUpdateCardMutation();
  const { boardId } = useParams();
  const queryClient = useQueryClient();

  const { top, left } = useAppSelector((state) => state.stickerReducer);
  const { mutate: createStickerMutation } = useStickerCreateMutation();

  const updateColumnNumber = (
    oldData: Board,
    newData: [number, UpdateColumn]
  ): Board => {
    const columnList = oldData.columnList;
    const rawOldData = Array.from(columnList);
    const prevIndex = columnList.findIndex(
      (column) => column.id === newData![0]!
    );
    const [removed] = rawOldData.splice(prevIndex, 1);
    removed.serialNumber = newData[1].serialNumber!;
    rawOldData.splice(newData![1].serialNumber! - 1, 0, removed);
    return { ...oldData, columnList: rawOldData };
  };

  const columnMutate = useMutation(
    [BOARD_URL, boardId],
    (arg: [number, UpdateColumn]) => updateColumn(...arg),
    {
      onMutate: async (data) => {
        await queryClient.cancelQueries([BOARD_URL, boardId]);

        queryClient.setQueryData<Board>([BOARD_URL, boardId], (old) =>
          updateColumnNumber(old!, data)
        );

        return queryClient.getQueryData([BOARD_URL, boardId]);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries([BOARD_URL, boardId]);
      },
    }
  );

  return useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      if (result.type === 'STICKER') {
        const cardId = result.destination.droppableId.replaceAll(
          'cardSticker',
          ''
        );

        const imageUrl = result.draggableId;
        createStickerMutation({
          top: top!,
          left: left!,
          rotate: 0,
          taskId: +cardId,
          imageUrl: imageUrl,
        });
      }
      if (result.destination.droppableId.includes('columnList')) {
        const colId = result.draggableId.replace('column', '');
        const columnList = queryClient.getQueryData<Board>([
          BOARD_URL,
          boardId?.toString(),
        ])?.columnList;

        const columnId = columnList!.find((col) => col.id === +colId)!.id;
        columnMutate.mutate([
          columnId,
          { serialNumber: result.destination.index + 1 },
        ]);
      } else if (result.destination.droppableId.includes('column')) {
        const cardId = result.draggableId.replace('card', '');
        const targetCol = result.destination.droppableId.replace('column', '');
        const sourceCol = result.source.droppableId.replace('column', '');
        const columnId = targetCol !== sourceCol ? +targetCol : undefined;
        const numberInColumn = result.destination.index;

        if (columnId || numberInColumn !== result.source.index)
          cardMutate.mutate([+cardId, { columnId, numberInColumn }]);
      }
    },
    [top]
  );
};
