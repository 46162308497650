import React, { useState } from 'react';
import { Col, Row, Spin, Typography } from 'antd';
import styled from 'styled-components';
import { useInfiniteQuery, UseMutateFunction } from 'react-query';
import { UnsplashPhoto } from '../type';
import { getUnsplashRandomPhoto, UNSPLASH } from '../api';
import { debounce } from 'lodash';
import Search from 'antd/es/input/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import API from '../../../utils/API/API';

const SearchBackground = React.memo(
  ({
    heightImage = 100,
    mutate,
  }: {
    heightImage?: number;
    mutate: UseMutateFunction<any, unknown, { backgroundUrl?: string }, any>;
  }) => {
    const [query, setQuery] = useState('');

    const { data, isLoading, fetchNextPage, error } = useInfiniteQuery<
      UnsplashPhoto[]
    >(
      [UNSPLASH, query],
      ({ pageParam = 1 }) => getUnsplashRandomPhoto(query, pageParam),
      {
        getNextPageParam: (_, allPages) => allPages.length + 1,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );

    const debounceSearch = debounce(setQuery, 500);

    return (
      <>
        <Search
          placeholder={`Поиск фотографий`}
          onChange={(e) => debounceSearch(e.target.value)}
          style={{ marginBottom: 8 }}
          enterButton
          loading={isLoading}
        />
        {error ? (
          <Typography.Text>
            Исчерпан лимит запросов в час😥. Попробуйте позднее
          </Typography.Text>
        ) : (
          <InfiniteScroll
            next={fetchNextPage}
            hasMore
            loader={<Spin />}
            className={'infinite-scroll'}
            height={
              isMobile
                ? `${heightImage * 4 - 50}px`
                : heightImage
                ? `${heightImage * 8 - 50}px`
                : 'calc(100vh - 150px)'
            }
            dataLength={16 * (data?.pages.length ?? 1)}
          >
            <Row gutter={[4, 4]} style={{ marginBottom: 8 }}>
              {data?.pages.flat().map((photo) => {
                const onClickPhoto = async () => {
                  await API.get(
                    photo.links.download_location.replace(
                      'https://api.unsplash.com',
                      'api/unsplash'
                    )
                  );
                  mutate({
                    backgroundUrl:
                      heightImage < 100 ? photo.urls.regular : photo.urls.full,
                  });
                };
                return (
                  <Col span={12} key={photo.id}>
                    <BackgroundWrapper
                      onClick={onClickPhoto}
                      $photoUrl={photo.urls.thumb}
                      $height={heightImage}
                    >
                      <div>
                        <Link
                          to={
                            photo.user.links.html +
                            '?utm_source=Yambook&utm_medium=referral'
                          }
                        >
                          {photo.user.username}
                        </Link>
                      </div>
                    </BackgroundWrapper>
                  </Col>
                );
              })}
            </Row>
          </InfiniteScroll>
        )}
      </>
    );
  }
);
SearchBackground.displayName = 'SearchBackground';
export default SearchBackground;

const BackgroundWrapper = styled.div<{ $photoUrl: string; $height: number }>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.$photoUrl});
  width: 100%;
  height: ${(props) => props.$height}px;
  border-radius: 4px;
  cursor: pointer;

  & div {
    visibility: hidden;
    background-color: rgb(95, 95, 95);
    backdrop-filter: blur(1px);

    & a {
      color: white;
    }
  }

  &:hover {
    opacity: 0.9;
    transition: 0.3ms;

    & div {
      font-size: 0.9em;
      padding: 4px 8px;
      visibility: initial;
    }
  }
`;
