import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Avatar,
  Button,
  Form,
  Input,
  Popover,
  Progress,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Card } from '../type';
import { CARD_URL } from '../api';
import { isMobile } from 'react-device-detect';
import { useCreateSubCard } from '../helpers/useCreateSubCard';
import { Board } from '../../Workspace/Board/types';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { Column, ColumnStatus } from '../../Column/types';
import { useEditSubCard } from '../helpers/useEditSubCard';
import AddMemberSubCard from './AddMembersSubCard';
import { useGetWorkplaceId, useRole } from '../../../utils/helpers/useRole';

const Subcard = React.memo(
  ({
    card,
    isAdd,
    setIsAdd,
  }: {
    card: Card;
    isAdd: boolean;
    setIsAdd: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const queryKey = [CARD_URL, 'once', card.id.toString()];
    const { boardId } = useParams();
    const [openCardId, setOpenCardId] = useState<number>();

    const { mutateAsync: createSubTaskMutation } = useCreateSubCard(queryKey);

    const { mutate: editColumnSubCardMutation } = useEditSubCard(queryKey);

    const queryClient = useQueryClient();

    const getColor = (status: ColumnStatus) => {
      switch (status) {
        case 'COMPLETE':
          return 'green';
        case 'IN_PROGRESS':
          return 'blue';
        case 'TO_DO':
          return undefined;
        default:
          return 'cyan';
      }
    };

    const workplaceId = useGetWorkplaceId();
    const role = useRole(workplaceId ?? 0);

    const columnList = queryClient.getQueryData<Board>([BOARD_URL, boardId])
      ?.columnList;

    const getTag = (col: Column) => (
      <Tag color={getColor(col.status)} style={{ maxWidth: 100 }}>
        {
          <Typography.Text
            style={{ color: 'inherit', fontSize: 'inherit' }}
            ellipsis={true}
          >
            {col.title}
          </Typography.Text>
        }
      </Tag>
    );

    const colOptions = columnList?.map((col) => {
      return {
        label: (
          <Tooltip title={col.title} mouseEnterDelay={0.5}>
            {getTag(col)}
          </Tooltip>
        ),
        value: col.id,
      };
    });

    const onFinish = (values: { title: string }) => {
      const column = queryClient
        .getQueryData<Board>([BOARD_URL, boardId])!
        .columnList.find((col) => col.status === 'TO_DO');
      createSubTaskMutation({
        ...values,
        columnId: column!.id,
        taskId: card.id,
        numberInColumn: column?.task.length ? column?.task.length - 1 : 0,
      });
    };

    const getPercent = () => {
      const isCompleteCount =
        card.subTaskList.filter((item) => item.isCompleted)?.length ?? 0;
      if (isCompleteCount === 0) return 0;
      return +((isCompleteCount / card.subTaskList.length) * 100).toFixed(2);
    };

    return (
      <ChecklistWrapper>
        <Row justify={'space-between'}>
          <Typography.Title level={5}>
            <UnorderedListOutlined style={{ marginRight: 8 }} />
            Подзадачи
          </Typography.Title>
        </Row>
        <Space direction={'vertical'} style={{ width: '100%' }}>
          {!!card.subTaskList?.length && (
            <Progress style={{ paddingRight: 16 }} percent={getPercent()} />
          )}
          {card.subTaskList.map((item) => (
            <Row wrap={false} justify={'space-between'} key={item.id}>
              <Space>
                <Typography.Text delete={item.isCompleted}>
                  <Tag>{item.serialNumber}</Tag>
                  <Link to={`/board/${boardId}/card/${item.id}`}>
                    {item.title}
                  </Link>
                </Typography.Text>
              </Space>
              <Space>
                <Popover
                  placement={'top'}
                  trigger={['click']}
                  open={role === 'user' ? false : openCardId === item.id}
                  arrow={false}
                  onOpenChange={(value) =>
                    setOpenCardId(value ? item.id : undefined)
                  }
                  content={
                    <AddMemberSubCard
                      card={item}
                      cancelAdd={() => setOpenCardId(undefined)}
                    />
                  }
                >
                  <>
                    {item.accountToTask?.length ? (
                      <Avatar.Group
                        maxCount={2}
                        maxStyle={{
                          color: '#f56a00',
                          backgroundColor: '#fde3cf',
                        }}
                      >
                        {item.accountToTask.map((account) => (
                          <Tooltip
                            key={account.id}
                            title={account.account.personFullName}
                          >
                            <Avatar
                              size={'small'}
                              icon={<UserOutlined />}
                              src={account?.account?.avatarUrl}
                            />
                          </Tooltip>
                        ))}
                      </Avatar.Group>
                    ) : (
                      role !== 'user' && (
                        <Tooltip title={'Добавить участников'}>
                          <Button
                            icon={<UsergroupAddOutlined />}
                            type={'text'}
                            shape={'circle'}
                          />
                        </Tooltip>
                      )
                    )}
                  </>
                </Popover>
                {role !== 'user' ? (
                  <Select
                    bordered={false}
                    options={colOptions}
                    value={item.column?.id}
                    onChange={(value) => {
                      editColumnSubCardMutation([
                        item.id,
                        {
                          columnId: value,
                          isCompleted:
                            columnList?.find((col) => col.id === value)
                              ?.status === 'COMPLETE',
                        },
                      ]);
                    }}
                  />
                ) : (
                  <div style={{ marginLeft: 8 }}>{getTag(item.column)}</div>
                )}
              </Space>
            </Row>
          ))}
          {isAdd && (
            <Form onFinish={onFinish}>
              <Form.Item
                name={'title'}
                rules={[
                  { message: 'Поле не может быть пустым', required: true },
                ]}
              >
                <Input
                  onKeyDown={(e) => e.stopPropagation()}
                  autoFocus
                  placeholder={'Введите описание подзадачи'}
                />
              </Form.Item>
              <Space>
                <Button type={'primary'} htmlType={'submit'}>
                  Добавить
                </Button>
                <Button onClick={() => setIsAdd(false)}>Отменить</Button>
              </Space>
            </Form>
          )}
        </Space>
      </ChecklistWrapper>
    );
  }
);
Subcard.displayName = 'Subcard';
export default Subcard;

const ChecklistWrapper = styled.div`
  margin-left: ${isMobile ? 0 : '24px'};
`;
