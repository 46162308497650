import { useMutation, useQueryClient } from 'react-query';
import { notification } from 'antd';
import { createStickerToTask } from '../api';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';
import { Board } from '../../Workspace/Board/types';
import { produce } from 'immer';
import { getCurrentColumnAndCard } from '../../Card/helpers/getCurrentColumnAndCard';

export const useStickerCreateMutation = () => {
  const { boardId } = useParams();
  const queryKeyFirst = [BOARD_URL, boardId];
  const queryClient = useQueryClient();

  return useMutation(queryKeyFirst, createStickerToTask, {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKeyFirst);
    },
    onSuccess: (data) => {
      queryClient.setQueryData<Board>(
        queryKeyFirst,
        // @ts-ignore
        (oldData) => {
          return produce(oldData!, (draft) => {
            const { currentCard } = getCurrentColumnAndCard(draft, data.taskId);
            currentCard.stickerToTask.splice(-1);
            currentCard.stickerToTask.push(data);
          });
        }
      );
    },
    onError: async () => {
      await queryClient.invalidateQueries(queryKeyFirst);
      notification.error({
        message: 'Ошибка при работе со стикерами',
      });
    },
  });
};
