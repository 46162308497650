import { updateWorkplace, WORKPLACE_URL } from '../api/WorkplaceApi';
import { UpdateWorkplace, Workplace } from '../../types';
import { useMutation, useQueryClient } from 'react-query';
import { Card as CardType } from '../../../Card/type';
import { notification } from 'antd';
import { useAppSelector } from '../../../../store/rootReducer';

export const useWorkplaceMutation = (workplaceId: string) => {
  const queryClient = useQueryClient();
  const { profile } = useAppSelector((state) => state.authReducer);
  return useMutation(
    [WORKPLACE_URL, workplaceId],
    (arg: [number, UpdateWorkplace]) => updateWorkplace(...arg),
    {
      onMutate: async (newData) => {
        await queryClient.cancelQueries([WORKPLACE_URL]);
        const prevValues = queryClient.getQueryData([
          WORKPLACE_URL,
          workplaceId,
        ]);
        queryClient.setQueryData<CardType>(
          [WORKPLACE_URL, workplaceId],
          // @ts-ignore
          (oldData) => ({
            ...oldData,
            ...newData[1],
          })
        );

        return prevValues;
      },
      onSuccess: (data) => {
        queryClient.setQueryData<Workplace>(
          [WORKPLACE_URL, workplaceId],
          // @ts-ignore
          () => data
        );
        queryClient.setQueryData<Workplace[]>(
          [WORKPLACE_URL, { profileId: profile?.id }],
          // @ts-ignore
          (old) => {
            const copyWorkplace = Array.from(old ?? []);
            const idx = copyWorkplace.findIndex(
              (workplace) => workplace.id === +workplaceId
            );
            copyWorkplace[idx] = { ...copyWorkplace[idx], ...data };
            return copyWorkplace;
          }
        );
      },
      onError: (error, variables, context) => {
        notification.error({
          message: 'Ошибка при изменении рабочего пространства',
        });
        queryClient.setQueryData<Workplace>(
          [WORKPLACE_URL, workplaceId],
          // @ts-ignore
          () => context
        );
      },
    }
  );
};
