import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { ResponseBody } from '../../../../GlobalTypes';
import { getNotificationList, NOTIFICATION_URL } from '../api';
import {
  Button,
  Checkbox,
  Col,
  Empty,
  Row,
  Space,
  Spin,
  Switch,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CardWrapper } from '../../Card/components/SimpleCard';
import { Notification, NotificationType } from '../type';
import CenteredSpin from '../../../utils/Copmonents/CenteredSpin';
import { useNotificationMutation } from '../helpers/useNotificationMutation';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../styles/Fade.css';

const getType = (type: NotificationType) => {
  switch (type) {
    case 'comment':
      return 'Новый комментарий';
    case 'add_account':
      return 'Вас добавили к задаче';
    case 'archive':
      return 'Карточка перемещена в архив';
    case 'attachments':
      return 'Новое вложение';
    case 'change_date':
      return 'Изменена дата';
    case 'change_location':
      return 'Карточка была перемещена';
    case 'complete_date':
      return 'Дата выполнения подходит к концу';
    case 'delete_account':
      return 'Вас удалили из задачи';
  }
};

const { useToken } = theme;

const NotificationList = React.memo(() => {
  const [isView, setIsView] = useState(false);
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery<
    ResponseBody<Notification[]>
  >(
    [NOTIFICATION_URL, isView],
    ({ pageParam = 1 }) =>
      getNotificationList({ page: pageParam, size: 10, isView: isView }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage < lastPage.meta.totalPages
          ? lastPage.meta.currentPage + 1
          : undefined,
    }
  );

  const formatData = data?.pages.map((v) => v.content).flat();
  const [isEmpty, setIsEmpty] = useState(true);

  const {
    mutate,
    isLoading: isLoadingUpdate,
    variables,
  } = useNotificationMutation(isView, formatData);

  const { token } = useToken();

  useEffect(() => {
    setTimeout(() => {
      const isEmpty = !formatData?.length;
      setIsEmpty(isEmpty);
    }, 300);
  }, [data]);

  const dataLength = data?.pages.map((v) => v.content).flat().length ?? 30;

  const onChange = (id: number, checked: boolean) => {
    mutate({ idList: [id], isView: !checked });
  };

  const onAllChange = () => {
    mutate({
      idList:
        data?.pages
          .map((el) => el.content)
          .flat()
          .map((el) => el.id) ?? [],
      isView: true,
    });
  };

  return (
    <Spin spinning={isLoading}>
      <div style={{ padding: '0 12px' }}>
        <Typography.Title level={5}>Уведомления</Typography.Title>
        <Row justify={'end'} style={{ marginBottom: 8, fontSize: '0.75em' }}>
          <Col>
            <Typography.Text type={'secondary'}>
              Показать просмотренные уведомления
            </Typography.Text>{' '}
            <Switch size={'small'} onChange={setIsView} />
          </Col>
        </Row>
        {!!data?.pages[0].content.length && !isView && !isLoading && (
          <Row justify={'end'} style={{ marginBottom: 4 }}>
            <Button
              type={'text'}
              size={'small'}
              style={{ color: token.colorTextSecondary, fontSize: '0.75rem' }}
              onClick={onAllChange}
            >
              Пометить все как прочитанное
            </Button>
          </Row>
        )}
      </div>
      <InfiniteScroll
        next={fetchNextPage}
        hasMore={!!hasNextPage}
        className={'infinite-scroll'}
        loader={<CenteredSpin spinning={true} />}
        height={isMobile ? 300 : 500}
        dataLength={dataLength}
      >
        <Space style={{ width: '100%' }} direction={'vertical'} size={0}>
          <TransitionGroup>
            {formatData?.map((notification) => (
              <CSSTransition
                timeout={300}
                classNames="fade"
                unmountOnExit
                key={notification.id}
              >
                <NotificationItemWrapper
                  $bgColor={
                    notification.viewDate ? undefined : token.colorPrimaryBg
                  }
                >
                  <Link
                    to={`board/${notification.task.column.board.id}/card/${notification.taskId}`}
                    style={{ color: 'inherit', display: 'block' }}
                  >
                    <BgWrapper
                      $bgColor={
                        notification.task.column.board.backgroundColor ??
                        undefined
                      }
                      $bgUrl={
                        notification.task.column.board.backgroundUrl ?? ''
                      }
                    >
                      <TitleCardWrapper>
                        <Typography.Text strong>
                          {notification.task.title}
                        </Typography.Text>
                      </TitleCardWrapper>
                      <InfoWrapper>
                        <Typography.Text strong type={'secondary'}>
                          {notification.task.column.board.title}
                        </Typography.Text>
                        <Typography.Text type={'secondary'}>
                          {': '}
                        </Typography.Text>
                        <Typography.Text type={'secondary'}>
                          {notification.task.column.title}
                        </Typography.Text>
                      </InfoWrapper>
                      <NotificationWrapper>
                        <Typography.Text strong>
                          {getType(notification.type)}
                        </Typography.Text>
                      </NotificationWrapper>
                    </BgWrapper>
                  </Link>
                  <Tooltip
                    title={
                      notification.viewDate
                        ? 'Пометить как непрочитанное'
                        : 'Пометить как прочитанное'
                    }
                  >
                    <Checkbox
                      disabled={
                        isLoadingUpdate &&
                        (variables?.idList[0] === notification.id ||
                          (variables?.idList.length ?? 0) > 1)
                      }
                      onChange={(event) =>
                        onChange(notification.id, event.target.checked)
                      }
                      checked={!notification.viewDate}
                    />
                  </Tooltip>
                </NotificationItemWrapper>
              </CSSTransition>
            ))}
          </TransitionGroup>
          {isEmpty && formatData?.length === 0 && <Empty />}
        </Space>
      </InfiniteScroll>
    </Spin>
  );
});

const BgWrapper = styled(CardWrapper)<{
  $bgUrl: string | undefined;
  $bgColor: string | undefined;
}>`
  width: 250px;
  margin: auto;
  background-image: url('${(props) => props.$bgUrl ?? ''}');
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${(props) => props.$bgColor};
  padding: 8px 0 0;
  display: block;
`;

const TitleCardWrapper = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 8px;
  margin: 0 8px;
`;

const InfoWrapper = styled.div`
  backdrop-filter: blur(14px);
  background-color: rgba(245, 245, 245, 0.22);
  height: 24px;
  padding: 2px 10px;
  color: white;
`;

const NotificationWrapper = styled.div`
  background-color: #f1f1f1;
  border-radius: 0 0 8px 8px;
  padding: 8px 12px;
`;

const NotificationItemWrapper = styled.div<{
  $bgColor: string | undefined;
}>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 6px 12px;
  gap: 8px;
  background-color: ${(props) => props.$bgColor};
`;

NotificationList.displayName = 'NotificationList';
export default NotificationList;
