import React from 'react';
import { Button, Popover, Skeleton, Space, Typography } from 'antd';
import { Workplace } from '../../Workspace/types';
import { TeamOutlined } from '@ant-design/icons';
import MembersView from '../../Workspace/Home/components/MembersView';
import { useQuery } from 'react-query';
import {
  getWorkplace,
  WORKPLACE_URL,
} from '../../Workspace/Home/api/WorkplaceApi';
import { Board } from '../../Workspace/Board/types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Menu = React.memo(({ boardData }: { boardData?: Board }) => {
  const { data: workplaceData, isLoading } = useQuery<Workplace>(
    [WORKPLACE_URL, boardData?.workplaceId.toString()],
    () => getWorkplace(boardData?.workplaceId ?? 0)
  );

  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <div>
        <Typography.Title level={5}>
          Рабочее пространство {boardData?.workplace.title}
        </Typography.Title>
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Popover
            arrow={false}
            overlayStyle={{ width: 500 }}
            placement={'right'}
            trigger={'click'}
            content={<MembersView workplace={workplaceData} />}
          >
            <Button type={'text'} style={{ width: '100%', textAlign: 'left' }}>
              <TeamOutlined />
              Управление участниками
            </Button>
          </Popover>
        </Space>
      </div>
      <div>
        <Typography.Title level={5}>Мои доски</Typography.Title>
        <Skeleton
          loading={isLoading}
          active
          title={false}
          paragraph={{ width: [150, 150, 150] }}
        >
          <Space direction={'vertical'} style={{ width: '100%' }}>
            {workplaceData?.boardList?.map((board) => (
              <Link key={board.id} to={`/board/${board.id}`}>
                <Button
                  type={'text'}
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  <Space>
                    <WallpaperBoardWrapper
                      $bgUrl={board.backgroundUrl}
                      $bgColor={board.backgroundColor}
                    />
                    {board.title}
                  </Space>
                </Button>
              </Link>
            ))}
          </Space>
        </Skeleton>
      </div>
    </Space>
  );
});

const WallpaperBoardWrapper = styled.div<{
  $bgUrl: string | null;
  $bgColor: string | null;
}>`
  border-radius: 4px;
  width: 30px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('${(props) => props.$bgUrl ?? ''}');
  background-color: ${(props) => props.$bgColor};
`;

Menu.displayName = 'Menu';
export default Menu;
