import React, { useState } from 'react';
import { Button, DatePicker, Form } from 'antd';
import { useQuery } from 'react-query';
import { Task } from 'gantt-task-react';
import { GANTT_URL, getGanttTaskList } from '../api';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

const FilterGantt = React.memo(() => {
  const currentDate: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs().startOf('month'),
    dayjs().endOf('month'),
  ];
  const [date, setDate] = useState<string[] | undefined>(
    currentDate.map((date) => date.format())
  );
  const { boardId: id } = useParams();
  const params = { boardId: +id!, date: date?.join() };
  const { refetch } = useQuery<Task[]>(
    [GANTT_URL, params],
    () => getGanttTaskList(params),
    { enabled: false }
  );

  return (
    <Form onFinish={refetch}>
      <Form.Item name={'date'} initialValue={currentDate}>
        <DatePicker.RangePicker
          format={'DD.MM.YYYY'}
          onChange={(value) =>
            setDate(
              value
                ? [dayjs(value[0]).format(), dayjs(value[1]).format()]
                : undefined
            )
          }
        />
      </Form.Item>
      <Form.Item label={'Календарная неделя'}>
        <DatePicker
          picker={'week'}
          onChange={(value) => {
            setDate(
              value
                ? [
                    dayjs(value).startOf('week').format(),
                    dayjs(value).endOf('week').format(),
                  ]
                : []
            );
          }}
        />
      </Form.Item>
      <Button type={'primary'} htmlType={'submit'}>
        Фильтровать
      </Button>
    </Form>
  );
});
FilterGantt.displayName = 'FilterGantt';
export default FilterGantt;
