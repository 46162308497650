import { useMutation, useQueryClient } from 'react-query';
import { deleteStickerToTask } from '../api';
import { Card } from '../../Card/type';
import { notification } from 'antd';
import { Board } from '../../Workspace/Board/types';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';
import { produce } from 'immer';
import { getCurrentColumnAndCard } from '../../Card/helpers/getCurrentColumnAndCard';

export const useDeleteStickerMutation = (queryKey: any[], cardId: number) => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();

  const cardView = queryKey[1] === 'once';

  const setInCard = (oldData: Card | undefined, data: number): Card => {
    return {
      ...oldData!,
      stickerToTask:
        oldData?.stickerToTask?.filter((sticker) => sticker.id !== data) ?? [],
    };
  };

  return useMutation(queryKey, deleteStickerToTask, {
    onMutate: async (data) => {
      await queryClient.cancelQueries(queryKey);

      const prevValue = queryClient.getQueryData<Card[] | Card>(queryKey);

      cardView
        ? queryClient.setQueryData<Card>(
            queryKey,
            // @ts-ignore
            (oldData) => {
              return setInCard(oldData, data);
            }
          )
        : queryClient.setQueryData<Board>(
            [BOARD_URL, boardId],
            // @ts-ignore
            (oldData) => {
              return produce(oldData!, (draft) => {
                const { currentCard } = getCurrentColumnAndCard(draft, cardId);
                currentCard.stickerToTask = currentCard.stickerToTask.filter(
                  (stiker) => stiker.id !== data
                );
              });
            }
          );

      return prevValue;
    },
    onError: async (error, variables, context) => {
      queryClient.setQueryData<Card[] | Card>(queryKey, () => context ?? []);
      notification.error({
        message: 'Ошибка при работе со стикерами',
      });
    },
  });
};
