import { useMutation, useQueryClient } from 'react-query';
import { CARD_URL, createCheckList } from '../api';
import { Card } from '../type';
import { notification } from 'antd';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { Board } from '../../Workspace/Board/types';
import { useParams } from 'react-router-dom';
import { produce } from 'immer';

export const useCreateChecklistMutation = (queryKey: any[]) => {
  const queryClient = useQueryClient();
  const { boardId, id } = useParams();

  return useMutation(queryKey, createCheckList, {
    onMutate: async () => {
      const cardData = queryClient.getQueryData<Card>(queryKey);

      await queryClient.cancelQueries([CARD_URL]);
      queryClient.setQueryData<Card>(
        queryKey,
        // @ts-ignore
        (old) => {
          return { ...old, checklist: { id: 0, checklistItems: [] } };
        }
      );

      return cardData;
    },
    onError: async (error, variables, context) => {
      notification.error({ message: 'Ошибка' });
      queryClient.setQueryData<Card>(queryKey, () => context!);
      await queryClient.invalidateQueries([BOARD_URL]);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, (old) => ({
        ...old!,
        checklist: data,
      }));
      queryClient.setQueryData<Board>([BOARD_URL, boardId], (oldData) => {
        return produce(oldData!, (draft) => {
          const currentCard = draft.columnList
            .flatMap((col) => col.task)
            .find((card) => card.id === +id!)!;
          currentCard.checklist = { ...data, checklistItems: [] };
        });
      });
    },
  });
};
