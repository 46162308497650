import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { CARD_URL, getCardListNoCol } from '../api';
import { Link, useParams } from 'react-router-dom';
import { Card as CardType } from '../type';
import styled from 'styled-components';
import {
  Button,
  Col,
  Empty,
  Form,
  Popconfirm,
  Popover,
  Row,
  Skeleton,
  Space,
  Tooltip,
} from 'antd';
import { isMobile } from 'react-device-detect';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';
import { useCreateSimpleCardMutation } from '../helpers/useCreateSimpleCardMutation';
import { Column } from '../../Column/types';
import SimpleCard from './SimpleCard';
import { useAppSelector } from '../../../store/rootReducer';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { Board } from '../../Workspace/Board/types';
import { useDeleteTemplateMutation } from '../helpers/useDeleteTemplateMutation';
import ChecklistInCreateCard from './ChecklistInCreateCard';

const CreateCardByTemplate = React.memo(({ column }: { column: Column }) => {
  const { boardId } = useParams();
  const queryKey = [CARD_URL, { isTemplate: true, columnId: column.id }];
  const {
    data: cardList,
    refetch,
    isLoading: isLoadingData,
  } = useQuery<CardType[]>(
    queryKey,
    () => getCardListNoCol({ size: 999, isTemplate: true, boardId }, true),
    { enabled: false }
  );
  const [currentTemplate, setCurrentTemplate] = useState<CardType | null>(null);
  const { mutateAsync: createMutation, isLoading } =
    useCreateSimpleCardMutation();
  const { mutate: deleteMutation, isLoading: deleteLoading } =
    useDeleteTemplateMutation(queryKey, [
      CARD_URL,
      'list',
      column.id.toString(),
    ]);
  const [title, setTitle] = useState('');
  const [isMember, setIsMember] = useState(true);
  const [isCheckItem, setIsCheckItem] = useState(true);
  const [isLabel, setIsLabel] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const board = queryClient.getQueryData<Board>([BOARD_URL, boardId]);
  const { profile } = useAppSelector((state) => state.authReducer);

  const getFormatCard = (card: CardType): CardType => {
    return {
      ...card,
      startDate: undefined,
      completeDate: null,
      gitlabMergeRequestList: [],
      gitlabCommit: [],
      serialNumber: undefined,
      title: title,
      labelToTask: isLabel ? card?.labelToTask ?? [] : [],
      accountToTask: isMember ? card?.accountToTask ?? [] : [],
      comments: [],
      checklist:
        isCheckItem && card?.checklist
          ? {
              ...card!.checklist,
              checklistItems: card!.checklist?.checklistItems.map((item) => ({
                ...item,
                isCompleted: false,
              })),
            }
          : undefined,
    };
  };

  const onFinish = () => {
    const createValue = {
      title: title,
      columnId: column.id,
      memberIdList: isMember
        ? currentTemplate?.accountToTask.map((acc) => acc.accountId)
        : undefined,
      description: currentTemplate?.description,
      checklistItems: isCheckItem
        ? currentTemplate?.checklist?.checklistItems.map((item) => ({
            title: item.title,
          }))
        : undefined,
      labelList: isLabel
        ? currentTemplate?.labelToTask.map((label) => label.labelId)
        : undefined,
      backgroundColor: currentTemplate?.backgroundColor ?? undefined,
      backgroundUrl: currentTemplate?.backgroundUrl ?? undefined,
      stickerList: currentTemplate?.stickerToTask.map((sticker) => ({
        ...sticker,
        id: undefined,
        taskId: undefined,
      })),
    };
    createMutation(createValue).then(() => {
      setOpen(false);
    });
  };

  const onDelete = (cardId: number) => {
    deleteMutation(cardId);
  };

  const templateView = (
    <CardListWrapper>
      <Skeleton loading={isLoadingData}>
        {cardList?.length ? (
          cardList?.map((card) => (
            <div style={{ display: 'flex', gap: 4 }} key={card.id}>
              <div
                style={{ flex: isEdit ? '90%' : '100%' }}
                onClick={() => onClickTemplate(card)}
              >
                <SimpleCard
                  card={{
                    ...card,
                    startDate: undefined,
                    completeDate: null,
                    serialNumber: undefined,
                    checklist: card?.checklist
                      ? {
                          ...card!.checklist,
                          checklistItems: card!.checklist?.checklistItems.map(
                            (item) => ({
                              ...item,
                              isCompleted: false,
                            })
                          ),
                        }
                      : undefined,
                    comments: [],
                  }}
                />
              </div>
              {isEdit && (
                <Space direction={'vertical'}>
                  <Link to={`card/${card.id}`} onClick={() => setOpen(false)}>
                    <Button size={'small'} icon={<EditOutlined />} />
                  </Link>
                  {(profile?.id === card.authorId ||
                    profile?.id === board?.authorId) && (
                    <Popconfirm
                      title="Удаление карточки"
                      description="Вы действительно хотите удалить данную карточку?"
                      onConfirm={() => onDelete(card.id)}
                      okButtonProps={{ loading: deleteLoading }}
                      okText="Да"
                      cancelText="Нет"
                    >
                      <Button size={'small'} icon={<DeleteOutlined />} />
                    </Popconfirm>
                  )}
                </Space>
              )}
            </div>
          ))
        ) : (
          <Empty />
        )}
        {!!cardList?.length && (
          <Button block onClick={() => setIsEdit((prevState) => !prevState)}>
            {isEdit ? 'Готово' : 'Редактировать шаблоны'}
          </Button>
        )}
      </Skeleton>
    </CardListWrapper>
  );

  const viewCheckList = !!(
    currentTemplate?.accountToTask.length ||
    currentTemplate?.checklist?.checklistItems.length ||
    currentTemplate?.labelToTask.length
  );

  const createView = (
    <CardListWrapper>
      <SimpleCard
        setTitle={setTitle}
        outerTitle={title}
        card={getFormatCard(currentTemplate!)}
      />
      <Form onFinish={onFinish}>
        {viewCheckList && (
          <ChecklistInCreateCard
            setIsLabel={setIsLabel}
            setIsMember={setIsMember}
            setIsCheckItem={setIsCheckItem}
            card={currentTemplate}
          />
        )}
        <Form.Item>
          <Button
            type={'primary'}
            block
            htmlType={'submit'}
            loading={isLoading}
          >
            Создать
          </Button>
        </Form.Item>
      </Form>
    </CardListWrapper>
  );

  const onClickTemplate = (card: CardType) => {
    setCurrentTemplate(card);
    setTitle(card.title);
  };

  const onOpenChange = (open: boolean) => {
    if (!open) {
      setCurrentTemplate(null);
      setOpen(false);
    } else {
      refetch();
      setOpen(true);
    }
  };

  return (
    <Popover
      arrow={false}
      title={
        currentTemplate ? (
          <Row>
            <Col span={2}>
              <Button
                icon={<ArrowLeftOutlined />}
                size={'small'}
                type={'text'}
                onClick={() => setCurrentTemplate(null)}
              />
            </Col>
            <Col span={22} style={{ textAlign: 'center' }}>
              Создать карточку
            </Col>
          </Row>
        ) : (
          'Шаблоны карточек'
        )
      }
      open={open}
      trigger={'click'}
      placement={isMobile ? 'bottom' : 'right'}
      destroyTooltipOnHide={true}
      content={currentTemplate ? createView : templateView}
      onOpenChange={onOpenChange}
    >
      <Tooltip title={'Создать по шаблону'}>
        <Button type={'text'} icon={<SnippetsOutlined />} size={'small'} />
      </Tooltip>
    </Popover>
  );
});

CreateCardByTemplate.displayName = 'CreateCardByTemplate';
export default CreateCardByTemplate;

const CardListWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 220px;
  box-sizing: border-box;
`;
