import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { CARD_URL, createCard } from '../api';
import { Card } from '../type';
import { notification } from 'antd';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { Board } from '../../Workspace/Board/types';
import { produce } from 'immer';
import dayjs from 'dayjs';

export const useCreateSubCard = (queryKey: any[]) => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();

  return useMutation(queryKey, createCard, {
    onMutate: async (variables) => {
      const cardData = queryClient.getQueryData<Card>(queryKey);
      const column = queryClient
        .getQueryData<Board>([BOARD_URL, boardId])!
        .columnList.find((col) => col.id === variables.columnId);

      await queryClient.cancelQueries([CARD_URL]);
      queryClient.setQueryData<Card>(
        queryKey,
        // @ts-ignore
        (old) => {
          return {
            ...old,
            subTaskList: [
              ...old!.subTaskList,
              {
                id: 0,
                column: { ...column },
                title: variables.title,
                accountToTask: [],
              },
            ],
          };
        }
      );

      queryClient.setQueryData<Board>(
        [BOARD_URL, boardId],
        // @ts-ignore
        (old) => {
          return produce(old!, (draft) => {
            const cardMutate = {
              id: 0,
              title: variables.title,
              accountToTask: [],
              isCompleted: false,
              creationTime: dayjs().toDate(),
              completeDate: null,
              authorId: 0,
              numberInColumn: variables.numberInColumn ?? 0,
              columnId: 0,
              backgroundUrl: null,
              comments: [],
              gitlabCommit: [],
              gitlabMergeRequestList: [],
              labelToTask: [],
              archived: false,
              isTemplate: false,
              isVisible: false,
              fileList: [],
              stickerToTask: [],
              dueReminder: null,
              subTaskList: [],
              updateTime: dayjs().toDate(),
              serialNumber: undefined,
              column: {
                id: variables.columnId,
                title: column!.title,
                boardId: +boardId!,
                serialNumber: column!.serialNumber,
                status: column!.status,
                task: [],
              },
              parentTaskId: variables.taskId!,
            };
            draft.columnList
              .find((col) => col.id === variables.columnId)!
              .task.push(cardMutate);

            draft.columnList
              .find((col) => col.id === cardData?.columnId)
              ?.task.find((task) => task.id === variables.taskId)
              ?.subTaskList.push({
                id: 0,
                title: variables.title,
                accountToTask: [],
                column: cardMutate.column,
                isCompleted: false,
                serialNumber: 0,
              });
          });
        }
      );

      return cardData;
    },
    onError: async (error, variables, context) => {
      notification.error({ message: 'Ошибка' });
      queryClient.setQueryData<Card>(queryKey, () => context!);
      await queryClient.invalidateQueries([BOARD_URL, boardId]);
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(queryKey, (old) => ({
        ...old!,
        subTaskList: [...context!.subTaskList, data],
      }));

      queryClient.setQueryData<Board>(
        [BOARD_URL, boardId],
        // @ts-ignore
        (old) => {
          return produce(old!, (draft) => {
            const taskList = draft.columnList.find(
              (col) => col.id === variables.columnId
            )!.task;
            taskList.splice(-1, 1, data);
          });
        }
      );
    },
  });
};
