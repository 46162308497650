import React from 'react';
import { Card } from '../type';
import { Space, Tag, theme, Tooltip, Typography } from 'antd';
import { getDateInfo } from '../Card';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { CardWrapper } from './SimpleCard';

const { useToken } = theme;

const CardListItem = React.memo(
  ({ card, closePopup }: { card: Card; closePopup: Function }) => {
    const { token } = useToken();
    return (
      <NavLink
        to={`/board/${card.column.boardId}/card/${card.id}`}
        onClick={() => closePopup()}
      >
        <CardListWrapper
          $bgColor={token.colorBgLayout}
          $bgColorHover={'#e1e5e6'}
        >
          <Space size={'small'} wrap={true}>
            {card?.labelToTask?.map(({ label }) => (
              <Tag key={label.id} color={label.color} style={{ margin: 0 }}>
                {label.title}
              </Tag>
            ))}
          </Space>
          <div>
            <Typography.Text style={{ fontSize: '0.8em' }} type={'secondary'}>
              {card?.serialNumber}{' '}
            </Typography.Text>
            <Typography.Text strong>{card?.title}</Typography.Text>
          </div>
          <Space size={0}>
            {card?.completeDate && (
              <div>
                <Tooltip
                  title={getDateInfo(card.completeDate, card.isCompleted).text}
                >
                  <Tag
                    color={
                      getDateInfo(card.completeDate, card.isCompleted).color
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    {dayjs(card.completeDate).format('DD.MM.YYYY')}
                  </Tag>
                </Tooltip>
              </div>
            )}
          </Space>
          <div>
            <Typography.Text type={'secondary'}>
              {card.column.board?.title}
            </Typography.Text>
          </div>
        </CardListWrapper>
      </NavLink>
    );
  }
);

const CardListWrapper = styled(CardWrapper)<{
  $bgColorHover: string;
}>`
  width: 250px;
  margin: auto;
  transition: 0.3s ease 0s;
  padding: 8px;
  &:hover {
    background-color: ${(props) => props.$bgColorHover};
  }
`;

CardListItem.displayName = 'CardListItem';
export default CardListItem;
