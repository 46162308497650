import { useContext } from 'react';
import { FilterContext } from '../../BoardLayout/BoardLayout';
import { useAppSelector } from '../../../store/rootReducer';

export const useMyCard = () => {
  const { setFilterList, filterList } = useContext(FilterContext);
  const { profile } = useAppSelector((state) => state.authReducer);

  return (evt: KeyboardEvent) => {
    if (evt.code === 'KeyQ') {
      if (!filterList.accountId) {
        setFilterList!((prevState) => ({
          ...prevState,
          accountId: profile?.id,
        }));
      } else {
        setFilterList!((prevState) => {
          const copy = { ...prevState };
          delete copy.accountId;
          return copy;
        });
      }
    }
  };
};
