import React, { useState } from 'react';
import { Avatar, Button, Divider, Form, Select, Space, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { ProfileType } from '../../Authorization/AuthorizationTypes';
import {
  ACCOUNT_ON_WORKPLACE_URL,
  ACCOUNT_URL,
  createAccountOnWorkplace,
  getAccountOnWorkplaceList,
} from '../../Workspace/Home/components/api';
import { Board } from '../../Workspace/Board/types';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';
import { AccountToTaskCreate, Card } from '../type';
import { AxiosResponse } from 'axios';
import { useAppSelector } from '../../../store/rootReducer';
import { useOnCustomSelect } from '../../../utils/hooks/useCustomSelect';
import { useGenericMutation } from '../../../utils/hooks/useGenericMutate';
import { useGetWorkplaceId } from '../../../utils/helpers/useRole';

const AddMemberView = React.memo(
  ({
    card,
    membersMutation,
    membersMutationDelete,
    cancelAdd,
  }: {
    card: Pick<Card, 'id' | 'accountToTask'> | undefined;
    membersMutation: UseMutationResult<any, unknown, AccountToTaskCreate, Card>;
    cancelAdd: () => void;
    membersMutationDelete: UseMutationResult<
      AxiosResponse<any, any>,
      unknown,
      number,
      Card
    >;
  }) => {
    const [value, setValue] = useState(
      card?.accountToTask.map((account) => account.account.id) ?? []
    );
    const [isSearchNew, setIsSearchNew] = useState(false);
    const { boardId } = useParams();
    const queryClient = useQueryClient();
    const board = queryClient.getQueryData<Board>([BOARD_URL, boardId]);
    const { data } = useQuery<{ account: ProfileType }[]>(
      [ACCOUNT_ON_WORKPLACE_URL, board?.workplaceId.toString()],
      () => getAccountOnWorkplaceList(board!.workplaceId)
    );
    const { profile } = useAppSelector((state) => state.authReducer);
    const workplaceId = useGetWorkplaceId();

    const setSelfMember = () => {
      setValue([...(value ?? []), profile!.id]);
      membersMutation.mutate({ memberIdList: [profile!.id], taskId: card!.id });
    };

    const formatOptions = ({ account: member }: { account: ProfileType }) => ({
      label: (
        <Space>
          <Avatar
            icon={<UserOutlined style={{ fontSize: 16 }} />}
            src={member.avatarUrl}
          />
          <span>{member.personFullName}</span>
        </Space>
      ),
      value: member.id,
      searchValue: member.personFullName,
    });
    const options = data?.map(formatOptions);

    const onDelete = (e: number) => {
      const bondFind = card?.accountToTask.find(
        (bond) => bond.account.id === e
      );
      membersMutationDelete.mutate(bondFind!.id);
    };

    const { dataList, searchHandler } = useOnCustomSelect<ProfileType>(
      `${ACCOUNT_URL}?q=`,
      'id'
    );
    const accountIds = data?.map((el) => el.account.id) ?? [];

    const newOptions = dataList
      ?.filter((account) => !accountIds.includes(account.id))
      .map((account) => formatOptions({ account }));

    const { mutateAsync: createMutate, isLoading: addWorkplaceAccountLoading } =
      useGenericMutation<any, any>(
        createAccountOnWorkplace,
        [ACCOUNT_ON_WORKPLACE_URL, workplaceId?.toString()],
        (oldData, newData) => [
          ...oldData,
          ...newData.memberIdList.map((memberId: number) => ({
            account: dataList?.find((account) => account.id === memberId),
          })),
        ]
      );

    const onFinish = (values: { memberId: number }) => {
      createMutate({
        workplaceId: +workplaceId!,
        memberIdList: [values.memberId],
      }).then(() => {
        cancelAdd();
        membersMutation.mutate({
          memberIdList: [values.memberId],
          taskId: card!.id,
        });
      });
    };

    return (
      <Space direction={'vertical'} style={{ width: 250 }}>
        <Typography.Title style={{ textAlign: 'center' }} level={5}>
          Участники
        </Typography.Title>
        <Select
          onKeyDown={(e) => e.stopPropagation()}
          showSearch={true}
          value={value}
          onChange={setValue}
          placeholder={'Поиск участников'}
          optionFilterProp={'searchValue'}
          options={options}
          mode={'multiple'}
          size={'large'}
          style={{ width: '100%' }}
          onDeselect={onDelete}
          dropdownRender={(menu) => {
            return (
              <div
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
              >
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space direction={'vertical'}>
                  {!isSearchNew ? (
                    <Button type={'link'} onClick={() => setIsSearchNew(true)}>
                      Пригласить новых участников
                    </Button>
                  ) : (
                    <Form onFinish={onFinish}>
                      <Form.Item name={'memberId'} style={{ marginBottom: 8 }}>
                        <Select
                          style={{ width: 240 }}
                          placeholder={'Искать новых участников'}
                          showSearch={true}
                          filterOption={false}
                          onSearch={searchHandler}
                          options={newOptions}
                          size={'large'}
                        />
                      </Form.Item>
                      <Form.Item noStyle style={{ margin: '0 auto 8px' }}>
                        <Button
                          type={'primary'}
                          block
                          htmlType={'submit'}
                          loading={addWorkplaceAccountLoading}
                        >
                          Добавить
                        </Button>
                      </Form.Item>
                    </Form>
                  )}
                </Space>
              </div>
            );
          }}
          disabled={
            membersMutation.isLoading || membersMutationDelete.isLoading
          }
          loading={membersMutation.isLoading || membersMutationDelete.isLoading}
          onSelect={(e) =>
            membersMutation.mutate({ memberIdList: [e], taskId: card!.id })
          }
        />
        {!value?.includes(profile!.id) && data && (
          <Button type={'text'} onClick={setSelfMember}>
            Назначить себе
          </Button>
        )}
      </Space>
    );
  }
);
AddMemberView.displayName = 'AddMemberView';
export default AddMemberView;
