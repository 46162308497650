import API from '../../../../utils/API/API';
import { CreateAccountOnWorkplace } from '../../types';
import { AccountRole } from '../../../Authorization/AuthorizationTypes';

export const ACCOUNT_ON_WORKPLACE_URL = 'api/account-on-workplace';
export const ACCOUNT_ON_WORKPLACE_ROLE_URL = 'api/account-on-workplace/role';
export const ACCOUNT_URL = 'api/accounts';

export const getAccountOnWorkplaceList = (workplaceId: number) =>
  API.get(`${ACCOUNT_ON_WORKPLACE_URL}?workplaceId=${workplaceId}`).then(
    (res) => res.data.data
  );

export const getAccountRole = (params: {
  workplaceId: number;
  accountId: number;
}) =>
  API.get(`${ACCOUNT_ON_WORKPLACE_URL}/role`, { params }).then(
    (res) => res.data.data
  );

export const createAccountOnWorkplace = (values: CreateAccountOnWorkplace) =>
  API.post(ACCOUNT_ON_WORKPLACE_URL, values);

export const deleteAccountOnWorkplace = (bondId: number) =>
  API.delete(`${ACCOUNT_ON_WORKPLACE_URL}/${bondId}`);

export const updateAccountOnWorkplace = (data: {
  id: number;
  role: AccountRole;
}) => API.put(`${ACCOUNT_ON_WORKPLACE_URL}`, data);
