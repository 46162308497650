import React from 'react';
import { Avatar, Card as CardA, Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { ProfileType } from '../../Authorization/AuthorizationTypes';
import styled from 'styled-components';
import { AvatarSize } from 'antd/es/avatar/SizeContext';

const AvatarCard = React.memo(
  ({
    account,
    size = 'default',
  }: {
    account: ProfileType;
    size?: AvatarSize;
  }) => {
    const urlImage = account.avatarUrl;
    return (
      <Popover
        mouseEnterDelay={1}
        key={account.id}
        overlayInnerStyle={{ padding: 0 }}
        content={
          <CardA
            style={{ width: 252 }}
            cover={
              urlImage ? (
                <ArtCard imgSrc={urlImage}>
                  <ArtImage alt="example" src={urlImage} />
                </ArtCard>
              ) : undefined
            }
          >
            <CardA.Meta
              title={account.personFullName}
              description={account.email}
            />
          </CardA>
        }
      >
        <Avatar size={size} icon={<UserOutlined />} src={urlImage} />
      </Popover>
    );
  }
);
AvatarCard.displayName = 'AvatarCard';
export default AvatarCard;

const ArtCard = styled.div<{ imgSrc: string }>`
  position: relative;
  height: 189px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${(props) => props.imgSrc});
    filter: blur(2px);
    z-index: 1;
  }
`;

const ArtImage = styled.img`
  position: absolute;
  border-radius: 0 !important;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;
