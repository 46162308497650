import { useMutation, useQueryClient } from 'react-query';
import { EXPLORER_URL, renameExplorerItem } from '../api/ExplorerApi';
import { ExplorerData } from '../type';
import { AxiosError } from 'axios';
import { notification } from 'antd';
import { produce } from 'immer';
import { findObjectByRelativePath } from './findObjectByRelativePath';

export const useRenameExplorerItem = (path: string) => {
  const mutationKey = [EXPLORER_URL, { path }];
  const queryClient = useQueryClient();

  return useMutation(mutationKey, renameExplorerItem, {
    onMutate: (variables) => {
      const prevValues = queryClient.getQueryData<ExplorerData>(mutationKey);

      queryClient.setQueryData<ExplorerData>(
        mutationKey,
        // @ts-ignore
        (oldData) => {
          return produce(oldData!, (draft) => {
            const splitNew = variables.newPath.split('/');
            const splitOld = variables.oldPath.split('/');
            const relativePath = splitOld
              .slice(0, -1)
              .join('/')
              .slice(path.length + 1);

            const node = findObjectByRelativePath(draft!, relativePath || '.')!;
            const indexFile = node.children?.findIndex(
              (el) =>
                el.relativePath ===
                `${relativePath ? relativePath + '/' : ''}${
                  splitOld[splitOld.length - 1]
                }`
            )!;
            node.children![indexFile].name = splitNew[splitNew.length - 1];
            node.children![indexFile].relativePath = `${splitNew
              .slice(2)
              .join('/')}`;
          });
        }
      );

      return prevValues;
    },
    onError: async (error: AxiosError<any, any>, _, context) => {
      notification.error({ message: error.response?.data.message });
      queryClient.setQueryData(mutationKey, () => context);
      await queryClient.invalidateQueries(mutationKey);
    },
  });
};
