import React, { useMemo, useState } from 'react';
import { Tree, TreeDataNode } from 'antd';
import { ExplorerData } from '../type';
import { sortCallback } from '../helpers/sortCallback';
import Search from 'antd/es/input/Search';

const { DirectoryTree } = Tree;
// const { useToken } = theme;

const TreeExplorer = React.memo(
  ({
    data,
    goPath,
    selectKey,
  }: {
    data: ExplorerData | undefined;
    goPath: (record: ExplorerData, relativePath: string) => void;
    selectKey: string;
  }) => {
    const [filter, setFilter] = useState('');
    const [keys, setKeys] = useState<string[]>([]);

    const sortedTreeData = (
      data: ExplorerData & {
        children: ExplorerData['children'] & Record<string, any>[];
      }
    ) => {
      if (data.children) {
        data.children.sort(sortCallback);

        for (let i = 0; i < data.children.length; i++) {
          data.children[i].parentPath = data.relativePath;
          // @ts-ignore
          sortedTreeData(data.children[i]);
        }
      }
      return data;
    };
    // @ts-ignore
    const treeData: TreeDataNode[] = useMemo(
      () => (data ? sortedTreeData(structuredClone(data))?.children : []),
      [data]
    );

    const onSearch = (value: string) => {
      const searchKeys: string[] = [];
      const findKeys = (data: ExplorerData) => {
        if (data.children) {
          for (let i = 0; i < data.children.length; i++) {
            if (
              data.children[i].name.toLowerCase().includes(value.toLowerCase())
            ) {
              searchKeys.push(data.children[i].relativePath);
            }
            findKeys(data.children[i]);
          }
        }
      };
      value && findKeys(data!);
      setKeys(searchKeys);
      setFilter(value);
    };

    return (
      <div style={{ marginTop: 64 }}>
        <Search
          style={{ marginBottom: 16 }}
          placeholder={'Поиск по всем файлам'}
          onSearch={onSearch}
        />
        <DirectoryTree
          fieldNames={{
            title: 'name',
            key: 'relativePath',
          }}
          treeData={treeData}
          rootStyle={{
            maxHeight: 'calc(100vh - 230px)',
            overflow: 'auto',
            backgroundColor: '#f4faff',
            fontSize: '0.85em',
          }}
          filterTreeNode={(node) => {
            return filter
              ? // @ts-ignore
                node.name?.toLowerCase().includes(filter.toLowerCase())
              : false;
          }}
          selectedKeys={[selectKey]}
          autoExpandParent
          expandedKeys={keys}
          onExpand={(keys) => {
            setKeys(keys as string[]);
          }}
          onSelect={(key, info) => {
            const path: string =
              // @ts-ignore
              info.node.type === 'directory'
                ? // @ts-ignore
                  info.node.relativePath
                : // @ts-ignore
                  info.node.parentPath;
            goPath(data!, path);
          }}
        />
      </div>
    );
  }
);
TreeExplorer.displayName = 'TreeExplorer';
export default TreeExplorer;
