import React from 'react';
import { Button, Form, Input, Space, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useAppSelector } from '../../../store/rootReducer';
import { useGenericMutation } from '../../../utils/hooks/useGenericMutate';
import {
  createWorkplace,
  WORKPLACE_URL,
} from '../../Workspace/Home/api/WorkplaceApi';
import { isMobile } from 'react-device-detect';

const FormWorkplace = React.memo(({ onClose }: { onClose: Function }) => {
  const { profile } = useAppSelector((state) => state.authReducer);
  const { mutateAsync, isLoading } = useGenericMutation<any, any>(
    (values: { title: string; description: string }) =>
      createWorkplace({ ...values, authorId: profile!.id }),
    [WORKPLACE_URL],
    (oldData, newData) => [...(oldData ?? []), newData]
  );
  const onFinish = (values: { title: string; description: string }) => {
    mutateAsync(values).then(() => onClose());
  };
  return (
    <Space direction={'vertical'} style={{ width: isMobile ? 250 : 500 }}>
      <Typography.Title level={2}>
        Создайте рабочее пространство
      </Typography.Title>
      <Typography.Title level={5} type={'secondary'}>
        Повысьте производительность: участники команды смогут получать удобный
        доступ ко всем доскам.
      </Typography.Title>
      <Form layout={'vertical'} onFinish={onFinish}>
        <Form.Item
          name={'title'}
          label={'Название'}
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={'Описание'} name={'description'}>
          <TextArea rows={6} />
        </Form.Item>
        <Button
          type={'primary'}
          htmlType={'submit'}
          style={{ width: '100%' }}
          loading={isLoading}
        >
          Продолжить
        </Button>
      </Form>
    </Space>
  );
});
FormWorkplace.displayName = 'FormWorkplace';
export default FormWorkplace;
