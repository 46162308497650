import React from 'react';
import { Card, Typography } from 'antd';
import { Task } from 'gantt-task-react';
import dayjs from 'dayjs';
import { transformWord } from '../../../utils/helpers/transformWord';

const GanttTaskView = React.memo(({ card }: { card: Task }) => {
  return (
    <Card size={'small'} title={card.name}>
      <Typography.Paragraph>
        <Typography.Text strong>Дата начала: </Typography.Text>
        <Typography.Text type={'secondary'}>
          {dayjs(card.start).format('DD.MM.YYYY')}
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Typography.Text strong>Дата окончания: </Typography.Text>
        <Typography.Text type={'secondary'}>
          {dayjs(card.end).format('DD.MM.YYYY')}
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Typography.Text strong>Длительность: </Typography.Text>
        <Typography.Text type={'secondary'}>
          {dayjs(card.end).diff(dayjs(card.start), 'days')}{' '}
          {transformWord(dayjs(card.end).diff(dayjs(card.start), 'days'), [
            'день',
            'дня',
            'дней',
          ])}
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Typography.Text strong>Прогресс: </Typography.Text>
        <Typography.Text type={'secondary'}>{card.progress}%</Typography.Text>
      </Typography.Paragraph>
    </Card>
  );
});
GanttTaskView.displayName = 'GanttTaskView';
export default GanttTaskView;
