import { useMutation, useQueryClient } from 'react-query';
import {
  deleteFileExplorer,
  deleteFolderExplorer,
  EXPLORER_URL,
} from '../api/ExplorerApi';
import { notification } from 'antd';
import { ExplorerData } from '../type';
import { produce } from 'immer';
import { findObjectByRelativePath } from './findObjectByRelativePath';
import { useParams } from 'react-router-dom';

export const useDeleteExplorerItemMutation = (path: string, isFile = false) => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();

  const mutationKey = [EXPLORER_URL, { path }];
  return useMutation(
    mutationKey,
    isFile ? deleteFileExplorer : deleteFolderExplorer,
    {
      onMutate: (data) => {
        const prevValues = queryClient.getQueryData<ExplorerData>(mutationKey);
        queryClient.setQueryData<ExplorerData>(
          mutationKey,
          // @ts-ignore
          (oldData) => {
            return produce(oldData, (draft) => {
              const pathWithoutBoardId = data.path.slice(boardId!.length + 1);
              const pathArray = pathWithoutBoardId.split('/').slice(0, -1);
              const relativePath = pathArray.length ? pathArray.join('/') : '.';
              const node = findObjectByRelativePath(draft!, relativePath)!;
              const index = node?.children!.findIndex(
                (el) => el.relativePath === pathWithoutBoardId
              )!;
              node?.children!.splice(index, 1);
            });
          }
        );
        return prevValues;
      },
      onError: async (error, _, context) => {
        notification.error({ message: 'Ошибка удаления файла' });
        queryClient.setQueryData(mutationKey, () => context);
        await queryClient.invalidateQueries(mutationKey);
      },
    }
  );
};
