import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getLabelList, LABEL_URL } from '../../Card/api';
import { useParams } from 'react-router-dom';
import { Label } from '../../Card/type';
import { Button, Col, Popover, Row, Space, Tag } from 'antd';
import CreateLabel from '../../Card/components/CreateLabel';
import { EditOutlined } from '@ant-design/icons';
import CenteredSpin from '../../../utils/Copmonents/CenteredSpin';

const FormLabel = React.memo(() => {
  const { boardId: id } = useParams();
  const { data, isLoading } = useQuery<Label[]>([LABEL_URL, id], () =>
    getLabelList(+id!)
  );
  const [isCreate, setIsCreate] = useState(false);
  return (
    <>
      <CenteredSpin spinning={isLoading} />
      <Space direction={'vertical'} style={{ width: '100%' }}>
        {data?.map((label) => (
          <Row key={label.id}>
            <Col span={22}>
              <Tag color={label.color} style={{ width: '100%' }}>
                {label.title}
              </Tag>
            </Col>
            <Col span={2}>
              <Popover
                trigger={'click'}
                arrow={false}
                content={<CreateLabel label={label} />}
              >
                <Button icon={<EditOutlined />} size={'small'} type={'text'} />
              </Popover>
            </Col>
          </Row>
        ))}
        <Popover
          trigger={'click'}
          arrow={false}
          open={isCreate}
          onOpenChange={setIsCreate}
          content={<CreateLabel onCancel={() => setIsCreate(false)} />}
        >
          <Button style={{ width: '100%' }}>Добавить метку</Button>
        </Popover>
      </Space>
    </>
  );
});
FormLabel.displayName = 'FormLabel';
export default FormLabel;
