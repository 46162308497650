import React from 'react';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';
import { Sticker } from '../../Card/type';
import { CARD_URL } from '../../Card/api';
import { useDeleteStickerMutation } from '../helpers/useDeleteStickerMutation';

const StickerElement = React.memo(
  ({
    sticker,
    columnId,
    cardView,
  }: {
    sticker: Sticker;
    columnId: number;
    cardView?: boolean;
  }) => {
    const queryKey = cardView
      ? [CARD_URL, 'once', sticker.taskId.toString()]
      : [CARD_URL, 'list', columnId.toString()];

    const { mutate, isLoading } = useDeleteStickerMutation(
      queryKey,
      sticker.taskId
    );
    const onDelete = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      mutate(sticker.id!);
    };

    const topPercentage = (100 * sticker.top) / 64 + 20;
    const leftPercentage = (752 * sticker.left) / 220 + 100;

    return (
      <StickerWrapper
        $top={cardView ? topPercentage : sticker.top}
        $left={cardView ? leftPercentage : sticker.left}
        $rotate={sticker.rotate}
      >
        <Tooltip
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0.5}
          open={sticker.id === 0 ? false : undefined}
          title={
            <Button
              loading={isLoading}
              style={{ color: 'white' }}
              type={'text'}
              size={'small'}
              onClick={onDelete}
            >
              Удалить
            </Button>
          }
        >
          <img alt={''} width={64} height={64} src={sticker.imageUrl} />
        </Tooltip>
      </StickerWrapper>
    );
  }
);

const StickerWrapper = styled.div<{
  $top: number;
  $left: number;
  $rotate: number;
}>`
  position: absolute;
  top: ${(props) => props.$top}px;
  left: ${(props) => props.$left}px;
  transform: rotate(${(props) => props.$rotate}deg);
  height: 64px;
  width: 64px;
  transition: 0.3s background-color;
  cursor: pointer;

  &:hover {
    background-color: rgba(241, 241, 241, 0.9);
    z-index: 9;
  }
`;
StickerElement.displayName = 'StickerElement';
export default StickerElement;
