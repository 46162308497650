import React, { useState } from 'react';
import { CopyOutlined, SwapOutlined } from '@ant-design/icons';
import { Button as ButtonA, Popover } from 'antd';
import { Card } from '../type';
import styled from 'styled-components';
import FormCopyChange from './FormCopyChange';

const CardCopyChange = React.memo(
  ({ card, type }: { card: Card | undefined; type: 'copy' | 'change' }) => {
    const [open, setOpen] = useState(false);

    return card ? (
      <Popover
        trigger={'click'}
        title={
          type === 'copy' ? 'Копирование карточки' : 'Перемещение карточки'
        }
        overlayInnerStyle={{ width: 250 }}
        onOpenChange={setOpen}
        open={open}
        content={<FormCopyChange setOpen={setOpen} card={card} type={type} />}
      >
        <Button onClick={() => setOpen(true)}>
          {type === 'copy' ? (
            <>
              <CopyOutlined /> Копировать
            </>
          ) : (
            <>
              <SwapOutlined /> Переместить
            </>
          )}
        </Button>
      </Popover>
    ) : (
      <></>
    );
  }
);

const Button = styled(ButtonA)`
  width: 100%;
  text-align: left;
`;
CardCopyChange.displayName = 'CopyCard';
export default CardCopyChange;
