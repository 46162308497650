import React from 'react';
import { Checkbox, Space, Typography } from 'antd';
import { Card } from '../type';

const ChecklistInCreateCard = React.memo(
  ({
    setIsLabel,
    setIsCheckItem,
    setIsMember,
    card,
  }: {
    setIsLabel: Function;
    setIsMember: Function;
    setIsCheckItem: Function;
    card: Card | undefined;
  }) => {
    return (
      <div>
        <Typography.Paragraph strong>Копировать также</Typography.Paragraph>
        <Space direction={'vertical'} style={{ marginBottom: 12 }}>
          {!!card?.labelToTask.length && (
            <Checkbox
              defaultChecked={true}
              onChange={(e) => setIsLabel(e.target.checked)}
            >
              Метки
            </Checkbox>
          )}
          {!!card?.accountToTask.length && (
            <Checkbox
              defaultChecked={true}
              onChange={(e) => setIsMember(e.target.checked)}
            >
              Участников
            </Checkbox>
          )}
          {!!card?.checklist?.checklistItems.length && (
            <Checkbox
              defaultChecked={true}
              onChange={(e) => setIsCheckItem(e.target.checked)}
            >
              Подзадачи
            </Checkbox>
          )}
        </Space>
      </div>
    );
  }
);
ChecklistInCreateCard.displayName = 'ChecklistInCreateCard';
export default ChecklistInCreateCard;
