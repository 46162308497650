import React from 'react';
import { Button, Form, Input, message } from 'antd';
import { useUpdateBoard } from '../helpers/useUpdateBoard';
import { useQuery } from 'react-query';
import { BOARD_URL, getBoardById } from '../../Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';
import { Board } from '../../Workspace/Board/types';

const Gitlab = React.memo(() => {
  const { boardId } = useParams();
  const { data } = useQuery<Board>([BOARD_URL, boardId], () =>
    getBoardById(boardId!)
  );
  const { mutateAsync, isLoading } = useUpdateBoard();
  const [messageApi, contextHolder] = message.useMessage();
  const onFinish = (values: { gitlabNamespace: string }) => {
    mutateAsync(values).then(() =>
      messageApi.open({ type: 'success', content: 'Успешно' })
    );
  };

  return (
    <Form onFinish={onFinish} layout={'vertical'}>
      <Form.Item
        name={'gitlabNamespace'}
        label={'Пространство имен'}
        initialValue={data?.gitlabNamespace}
      >
        <Input
          onKeyDown={(e) => e.stopPropagation()}
          placeholder={'yamata1/task-board'}
        />
      </Form.Item>
      <Button htmlType={'submit'} type={'primary'} loading={isLoading}>
        Сохранить
      </Button>
      {contextHolder}
    </Form>
  );
});
Gitlab.displayName = 'Gitlab';
export default Gitlab;
