import { useMutation, useQueryClient } from 'react-query';
import { CARD_URL, updateChecklistItem } from '../api';
import { Card, UpdateChecklistItem } from '../type';
import { notification } from 'antd';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';
import { Board } from '../../Workspace/Board/types';
import { produce } from 'immer';

export const useUpdateChecklistMutation = (queryKey: any[]) => {
  const queryClient = useQueryClient();
  const { boardId, id } = useParams();
  return useMutation(
    queryKey,
    (args: [number, UpdateChecklistItem]) => updateChecklistItem(...args),
    {
      onMutate: async (data) => {
        const cardData = queryClient.getQueryData<Card>(queryKey);

        const getNewChecklist = (oldData: Card) => {
          const checklistItemIndex =
            oldData.checklist!.checklistItems.findIndex(
              (item) => item.id === data[0]
            );

          const copy = Array.from(oldData.checklist?.checklistItems ?? []);
          copy[checklistItemIndex] = {
            ...copy[checklistItemIndex],
            ...data[1],
          };
          return copy;
        };

        await queryClient.cancelQueries([CARD_URL]);
        queryClient.setQueryData<Card>(
          queryKey,
          // @ts-ignore
          (oldData) => {
            if (oldData) {
              return {
                ...oldData,
                checklist: {
                  ...oldData.checklist,
                  checklistItems: getNewChecklist(oldData!),
                },
              };
            }
            return {};
          }
        );

        queryClient.setQueryData<Board>([BOARD_URL, boardId], (oldData) => {
          return produce(oldData!, (draft) => {
            const currentCard = draft.columnList
              .flatMap((col) => col.task)
              .find((card) => card.id === +id!)!;
            const checklistItem = currentCard.checklist?.checklistItems.find(
              (item) => item.id === data[0]
            );
            if (checklistItem) Object.assign(checklistItem, data[1]);
          });
        });

        return cardData;
      },
      onError: async (error, variables, context) => {
        notification.error({ message: 'Ошибка' });
        queryClient.setQueryData<Card>(queryKey, () => context!);
      },
      onSuccess: async (data, variables, context) => {
        const checklistItemIndex = context!.checklist!.checklistItems.findIndex(
          (item) => item.id === variables[0]
        );

        const copy = Array.from(context?.checklist?.checklistItems ?? []);
        copy[checklistItemIndex] = data;

        queryClient.setQueryData<Card>(queryKey, () => ({
          ...context!,
          checklist: {
            ...context!.checklist!,
            checklistItems: copy,
          },
        }));
      },
    }
  );
};
