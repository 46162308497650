import React, { useEffect, useRef, useState } from 'react';
import { Card as CardType, UpdateCard } from './type';
import { Button, Form, Popover, Space, Tag, Tooltip, Typography } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {
  CheckSquareOutlined,
  CommentOutlined,
  EditOutlined,
  GitlabOutlined,
  PullRequestOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { useCardUpdate } from './helpers/useCardUpdate';
import TextArea from 'antd/es/input/TextArea';
import { getMergeStatus } from './helpers/getMergeStatus';
import { Droppable } from 'react-beautiful-dnd';
import StickerElement from '../BoardLayout/components/StickerElement';
import { useGetWorkplaceId, useRole } from '../../utils/helpers/useRole';
import AvatarCard from './components/AvatarCard';

export const getDateInfo = (end: Date | string, isCompleted = false) => {
  if (isCompleted) return { color: 'green', text: 'Дата выполнения' };
  const now = dayjs(dayjs(), 'YYYY-MM-DD');
  const formatEnd = dayjs(end, 'YYYY-MM-DD');
  if (now.diff(formatEnd, 'days') > 0)
    return { color: 'red', text: 'Дата завершения истекла' };
  if (formatEnd.diff(now, 'days') < 2)
    return { color: 'yellow', text: 'Дата' + ' завершения скоро истекает' };
  return { color: 'blue', text: 'Дата завершения' };
};

const Card = React.memo(
  ({ card, isDragging }: { card: CardType; isDragging?: boolean }) => {
    const [viewBtn, setViewBtn] = useState(false);
    const [viewMenu, setViewMenu] = useState(false);
    const { mutate: cardMutate, mutateAsync } = useCardUpdate();

    const onClickEditButton = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      e.preventDefault();
      setViewMenu(true);
    };
    const closeMenu = () => {
      setViewMenu(false);
    };
    useEffect(() => {
      document.addEventListener('mousedown', closeMenu);
      return () => document.removeEventListener('mousedown', closeMenu);
    }, []);
    const bgRef = useRef<any>();
    const workplaceId = useGetWorkplaceId();
    const role = useRole(workplaceId ?? 0);

    const sendToArchive = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      e.preventDefault();
      cardMutate([card!.id, { archived: true }]);
    };

    const subTaskInfo =
      card?.subTaskList &&
      `${card.subTaskList.filter((item) => item.isCompleted).length}/${
        card.subTaskList.length
      }`;

    const checkInfo =
      card?.checklist?.checklistItems &&
      `${
        card.checklist.checklistItems.filter((item) => item.isCompleted).length
      }/${card.checklist.checklistItems.length}`;

    const onFinishEditTitle = (values: UpdateCard) =>
      mutateAsync([card.id, values]).then(() => {
        setViewMenu(false);
        setViewBtn(false);
      });

    const isHeight = !!(
      card?.backgroundColor ||
      card?.backgroundUrl ||
      card?.stickerToTask?.length
    );

    return (
      <>
        <Popover
          arrow={false}
          open={viewMenu}
          content={
            <Space>
              <Button
                onClick={sendToArchive}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                style={{ width: '100%' }}
              >
                В архив
              </Button>
            </Space>
          }
          placement={'right'}
        >
          <CardWrapper
            onMouseEnter={() => setViewBtn(true)}
            onMouseLeave={() => setViewBtn(false)}
            $bgColor={'#fff'}
            $bgColorHover={'#fff'}
            $isDragging={!!isDragging}
            $cursor={card.id ? 'pointer' : 'pointer'}
          >
            {viewBtn && role !== 'user' && (
              <EditButtonWrapper>
                <Button
                  onClick={onClickEditButton}
                  icon={<EditOutlined />}
                  size={'small'}
                />
              </EditButtonWrapper>
            )}
            <Droppable
              droppableId={'cardSticker' + card.id}
              type={'STICKER'}
              ignoreContainerClipping={true}
            >
              {(providedCard, snapshot) => (
                <div
                  {...providedCard.droppableProps}
                  ref={providedCard.innerRef}
                  id={'cardSticker' + card.id}
                >
                  <BackgroundWrapper
                    $bgUrl={card?.backgroundUrl ?? ''}
                    $height={isHeight ? 64 : 0}
                    $bgColor={
                      snapshot.isDraggingOver
                        ? '#e1e5e6'
                        : card?.backgroundColor
                    }
                    ref={bgRef}
                  />
                  {!isHeight && snapshot.isDraggingOver && (
                    <BackgroundWrapper $height={84} $bgColor={'#e1e5e6'} />
                  )}
                  {card?.stickerToTask?.map((sticker) => (
                    <StickerElement
                      columnId={card.columnId}
                      sticker={sticker}
                      key={sticker.id!}
                    />
                  ))}
                </div>
              )}
            </Droppable>
            <InfoCardWrapper>
              <Space size={'small'} wrap={true}>
                {card?.labelToTask?.map(({ label }) => (
                  <Tag key={label.id} color={label.color} style={{ margin: 0 }}>
                    {label.title}
                  </Tag>
                ))}
              </Space>
              <span>
                {!viewMenu && (
                  <Typography.Text
                    style={{ fontSize: '0.8em' }}
                    type={'secondary'}
                  >
                    {card?.serialNumber}{' '}
                  </Typography.Text>
                )}
                {!viewMenu ? (
                  <Typography.Text strong>{card?.title}</Typography.Text>
                ) : (
                  <Form onFinish={onFinishEditTitle}>
                    <Form.Item
                      name={'title'}
                      noStyle
                      initialValue={card?.title}
                    >
                      <TextArea
                        rows={3}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                        }}
                        onKeyDown={(e) => e.stopPropagation()}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        size={'small'}
                      />
                    </Form.Item>
                    <Form.Item noStyle>
                      <Button
                        style={{ marginTop: 8 }}
                        type={'primary'}
                        htmlType={'submit'}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        Сохранить
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </span>
              <Space size={5} wrap>
                {card?.completeDate && (
                  <div>
                    <Tooltip
                      title={
                        getDateInfo(card.completeDate, card.isCompleted).text
                      }
                    >
                      <Tag
                        color={
                          getDateInfo(card.completeDate, card.isCompleted).color
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        {dayjs(card.completeDate).local().format('DD.MM.YYYY')}
                      </Tag>
                    </Tooltip>
                  </div>
                )}
                {!!card?.comments?.length && (
                  <Tooltip title={'Комментарии к карточке'}>
                    <Typography.Text type={'secondary'}>
                      <CommentOutlined /> {card.comments.length}
                    </Typography.Text>
                  </Tooltip>
                )}
                {!!card?.checklist?.checklistItems?.length && (
                  <Tooltip title={'Чек-лист'}>
                    <Typography.Text type={'secondary'}>
                      <CheckSquareOutlined /> {checkInfo}
                    </Typography.Text>
                  </Tooltip>
                )}

                {!!card?.subTaskList?.length && (
                  <Tooltip title={'Статус подзадач'}>
                    <Typography.Text type={'secondary'}>
                      <UnorderedListOutlined /> {subTaskInfo}
                    </Typography.Text>
                  </Tooltip>
                )}
                {!!card?.gitlabCommit?.length && (
                  <Tooltip title={'Коммиты'}>
                    <Typography.Text type={'secondary'}>
                      <GitlabOutlined /> {card.gitlabCommit.length}
                    </Typography.Text>
                  </Tooltip>
                )}
                {!!card?.gitlabMergeRequestList?.length && (
                  <Tooltip title={'Merge request'}>
                    <Typography.Text type={'secondary'}>
                      <PullRequestOutlined />{' '}
                      {getMergeStatus(card.gitlabMergeRequestList)}/
                      {card.gitlabMergeRequestList.length}
                    </Typography.Text>
                  </Tooltip>
                )}
              </Space>
              {card?.isTemplate && (
                <div>
                  <Tag color={'blue'}>Шаблон карточки</Tag>
                </div>
              )}
              {!!card?.accountToTask?.length && (
                <AvatarWrapper>
                  {card?.accountToTask.map(({ account }) => (
                    <AvatarCard
                      account={account}
                      key={account.id}
                      size={'small'}
                    />
                  ))}
                </AvatarWrapper>
              )}
            </InfoCardWrapper>
          </CardWrapper>
        </Popover>
      </>
    );
  }
);

export const AvatarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 6px;
`;

export const BackgroundWrapper = styled.div<{
  $bgUrl?: string;
  $bgColor?: string;
  $height?: number;
}>`
  height: ${(props) => props.$height}px;
  width: 100%;
  background-image: url('${(props) => props.$bgUrl ?? ''}');
  border-radius: 8px 8px 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${(props) => props.$bgColor};
`;

const EditButtonWrapper = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 10;
`;

export const CardWrapper = styled.div<{
  $bgColor: string;
  $bgColorHover: string;
  $isDragging: boolean;
  $cursor: 'pointer' | 'wait';
}>`
  background-color: ${(props) => props.$bgColor};
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  width: 100%;
  cursor: ${(props) => props.$cursor};
  position: relative;
  box-shadow: 0 5px 5px -5px rgba(83, 108, 128, 0.6);
  transition: 0.3s ease 0s;
  transform: ${(props) => (props.$isDragging ? `rotate(4deg)` : undefined)};
  &:hover {
    background-color: ${(props) => props.$bgColorHover};
  }
`;

const InfoCardWrapper = styled.div`
  padding: 8px;
  position: relative;
  display: flex;
  gap: 6px;
  flex-direction: column;
  z-index: 3;
  transition: 0.3s ease 0s;
`;

Card.displayName = 'Card';
export default Card;
