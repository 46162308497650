import { createSlice } from '@reduxjs/toolkit';

declare type InitialStateType = {
  top: number | null;
  left: number | null;
  topImg: null | number;
  leftImg: null | number;
  isPortal: boolean;
};
export const stickerReducer = createSlice({
  name: 'stickerReducer',
  initialState: <InitialStateType>{
    top: null,
    left: null,
    topImg: null,
    leftImg: null,
  },
  reducers: {
    setCoordinates: (state, action) => {
      state.top = action.payload.top;
      state.left = action.payload.left;
    },
    setCoordinateImg: (state, action) => {
      state.topImg = action.payload.top;
      state.leftImg = action.payload.left;
    },
  },
});

export const { setCoordinates, setCoordinateImg } = stickerReducer.actions;
