import { useMutation, useQueryClient } from 'react-query';
import { EXPLORER_URL, uploadFile } from '../api/ExplorerApi';
import { notification } from 'antd';
import { useParams } from 'react-router-dom';

export const useExplorerMutate = () => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();

  const mutationKey = [EXPLORER_URL, { path: `/${boardId}` }];
  return useMutation(mutationKey, uploadFile, {
    // onMutate: (data) => {
    //   const prevValues = queryClient.getQueryData<ExplorerData>(mutationKey);
    //   queryClient.setQueryData<ExplorerData>(
    //     mutationKey,
    //     // @ts-ignore
    //     (oldData) => {
    //       return produce(oldData!, (draft) => {
    //         const node = findObjectByRelativePath(draft!, path || '.')!;
    //         if (!('children' in node)) {
    //           node.children = [];
    //         }
    //         node?.children!.push({
    //           isSymbolicLink: false,
    //           // @ts-ignore
    //           name: data.get('file')?.name,
    //           path: '',
    //           // @ts-ignore
    //           relativePath: `${path}\\${data.get('file')?.name}`,
    //           size: '',
    //           // @ts-ignore
    //           sizeInBytes: data.get('file')?.size,
    //           type: 'file',
    //         });
    //       });
    //     }
    //   );
    //   return prevValues;
    // },
    onSettled: async () => {
      await queryClient.invalidateQueries(mutationKey);
    },
    onError: async (error, _, context) => {
      notification.error({ message: 'Ошибка загрузки файла' });
      // queryClient.setQueryData(mutationKey, () => context);
    },
  });
};
