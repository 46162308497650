import React, { useState } from 'react';
import {
  Button,
  ColorPicker,
  Form,
  message,
  Popover,
  Space,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { Card } from '../type';
import { useCardUpdateOnce } from '../helpers/useCardUpdateOnce';
import { KeycloakApp } from '../../../utils/Keycloak/Keycloak';
import { getUrlImage } from '../../../utils/helpers/getUrlImage';
import SearchBackground from '../../BoardLayout/components/SearchBackground';
import { isMobile } from 'react-device-detect';

const BackgroundForm = React.memo(({ card }: { card?: Card }) => {
  const { id } = useParams();
  const [backgroundColor, setBackgroundColor] = useState(
    card?.backgroundColor ?? '#fff'
  );
  const cardMutate = useCardUpdateOnce(id);

  const props: UploadProps = {
    name: 'file',
    action: `${process.env.REACT_APP_PRODUCTION_URL}/api/filesystem`,
    headers: {
      authorization: `Bearer ${KeycloakApp.token ?? ''}`,
    },
    accept: 'image/png, image/jpeg',
    onChange(info) {
      if (info.file.status === 'done') {
        cardMutate.mutate({
          backgroundUrl: getUrlImage(info.file.response.data),
        });
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    maxCount: 1,
    defaultFileList: card?.backgroundUrl
      ? [
          {
            uid: '1',
            name: 'обложка',
            status: 'done',
            url: card?.backgroundUrl,
          },
        ]
      : undefined,
    onRemove: () => cardMutate.mutate({ backgroundUrl: '' }),
  };
  const fields = [{ name: 'backgroundColor', value: backgroundColor }];
  return (
    <Space direction={'vertical'}>
      <Typography.Title level={5}>Обложка</Typography.Title>
      <Form fields={fields}>
        <Form.Item>
          <Popover
            content={
              <SearchBackground heightImage={80} mutate={cardMutate.mutate} />
            }
            overlayInnerStyle={{ width: 300 }}
            title={
              <>
                Поиск фотографий
                <Typography.Text />{' '}
                <Link
                  to={
                    'https://unsplash.com/?utm_source=Yambook&utm_medium=referral'
                  }
                >
                  Unsplash
                </Link>
              </>
            }
            trigger={'click'}
            placement={isMobile ? 'bottom' : 'right'}
          >
            <Button type={'dashed'} icon={<SearchOutlined />}>
              Поиск изображений
            </Button>
          </Popover>
        </Form.Item>
        <Form.Item name={'backgroundUrl'}>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Нажмите для загрузки</Button>
          </Upload>
        </Form.Item>
        <Form.Item name={'backgroundColor'}>
          <ColorPicker
            presets={[
              {
                label: 'Рекомендованные цвета',
                colors: [
                  '#eb3b5a',
                  '#fa8231',
                  '#f7b731',
                  '#20bf6b',
                  '#0fb9b1',
                  '#2d98da',
                  '#3867d6',
                  '#8854d0',
                  '#a5b1c2',
                  '#4b6584',
                ],
              },
            ]}
            onChange={(_, hex) => setBackgroundColor(hex)}
            onOpenChange={(open) => {
              if (!open && backgroundColor !== card?.backgroundColor)
                cardMutate.mutate({ backgroundColor });
            }}
          />
        </Form.Item>
      </Form>
    </Space>
  );
});
BackgroundForm.displayName = 'BackgroundForm';
export default BackgroundForm;
