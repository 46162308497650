import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Form,
  InputNumber,
  message,
  notification,
  Popover,
  Row,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import { useAppSelector } from '../../../store/rootReducer';
import styled from 'styled-components';
import API from '../../../utils/API/API';
import { CheckOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { loggedIn } from '../../../store/reducers/AuthReducer';
import { getUrlImage } from '../../../utils/helpers/getUrlImage';
import { KeycloakApp } from '../../../utils/Keycloak/Keycloak';
import { RcFile } from 'antd/es/upload';

const Profile = React.memo(() => {
  const { profile } = useAppSelector((state) => state.authReducer);
  const [isUpload, setIsUpload] = useState(false);
  const dispatch = useDispatch();
  const onFinish = (values: any) => {
    API.put(`api/accounts/${profile?.id}`, values)
      .then((res) => {
        dispatch(loggedIn(res.data.data));
        notification.success({ message: 'Аккаунт успешно изменен' });
      })
      .catch((error) => {
        notification.error({ message: error.response.data.message[0] });
      });
  };

  const props: UploadProps = {
    name: 'file',
    action: `${process.env.REACT_APP_PRODUCTION_URL}/api/filesystem`,
    headers: {
      authorization: `Bearer ${KeycloakApp.token}`,
    },
    accept: 'image/png, image/jpeg',
    onChange: (info) => {
      if (info.file.status === 'done') {
        onFinish({
          avatarUrl: getUrlImage(info.file.response.data),
        });
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    maxCount: 1,
    defaultFileList: profile?.avatarUrl
      ? [
          {
            uid: '-xxx',
            name: 'аватар',
            status: 'done',
          },
        ]
      : [],
    onRemove: () => onFinish({ avatarUrl: '' }),
    beforeUpload,
  };

  /**
   * @param {RcFile} file
   * @return {string}
   */
  async function beforeUpload(file: RcFile): Promise<boolean> {
    const isLt2M = file.size / 1024 < 250;

    if (KeycloakApp?.isTokenExpired(5)) {
      await KeycloakApp.updateToken(5).then(() => {
        props!.headers!.authorization! = `Bearer ${KeycloakApp.token}`;
      });
    }
    if (!isLt2M) {
      notification['error']({
        message: `Изображение должно быть меньше 250KB!`,
      });
    }
    return !!(isLt2M || Upload.LIST_IGNORE);
  }

  return (
    <ProfileContainer>
      <Row gutter={[8, 8]}>
        <Col
          onMouseEnter={() => setIsUpload(true)}
          onMouseLeave={() => setIsUpload(false)}
          md={6}
          span={24}
        >
          <Upload {...props} showUploadList={isUpload} beforeUpload={() => {}}>
            {profile?.avatarUrl ? (
              <Avatar
                size={100}
                style={{ cursor: 'pointer' }}
                src={profile.avatarUrl}
              />
            ) : (
              <Avatar
                size={100}
                icon={<UserOutlined />}
                style={{ cursor: 'pointer' }}
              />
            )}
          </Upload>
        </Col>
        <Col md={18} span={18}>
          <Descriptions layout={'vertical'}>
            <Descriptions.Item label="Имя">
              {profile?.personName}
            </Descriptions.Item>
            <Descriptions.Item label="Фамилия">
              {profile?.personSurname}
            </Descriptions.Item>
            <Descriptions.Item label="Логин">
              {profile?.login}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {profile?.email}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row justify={'end'} gutter={16}>
        <Popover
          trigger={'click'}
          content={
            <Form
              initialValues={{ telegramId: profile?.telegramId }}
              onFinish={onFinish}
            >
              <Form.Item name={'telegramId'} label={'User ID'}>
                <InputNumber
                  addonAfter={
                    <Button
                      htmlType={'submit'}
                      icon={<CheckOutlined />}
                      type={'text'}
                    />
                  }
                  controls={false}
                />
              </Form.Item>
              <Typography.Paragraph style={{ fontSize: '0.8em' }}>
                Для того, чтобы узнать User ID нужно связаться с ботом
                @yambook_bot
              </Typography.Paragraph>
            </Form>
          }
        >
          <Button type={'link'}>Указать Telegram</Button>
        </Popover>
        <Button type={'link'} href={KeycloakApp.createAccountUrl()}>
          Редактировать аккаунт
        </Button>
      </Row>
    </ProfileContainer>
  );
});

const ProfileContainer = styled.div`
  background-color: white;
  padding: 20px;
  max-width: 600px;
  margin: 50px auto;
  border-radius: 4px;
`;

Profile.displayName = 'Profile';
export default Profile;
