import React, { useEffect, useState } from 'react';
import { Card, Col, Row, theme } from 'antd';
import axios from 'axios';
import RegistryForm from './RegistryForm';
import { isDesktop } from 'react-device-detect';
import { KeycloakApp } from '../../utils/Keycloak/Keycloak';

const { useToken } = theme;

const Authorization = () => {
  const [backgroundUrl, setBackgroundUrl] = useState('');
  const { token } = useToken();

  useEffect(() => {
    isDesktop &&
      axios
        .get(
          // eslint-disable-next-line max-len
          'https://api.unsplash.com/photos/random?client_id=2NwTQtIjonA_Y4sqdd4x3I1yfGEEqrf0IU77b0AYh5I&query=cute,nature,cat,skyline,summer,syngonium'
        )
        .then((res) => setBackgroundUrl(res.data.urls.regular));
  }, []);
  return (
    <Row align="middle" className="authorization">
      {isDesktop && (
        <Col xs={24} md={12}>
          <div
            style={{
              height: '100vh',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundImage: `url(${backgroundUrl})`,
            }}
          />
        </Col>
      )}
      <Col xs={24} md={12}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Card
            title={'Регистрация'}
            style={{
              width: 500,
              boxShadow: token.boxShadow,
            }}
          >
            {KeycloakApp?.authenticated && <RegistryForm />}
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default Authorization;
