import React from 'react';
import { Button, Form, message, Select, Skeleton, Space, Tag } from 'antd';
import { useQuery } from 'react-query';
import { Column as ColumnType, ColumnStatus } from '../../Column/types';
import { COLUMN_URL, getColumnList } from '../../Column/ColumnApi';
import { useParams } from 'react-router-dom';
import { useColumnUpdateMutation } from '../../Column/helpers/useColumnUpdateMutation';

const SettingColumn = React.memo(() => {
  const { boardId } = useParams();
  const { data: columnList, isLoading } = useQuery<ColumnType[]>(
    [COLUMN_URL, boardId],
    () => getColumnList(+boardId!)
  );
  const { mutateAsync, isLoading: loadingUpdate } = useColumnUpdateMutation(
    boardId!
  );
  const [messageApi, contextHolder] = message.useMessage();

  const selectOptions = [
    { label: <Tag>К выполнению</Tag>, value: 'TO_DO' },
    { label: <Tag color={'blue'}>В работе</Tag>, value: 'IN_PROGRESS' },
    { label: <Tag color={'green'}>Готово</Tag>, value: 'COMPLETE' },
  ];

  const onFinish = (values: Record<number, { status: ColumnStatus }>) => {
    const entries = Object.entries(values);

    entries
      .filter(
        (entry) =>
          columnList?.find((col) => col.id === +entry[0])?.status !==
          entry[1].status
      )
      .forEach(([colId, data], index, array) =>
        mutateAsync([+colId, data]).then(() => {
          if (index === array.length - 1)
            messageApi.open({ type: 'success', content: 'Успешно' });
        })
      );
  };

  return (
    <Skeleton loading={isLoading}>
      <Space direction={'vertical'}>
        <Form layout={'vertical'} onFinish={onFinish}>
          {columnList?.map((col) => (
            <Form.Item
              key={col.id}
              label={col.title}
              initialValue={col.status}
              name={[col.id, 'status']}
            >
              <Select options={selectOptions} style={{ width: 200 }} />
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              htmlType={'submit'}
              type={'primary'}
              loading={loadingUpdate}
            >
              Сохранить
            </Button>
          </Form.Item>
        </Form>
        {contextHolder}
      </Space>
    </Skeleton>
  );
});
SettingColumn.displayName = 'SettingColumn';
export default SettingColumn;
