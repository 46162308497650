import { useMutation, useQueryClient } from 'react-query';
import { CARD_URL, updateCard } from '../api';
import { UpdateCard } from '../type';
import { notification } from 'antd';
import { useParams } from 'react-router-dom';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { Board } from '../../Workspace/Board/types';
import { produce } from 'immer';

export const useCardUpdate = () => {
  const { boardId } = useParams();
  const queryClient = useQueryClient();
  return useMutation(
    [BOARD_URL, boardId],
    (args: [number, UpdateCard]) => updateCard(...args),
    {
      onMutate: async (data) => {
        const [id, updateValue] = data;
        await queryClient.cancelQueries([CARD_URL]);
        queryClient.setQueryData<Board>(
          [BOARD_URL, boardId],
          // @ts-ignore
          (oldData) => {
            return produce(oldData!, (draft) => {
              const currentCard = draft.columnList
                .flatMap((col) => col.task)
                .find((card) => card.id === id)!;

              const currentColumn = draft.columnList.find(
                (col) => col.id === currentCard.columnId
              )!;
              if (updateValue.hasOwnProperty('archived')) {
                currentColumn.task = currentColumn.task.filter(
                  (card) => card.id !== id
                );
              }
              Object.assign(currentCard, updateValue);
            });
          }
        );
      },
      onError: async () => {
        notification.error({ message: 'Ошибка' });
        await queryClient.invalidateQueries([BOARD_URL, boardId]);
      },
      onSuccess: async (_, variables) => {
        if (variables[1].hasOwnProperty('archived')) {
          await queryClient.invalidateQueries(['archive', boardId]);
        }
      },
    }
  );
};
