import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const CenteredSpin = React.memo(({ spinning }: { spinning?: boolean }) => {
  return spinning ? (
    <SpinWrapper>
      <Spin spinning={spinning} />
    </SpinWrapper>
  ) : (
    <></>
  );
});

const SpinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 8px;
`;

CenteredSpin.displayName = 'CenteredSpin';
export default CenteredSpin;
