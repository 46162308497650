import React, { useState } from 'react';
import {
  Button as ButtonA,
  message,
  notification,
  Popconfirm,
  Popover,
  Space,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import {
  BgColorsOutlined,
  CalendarOutlined,
  CheckCircleTwoTone,
  CheckSquareOutlined,
  DeleteOutlined,
  FolderOutlined,
  SnippetsOutlined,
  TagsOutlined,
  UnorderedListOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import AddMemberForm from './AddMemberForm';
import { Card } from '../type';
import { PopoverProps } from 'antd/es/popover';
import AddLabels from './AddLabels';
import DateForm from './DateForm';
import BackgroundForm from './BackgroundForm';
import { useCardUpdateOnce } from '../helpers/useCardUpdateOnce';
import { downloadFile } from '../../Calendar/helpers/downloadFile';
import API from '../../../utils/API/API';
import { isMobile } from 'react-device-detect';
import CardCopyChange from './CardCopyChange';
import { KeycloakApp } from '../../../utils/Keycloak/Keycloak';
import { Board } from '../../Workspace/Board/types';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useDeleteCard } from '../helpers/useDeleteCard';
import { useCreateChecklistMutation } from '../helpers/useCreateChecklistMutation';
import { CARD_URL } from '../api';

const Menu = React.memo(
  ({
    card,
    setIsAddCard,
    setModalView,
  }: {
    card?: Card;
    setIsAddCard: React.Dispatch<React.SetStateAction<boolean>>;
    setModalView: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const popoverProps: Partial<PopoverProps> = {
      placement: 'bottom',
      trigger: 'click',
      arrow: false,
    };
    const [isOpenDate, setIsOpenDate] = useState(false);
    const [isOpenLabels, setIsOpenLabels] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    const { mutateAsync: cardUpdate } = useCardUpdateOnce(
      card?.id?.toString(),
      card
    );
    const { mutate: deleteMuatation } = useDeleteCard(setModalView);
    const queryClient = useQueryClient();
    const { boardId } = useParams();
    const toDoCol = queryClient
      .getQueryData<Board>([BOARD_URL, boardId])
      ?.columnList?.find((col) => col.status === 'TO_DO');
    const cardId = location.pathname.split('/')[4];
    const { mutate: createCheckListMutation } = useCreateChecklistMutation([
      CARD_URL,
      'once',
      cardId,
    ]);
    const [isOpenChecklist, setIsOpenChecklist] = useState(false);
    const [isOpenAddMember, setIsOpenAddMember] = useState(false);

    const uploadProps: UploadProps = {
      name: 'file',
      action: `${process.env.REACT_APP_PRODUCTION_URL}/api/filesystem`,
      headers: {
        authorization: 'Bearer ' + (KeycloakApp.token ?? ''),
      },
      beforeUpload: async () => {
        if (KeycloakApp.isTokenExpired(5)) {
          await KeycloakApp.updateToken(5);
        }
      },
      defaultFileList: [],
      onChange(info) {
        if (info.file.status === 'uploading') {
          setIsUpload(true);
        }
        if (info.file.status === 'done') {
          cardUpdate({ fileList: [info.file.response.data.id] }).then(() =>
            setIsUpload(false)
          );
        } else if (info.file.status === 'error') {
          message.error(`Файл ${info.file.name} не смог загрузиться.`);
          setIsUpload(false);
        }
      },
      showUploadList: false,
      onRemove: (file) => {
        API.delete(`api/filesystem/${file.uid}`).catch(() =>
          notification.error({ message: 'Ошибка удаления файла' })
        );
      },
    };

    return (
      <Space
        direction={'vertical'}
        style={{ width: '100%', marginTop: isMobile ? 20 : undefined }}
      >
        <Typography.Text strong>Добавить на карточку</Typography.Text>
        <Space
          direction={isMobile ? 'horizontal' : 'vertical'}
          style={{ width: '100%' }}
          wrap={true}
        >
          <Popover
            {...popoverProps}
            destroyTooltipOnHide
            open={isOpenAddMember}
            onOpenChange={setIsOpenAddMember}
            content={
              <AddMemberForm
                card={card}
                cancelAdd={() => setIsOpenAddMember(false)}
              />
            }
          >
            <Button>
              <UserOutlined />
              Участники
            </Button>
          </Popover>
          <Popover
            {...popoverProps}
            content={<AddLabels card={card} setIsLabelOpen={setIsOpenLabels} />}
            open={isOpenLabels}
            onOpenChange={setIsOpenLabels}
          >
            <Button>
              <TagsOutlined />
              Метки
            </Button>
          </Popover>
          <Popover
            {...popoverProps}
            open={isOpenDate}
            onOpenChange={setIsOpenDate}
            content={
              <DateForm card={card!} onCancel={() => setIsOpenDate(false)} />
            }
          >
            <Button>
              <CalendarOutlined />
              Дата
            </Button>
          </Popover>
          {toDoCol && (
            <Button onClick={() => setIsAddCard(true)}>
              <UnorderedListOutlined />
              Добавить подзадачу
            </Button>
          )}
          <Popover
            {...popoverProps}
            open={isOpenChecklist}
            onOpenChange={setIsOpenChecklist}
            content={
              <Button
                onClick={() => {
                  createCheckListMutation(card!.id);
                  setIsOpenChecklist(false);
                }}
                disabled={!!card?.checklist}
              >
                Добавить чек-лист
              </Button>
            }
          >
            <Button>
              <CheckSquareOutlined />
              Чек-лист
            </Button>
          </Popover>

          <Popover {...popoverProps} content={<BackgroundForm card={card} />}>
            <Button>
              <BgColorsOutlined />
              Обложка
            </Button>
          </Popover>
          <Upload
            {...uploadProps}
            onPreview={downloadFile}
            className={'upload-full'}
          >
            <Button loading={isUpload} icon={<UploadOutlined />}>
              Файлы
            </Button>
          </Upload>
        </Space>
        <Typography.Text strong>Действия</Typography.Text>
        <Space
          direction={isMobile ? 'horizontal' : 'vertical'}
          style={{ width: '100%' }}
          wrap={true}
        >
          <CardCopyChange card={card} type={'copy'} />
          <CardCopyChange card={card} type={'change'} />
          <Button onClick={() => cardUpdate({ archived: !card?.archived })}>
            <FolderOutlined />{' '}
            {card?.archived ? ' Вернуть из архива' : ' В архив'}
          </Button>
          {card?.archived && (
            <Popconfirm
              title="Удаление карточки"
              description="Вы действительно хотите удалить текущую карточку"
              onConfirm={() => deleteMuatation(card.id)}
            >
              <Button danger>
                <DeleteOutlined /> Удалить
              </Button>
            </Popconfirm>
          )}
          <Button onClick={() => cardUpdate({ isTemplate: !card?.isTemplate })}>
            {card?.isTemplate ? (
              <CheckCircleTwoTone twoToneColor={'#52c41a'} />
            ) : (
              <SnippetsOutlined />
            )}{' '}
            {card?.isTemplate ? ' Шаблон' : ' Создать шаблон'}
          </Button>
        </Space>
      </Space>
    );
  }
);
Menu.displayName = 'Menu';

const Button = styled(ButtonA)`
  width: 100%;
  text-align: left;
`;
export default Menu;
