import { useMutation, useQueryClient } from 'react-query';
import { CARD_URL } from '../api';
import { Card } from '../type';
import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { Board } from '../../Workspace/Board/types';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';
import { produce } from 'immer';

export const useDeleteBondMutation = (
  queryKey: any[],
  func: (id: number) => Promise<AxiosResponse<any>>,
  updateFunction: (old: Card, newData: number) => Card,
  updatePropF: (deleteId: number) => Partial<Card>,
  boardCardId?: number
) => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();
  return useMutation(queryKey, func, {
    onMutate: async (data) => {
      const cardData = queryClient.getQueryData<Card>(queryKey);

      await queryClient.cancelQueries([CARD_URL]);
      queryClient.setQueryData<Card>(
        queryKey,
        // @ts-ignore
        (old) => updateFunction(old, data)
      );

      queryClient.setQueryData<Board>(
        [BOARD_URL, boardId],
        // @ts-ignore
        (oldData) => {
          return produce(oldData!, (draft) => {
            const currentCard = draft.columnList
              .flatMap((col) => col.task)
              .find(
                (card) => card.id === (boardCardId ? boardCardId : cardData?.id)
              )!;

            const updateProps = Object.entries(updatePropF(data));

            updateProps.forEach(([propName, value]) => {
              // @ts-ignore
              currentCard[propName] = value;
            });
          });
        }
      );

      return cardData;
    },
    onError: async (error, variables, context) => {
      notification.error({ message: 'Ошибка' });
      queryClient.setQueryData<Card>(queryKey, () => context!);
      await queryClient.invalidateQueries([BOARD_URL, boardId]);
    },
  });
};
