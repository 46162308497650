import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { CARD_URL, createAccountToTask } from '../api';
import { ProfileType } from '../../Authorization/AuthorizationTypes';
import { Card } from '../type';
import { Board } from '../../Workspace/Board/types';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { produce } from 'immer';
import { notification } from 'antd';

export const useAddAccountSubCard = (
  queryKey: any[],
  queryKeyAccountList: any[]
) => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();

  return useMutation(queryKey, createAccountToTask, {
    onMutate: async (data) => {
      const accountList =
        queryClient.getQueryData<{ account: ProfileType }[]>(
          queryKeyAccountList
        );
      const account = accountList!.find(
        (acc) => acc.account.id === data.memberIdList[0]
      );

      const cardData = queryClient.getQueryData<Card>(queryKey);

      await queryClient.cancelQueries([CARD_URL]);
      queryClient.setQueryData<Card>(
        queryKey,
        // @ts-ignore
        (oldData) => {
          return produce(oldData!, (draft) => {
            draft.subTaskList
              .find((task) => task.id === data.taskId)
              ?.accountToTask.push({
                accountId: data.memberIdList[0],
                id: 0,
                taskId: data.taskId,
                account: account!.account,
              });
          });
        }
      );

      queryClient.setQueryData<Board>(
        [BOARD_URL, boardId],
        // @ts-ignore
        (oldData) => {
          if (oldData) {
            return produce(oldData, (draft) => {
              const currentCard = draft.columnList
                .flatMap((col) => col.task)
                .find((card) => card.id === data.taskId)!;

              currentCard.accountToTask.push({
                accountId: data.memberIdList[0],
                taskId: data.taskId,
                account: account!.account,
                id: 0,
              });
            });
          }
          return [];
        }
      );

      return cardData;
    },
    onError: async (error, variables, context) => {
      notification.error({ message: 'Ошибка' });
      queryClient.setQueryData<Card>(queryKey, () => context!);
      await queryClient.invalidateQueries([BOARD_URL, boardId]);
    },
    onSuccess: async (data) => {
      queryClient.setQueryData<Card>(queryKey, (oldData) => {
        return produce(oldData!, (draft) => {
          const accountList = draft.subTaskList.find(
            (task) => task.id === data[0].taskId
          )?.accountToTask;
          accountList?.splice(-1, 1);
          accountList?.push(...data);
        });
      });
    },
  });
};
