import API from '../../utils/API/API';
import { CreateEvent } from './type';

export const EVENT_URL = 'api/events';
export const ACCOUNT_EVENT_URL = 'api/account-to-event';

export const createEvent = (values: CreateEvent) => API.post(EVENT_URL, values);

export const updateEvent = (eventId: number, values: CreateEvent) =>
  API.put(`${EVENT_URL}/${eventId}`, values).then((res) => res.data.data);

export const deleteEvent = (eventId: number) =>
  API.delete(`${EVENT_URL}/${eventId}`);

export const getEventList = (params: { boardId: number }) =>
  API.get(EVENT_URL, { params }).then((res) => res.data.data);

export const getEvent = (eventId: number) =>
  API.get(`${EVENT_URL}/${eventId}`).then((res) => res.data.data);

export const createAccountToEvent = (values: {
  eventId: number;
  memberIdList: number[];
}) => API.post(ACCOUNT_EVENT_URL, values);

export const deleteAccountToEvent = (bondId: number) =>
  API.delete(`${ACCOUNT_EVENT_URL}/${bondId}`);
