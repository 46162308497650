import API from '../../utils/API/API';
import { CreateColumn } from './types';

export const COLUMN_URL = 'api/columns';

export const createColumn = (value: CreateColumn) =>
  API.post(COLUMN_URL, value).then((res) => res.data.data);

export const getColumnList = (boardId: number) =>
  API.get(`${COLUMN_URL}?boardId=${boardId}`).then(
    (res) => res.data.data.content
  );
