import API from '../../../utils/API/API';

export const EXPLORER_URL = 'api/explorer';
export const EXPLORER_FILE_URL = 'api/explorer/file';
export const EXPLORER_FOLDER_URL = 'api/explorer/folder';

export const getFiles = (params: { path: string }) =>
  API.get(`${EXPLORER_URL}`, { params }).then((res) => res.data.data);

export const uploadFile = (data: FormData) =>
  API.post(EXPLORER_FILE_URL, data).then((res) => res.data.data);

export const createFolder = (data: { path: string; folderName: string }) =>
  API.post(EXPLORER_FOLDER_URL, data).then((res) => res.data.data);

export const renameExplorerItem = (data: {
  oldPath: string;
  newPath: string;
}) => API.put(EXPLORER_URL, data).then((res) => res.data.data);

export const deleteFolderExplorer = (data: { path: string }) =>
  API.delete(EXPLORER_FOLDER_URL, { params: data }).then(
    (res) => res.data.data
  );

export const deleteFileExplorer = (data: { path: string }) =>
  API.delete(EXPLORER_FILE_URL, { params: data }).then((res) => res.data.data);
