import styled from 'styled-components';

export const ColumnWrapper = styled.div<{
  $backgroundColor: string;
}>`
  border-radius: 20px;
  padding: 4px 9px 16px 16px;
  width: 274px;
  margin: 0 4px;
  background-color: ${(props) => props.$backgroundColor};
`;
