export const transformWord = (
  count: number,
  [one, two, five]: [string, string, string]
) => {
  let lastN = Number(count.toString().slice(-2));
  if (lastN < 21 && lastN > 4) return five;
  lastN = Number(count.toString().slice(-1));
  if (lastN === 1) return one;
  if (lastN > 4 || lastN === 0) return five;
  return two;
};
