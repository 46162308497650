export const colorPreset = [
  {
    label: 'Рекомендованные цвета',
    colors: [
      '#eb3b5a',
      '#fa8231',
      '#f7b731',
      '#20bf6b',
      '#0fb9b1',
      '#2d98da',
      '#3867d6',
      '#8854d0',
      '#a5b1c2',
      '#4b6584',
    ],
  },
];
