import { useMutation, useQueryClient } from 'react-query';
import { CommentTask, UpdateComment } from '../type';
import { notification } from 'antd';
import { COMMENT_URL, updateComment } from '../CommentApi';

export const useUpdateCommentMutation = (
  queryKey: any[],
  commentId: number | null
) => {
  const queryClient = useQueryClient();
  return useMutation(
    queryKey,
    (values: UpdateComment) => updateComment(commentId!, values),
    {
      onMutate: async (data) => {
        const commentToCard = queryClient.getQueryData<CommentTask[]>(queryKey);

        await queryClient.cancelQueries([COMMENT_URL]);
        queryClient.setQueryData<CommentTask[]>(
          queryKey,
          // @ts-ignore
          (oldData) => {
            const copy = Array.from(oldData ?? []);
            const commentIndex = copy.findIndex(
              (comment) => comment.id === commentId
            );
            copy[commentIndex] = { ...copy[commentIndex], ...data };
            if (oldData) {
              return copy;
            }
            return [];
          }
        );

        return commentToCard;
      },
      onError: async (error, variables, context) => {
        notification.error({ message: 'Ошибка' });
        queryClient.setQueryData<CommentTask[]>(queryKey, () => context!);
      },
      onSuccess: async (data) => {
        queryClient.setQueryData<CommentTask[]>(queryKey, (oldData) => {
          const copy = Array.from(oldData ?? []);
          const commentIndex = copy.findIndex(
            (comment) => comment.id === +queryKey.slice(-1)[0]
          );
          copy[commentIndex] = data;
          return copy;
        });
      },
    }
  );
};
