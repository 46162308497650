import { useCardUpdateOnce } from './useCardUpdateOnce';
import { Card } from '../type';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { Board } from '../../Workspace/Board/types';

type Arrow = 'up' | 'down' | 'left' | 'right';

export const useHotkeyFunction = (data: Card | undefined) => {
  const { boardId } = useParams();
  const cardUpdate = useCardUpdateOnce(data?.id.toString());
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const queryKey = [BOARD_URL, boardId!.toString()];

  const inArchive = (evt: KeyboardEvent) => {
    if (evt.key === 'c' && !evt.ctrlKey) {
      cardUpdate.mutate({ archived: !data?.archived });
    }
  };

  const getNextCard = (arrow: Arrow) => {
    const getCardList = () => {
      if (arrow === 'up' || arrow === 'down') {
        return boardData?.columnList?.find((col) => col.id === data?.columnId)
          ?.task;
      } else {
        return boardData?.columnList?.flatMap((col) => col.task);
      }
    };

    const boardData = queryClient.getQueryData<Board>(queryKey);
    const cardList = getCardList();

    const currentIndex = cardList?.findIndex((card) => card.id === data?.id);

    if (currentIndex === undefined || cardList === undefined) return;

    const nextIndex =
      arrow === 'left' || arrow === 'up' ? currentIndex - 1 : currentIndex + 1;

    if (currentIndex < 0 || currentIndex >= cardList.length) return;

    const nextCard = cardList[nextIndex];
    nextCard && navigate(`/board/${boardId!}/card/${nextCard?.id}`);
  };

  const navigateCard = (evt: KeyboardEvent) => {
    if (evt.code === 'ArrowDown') {
      getNextCard('down');
    }
    if (evt.code === 'ArrowUp') {
      getNextCard('up');
    }
    if (evt.code === 'ArrowLeft') {
      getNextCard('left');
    }
    if (evt.code === 'ArrowRight') {
      getNextCard('right');
    }
  };

  return { inArchive, navigateCard };
};
