import React from 'react';
import { Col, Modal, Row, Tag, Typography } from 'antd';
import { isDesktop, isMobile } from 'react-device-detect';

const Hotkeys = React.memo(
  ({
    openHelp,
    setOpenHelp,
  }: {
    openHelp: boolean;
    setOpenHelp: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    return (
      <Modal
        open={openHelp}
        onCancel={() => setOpenHelp(false)}
        title={'Горячие клавиши'}
        footer={false}
        width={isDesktop ? 800 : 300}
      >
        <Row gutter={[16, 16]}>
          <Col style={{ width: isMobile ? 300 : 800 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Text strong>Навигация по карточкам</Typography.Text>
              <div>
                <Tag>←</Tag>
                <Tag>↓</Tag>
                <Tag>↑</Tag>
                <Tag>→</Tag>
              </div>
            </div>
            <Typography.Paragraph type={'secondary'}>
              Для перемещения между карточками нажимайте стрелки влево и вправо.
              Для перемещения между карточками в одной колонке нажимайте стрелки
              вверх и вниз
            </Typography.Paragraph>
          </Col>
          <Col style={{ width: isMobile ? 300 : 800 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text strong>Посмотреть мои карточки</Typography.Text>
              <div>
                <Tag>Q</Tag>
              </div>
            </div>
            <Typography.Paragraph type={'secondary'}>
              Для того, чтобы применить фильтр по карточкам &#34;Назначены
              мне&#34; нажмите &#34;Q&#34;
            </Typography.Paragraph>
          </Col>
          <Col style={{ width: isMobile ? 300 : 800 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text strong>
                Переместить карточки в архив
              </Typography.Text>
              <div>
                <Tag>C</Tag>
              </div>
            </div>
            <Typography.Paragraph type={'secondary'}>
              Для того, чтобы переместить карточку в архив нажмите &#34;C&#34;
            </Typography.Paragraph>
          </Col>
        </Row>
      </Modal>
    );
  }
);
Hotkeys.displayName = 'Hotkeys';
export default Hotkeys;
