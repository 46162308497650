import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteEvent, EVENT_URL, getEvent } from '../api';
import { Avatar, Button, Modal, Space, Spin, Typography, Upload } from 'antd';
import { EventCalendar } from '../type';
import dayjs from 'dayjs';
import { ExclamationCircleFilled, UserOutlined } from '@ant-design/icons';
import { downloadFile } from '../helpers/downloadFile';
import FormEvent from './FormEvent';
import { useNavigate, useParams } from 'react-router-dom';
import MdViewerTb from '../../../utils/Copmonents/MdViewerTb';
import { useGetWorkplaceId, useRole } from '../../../utils/helpers/useRole';

const { confirm } = Modal;

const EventView = React.memo(() => {
  const { eventId, boardId } = useParams();
  const { data: eventData, isLoading } = useQuery<EventCalendar>(
    [EVENT_URL, +eventId!],
    () => getEvent(+eventId!)
  );
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const workplaceId = useGetWorkplaceId();
  const role = useRole(workplaceId ?? 0);

  const queryClient = useQueryClient();

  const { mutateAsync: deleteMutation, isLoading: deleteLoading } = useMutation(
    [EVENT_URL, +eventId!],
    deleteEvent,
    {
      onSuccess: (_, variables) => {
        queryClient.setQueryData<{ id: number }[]>(
          [EVENT_URL, { boardId }],
          (old) => old?.filter((event) => event.id !== variables) ?? []
        );
      },
    }
  );

  const defaultList =
    eventData?.fileList?.map((file) => ({
      uid: file.id.toString(),
      name: file.fileName,
      url: `${process.env.REACT_APP_PRODUCTION_URL}/api/filesystem/${file.id}`,
      type: file.contentType,
    })) ?? [];

  const getDate = (date: Date | string | undefined, isTime: boolean) => {
    const formatWithTime = 'dddd, DD MMMM, HH:mm:ss';
    const formatWithoutTime = 'dddd, DD MMMM';
    return date
      ? dayjs(date).format(isTime ? formatWithTime : formatWithoutTime)
      : undefined;
  };

  const showPromiseConfirm = () => {
    confirm({
      title: 'Вы действительно хотите удалить данное событие?',
      icon: <ExclamationCircleFilled />,
      onOk() {
        return deleteMutation(+eventId!).then(() => navigate('..'));
      },
    });
  };

  return (
    <Modal
      title={'Детали события'}
      open={true}
      onCancel={() => navigate('..')}
      footer={
        role === 'lead' ? (
          <Space>
            <Button loading={deleteLoading} onClick={showPromiseConfirm}>
              Удалить
            </Button>
            <Button onClick={() => setIsEdit(true)} type={'primary'}>
              Редактировать
            </Button>
          </Space>
        ) : null
      }
      destroyOnClose={true}
      width={700}
    >
      <Spin spinning={isLoading}>
        <Typography.Title level={3}>{eventData?.title}</Typography.Title>
        <Typography.Title level={5} type={'secondary'}>
          <span>{getDate(eventData?.startDate, !!eventData?.startTime)}</span>
          {eventData?.endDate
            ? ' - ' + getDate(eventData.endDate, !!eventData?.endTime)
            : ''}
        </Typography.Title>
        {!!eventData?.description && (
          <>
            <Typography.Title level={5}>Описание:</Typography.Title>
            <MdViewerTb source={eventData.description} />
          </>
        )}
        {!!eventData?.memberList.length && (
          <>
            <Typography.Title level={5}>Участники:</Typography.Title>
            {eventData.memberList.map(({ id, account }) => (
              <Space key={id}>
                <Avatar
                  src={account.avatarUrl}
                  icon={<UserOutlined />}
                  size={'small'}
                />{' '}
                <Typography.Text type={'secondary'}>
                  {account.personFullName}
                </Typography.Text>{' '}
              </Space>
            ))}
          </>
        )}
        {!!eventData?.fileList?.length && (
          <div style={{ marginTop: 8 }}>
            <Typography.Title level={5}>Прикрепленные файлы:</Typography.Title>
            <Upload
              fileList={defaultList}
              onPreview={downloadFile}
              showUploadList={{ showRemoveIcon: false }}
            />
          </div>
        )}
      </Spin>
      <Modal
        title={'Редактирование события'}
        open={isEdit}
        onCancel={() => setIsEdit(false)}
        footer={false}
        destroyOnClose={true}
        width={700}
      >
        <FormEvent eventId={+eventId!} onCancel={() => setIsEdit(false)} />
      </Modal>
    </Modal>
  );
});
EventView.displayName = 'EventView';
export default EventView;
