import React, { useState } from 'react';
import { Gantt, Task } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import { Button, Drawer, Row, Space, Switch, Typography } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import FilterGantt from './component/FilterGantt';
import { useQuery, useQueryClient } from 'react-query';
import { GANTT_URL, getGanttTaskList } from './api';
import dayjs from 'dayjs';
import styled from 'styled-components';
import GanttTaskView from './component/GanttTaskView';
import { useNavigate, useParams } from 'react-router-dom';
import { useGenericMutation } from '../../utils/hooks/useGenericMutate';
import { CARD_URL, updateCard } from '../Card/api';
import { UpdateCard } from '../Card/type';
import BoardLayout from '../BoardLayout/BoardLayout';
import { isDesktop, isMobile } from 'react-device-detect';

const GanttView = React.memo(() => {
  const [isFilter, setIsFilter] = useState(false);
  const { boardId: id } = useParams();
  const currentDate = [
    dayjs().startOf('month').format(),
    dayjs().endOf('month').format(),
  ];
  const params = { boardId: +id!, date: currentDate.join() };
  const { data: taskList } = useQuery<Task[]>([GANTT_URL, params], () =>
    getGanttTaskList(params)
  );
  const { mutate: updateCardMutation } = useGenericMutation(
    (arg: [number, UpdateCard]) => updateCard(...arg),
    [CARD_URL, 'gantt']
  );
  const navigate = useNavigate();
  const [isViewTaskList, setIsViewTaskList] = useState(isDesktop);

  const queryClient = useQueryClient();

  const dateChange = (task: Task) => {
    return updateCardMutation(
      [
        +task.id,
        {
          startDate: dayjs(task.start).format(),
          completeDate: dayjs(task.end).format(),
        },
      ],
      {
        onSettled: async () =>
          await queryClient.invalidateQueries([GANTT_URL, params]),
      }
    );
  };

  return (
    <BoardLayout>
      <div style={{ margin: '20px auto 0' }}>
        <Row justify={'end'} style={{ marginBottom: 8, padding: '0 20px' }}>
          <Space>
            {isDesktop && (
              <>
                <Typography.Text style={{ color: 'white' }}>
                  Список задач
                </Typography.Text>{' '}
                && (
                <Switch
                  onChange={setIsViewTaskList}
                  defaultChecked={isDesktop}
                />
                )
              </>
            )}
            <Button
              size={'small'}
              icon={<FilterOutlined />}
              onClick={() => setIsFilter(true)}
            />
          </Space>
        </Row>
        {!!taskList?.length && (
          <div style={{ backgroundColor: 'white' }}>
            <Gantt
              tasks={taskList.map((task) => ({
                ...task,
                start: new Date(task.start),
                end: new Date(task.end),
              }))}
              locale={'ru'}
              onDoubleClick={(task) => navigate(`card/${task.id}`)}
              onSelect={
                isMobile ? (task) => navigate(`card/${task.id}`) : undefined
              }
              onDateChange={dateChange}
              listCellWidth={isViewTaskList ? '155px' : ''}
              TooltipContent={({ task }) => <GanttTaskView card={task} />}
              TaskListHeader={() => (
                <HeaderInfo>
                  <HeaderCell className={'_3lLk3'}>Название задачи</HeaderCell>
                  <HeaderCell className={'_3lLk3'}>Дата начала</HeaderCell>
                  <HeaderCell className={'_3lLk3'}>Дата окончания</HeaderCell>
                </HeaderInfo>
              )}
            />
          </div>
        )}
        <Drawer
          open={isFilter}
          onClose={() => setIsFilter(false)}
          title={'Фильтры'}
        >
          <FilterGantt />
        </Drawer>
      </div>
    </BoardLayout>
  );
});
GanttView.displayName = 'GanttView';

const HeaderInfo = styled.div`
  height: 50px;
  display: flex;
`;

const HeaderCell = styled.div`
  max-width: 155px;
  min-width: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default GanttView;
