import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

// @ts-ignore
const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export default store;
