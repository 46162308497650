import React, { useEffect, useState } from 'react';
import { CommentTask } from '../type';
import { Avatar, Button, List, Space } from 'antd';
import { useQuery } from 'react-query';
import { COMMENT_URL, getCommentList } from '../CommentApi';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../store/rootReducer';
import { isMobile } from 'react-device-detect';
import MdViewerTb from '../../../utils/Copmonents/MdViewerTb';
import MdEditorTb from '../../../utils/Copmonents/MdEditorTb';
import { useUpdateCommentMutation } from '../helpers/useUpdateCommentMutation';
import { useDeleteCommentMutation } from '../helpers/useDeleteCommentMutation';
import { UserOutlined } from '@ant-design/icons';

const CommentList = React.memo(() => {
  const { id } = useParams();
  const { data: commentList } = useQuery<CommentTask[]>([COMMENT_URL, id], () =>
    getCommentList(+id!)
  );

  const mutateCommentDelete = useDeleteCommentMutation([COMMENT_URL, id]);

  const [commentEdit, setCommentEdit] = useState<string>();
  const [currentCommentId, setCurrentCommentId] = useState<number | null>(null);

  const mutateComment = useUpdateCommentMutation(
    [COMMENT_URL, id],
    currentCommentId
  );

  const closeEdit = () => {
    setCurrentCommentId(null);
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeEdit);
    return () => document.removeEventListener('mousedown', closeEdit);
  }, []);

  const onEdit = (comment: string, id: number) => {
    setCurrentCommentId(id);
    setCommentEdit(comment);
  };

  const onSubmit = () => {
    mutateComment.mutate({
      text: commentEdit ?? '',
      authorId: profile!.id,
    });
    setCurrentCommentId(null);
  };

  const { profile } = useAppSelector((state) => state.authReducer);
  return commentList?.length ? (
    <Space
      direction={'vertical'}
      style={{ width: '100%', marginLeft: isMobile ? 0 : 20 }}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <List
        itemLayout={'vertical'}
        size={'small'}
        dataSource={commentList}
        renderItem={(item) => (
          <List.Item
            actions={
              item.authorId === profile?.id
                ? [
                    <Button
                      type={'text'}
                      size={'small'}
                      style={{ fontSize: '0.85em' }}
                      onClick={() => onEdit(item.text, item.id)}
                      key={'edit'}
                    >
                      Редактировать
                    </Button>,
                    <Button
                      type={'text'}
                      size={'small'}
                      style={{ fontSize: '0.85em' }}
                      key={'delete'}
                      onClick={() => mutateCommentDelete.mutate(item.id)}
                    >
                      Удалить
                    </Button>,
                  ]
                : undefined
            }
          >
            <List.Item.Meta
              avatar={
                <Avatar src={item.author?.avatarUrl} icon={<UserOutlined />} />
              }
              description={dayjs(item.creationTime).fromNow()}
              title={item.author?.personFullName}
            />
            {!(item.id === currentCommentId && item.id) ? (
              <MdViewerTb source={item.text} />
            ) : (
              <Space style={{ width: '100%' }} direction={'vertical'}>
                <MdEditorTb value={commentEdit} onChange={setCommentEdit} />
                <Button
                  size={'small'}
                  type={'primary'}
                  onClick={() => onSubmit()}
                >
                  Сохранить
                </Button>
              </Space>
            )}
          </List.Item>
        )}
      />
    </Space>
  ) : (
    <></>
  );
});
CommentList.displayName = 'CommentList';
export default CommentList;
