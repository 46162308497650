import { useMutation, useQueryClient } from 'react-query';
import { useAppSelector } from '../../../../store/rootReducer';
import { WORKPLACE_URL } from '../api/WorkplaceApi';
import { Workplace } from '../../types';
import { notification } from 'antd';
import {
  ACCOUNT_ON_WORKPLACE_URL,
  deleteAccountOnWorkplace,
} from '../components/api';

export const useDeleteAccountBondMutation = (workplaceId?: string) => {
  const queryClient = useQueryClient();
  const { profile } = useAppSelector((state) => state.authReducer);
  return useMutation(
    [ACCOUNT_ON_WORKPLACE_URL, workplaceId],
    deleteAccountOnWorkplace,
    {
      onMutate: async (newData) => {
        await queryClient.cancelQueries([ACCOUNT_ON_WORKPLACE_URL]);
        const prevValues = queryClient.getQueryData([
          ACCOUNT_ON_WORKPLACE_URL,
          workplaceId,
        ]);
        queryClient.setQueryData(
          [ACCOUNT_ON_WORKPLACE_URL, workplaceId],
          // @ts-ignore
          (oldData) => oldData.filter((bond: any) => bond.id !== newData)
        );

        return prevValues;
      },
      onSuccess: (data, variables, context) => {
        // @ts-ignore
        const currentWorkplace = context.find((bond) => bond.id === variables);

        if (currentWorkplace.accountId === profile?.id)
          queryClient.setQueryData(
            [WORKPLACE_URL, { profileId: profile?.id }],
            (old) => {
              // @ts-ignore
              return old.filter(
                (workplace: Workplace) =>
                  workplace.id !== currentWorkplace.workplaceId
              );
            }
          );
      },
      onError: (error, variables, context) => {
        notification.error({
          message: 'Ошибка',
        });
        queryClient.setQueryData<Workplace>(
          [ACCOUNT_ON_WORKPLACE_URL, workplaceId],
          // @ts-ignore
          () => context
        );
      },
    }
  );
};
