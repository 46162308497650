import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, InputNumber, Row, Select, Skeleton } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Card, CreateCard } from '../type';
import { useQuery } from 'react-query';
import {
  BOARD_URL_FULL,
  getBoardList,
} from '../../Workspace/Board/api/BoardApi';
import { useAppSelector } from '../../../store/rootReducer';
import { BoardFull, ColumnBoardFull } from '../../Workspace/Board/types';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateSimpleCardMutation } from '../helpers/useCreateSimpleCardMutation';
import ChecklistInCreateCard from './ChecklistInCreateCard';
import { groupBy } from 'lodash';
import { useUpdateCardMutation } from '../../Workspace/Board/helpers/useUpdateCardMutation';

type Create = {
  title: string;
  columnId: number;
  numberInColumn: number;
};

const FormCopyChange = React.memo(
  ({
    card,
    setOpen,
    type,
  }: {
    card: Card | undefined;
    setOpen: Function;
    type: 'copy' | 'change';
  }) => {
    const { profile } = useAppSelector((state) => state.authReducer);
    const { boardId: boardIdParam } = useParams();
    const params = { accountId: profile?.id };
    const { data, isLoading } = useQuery<BoardFull[]>(
      [BOARD_URL_FULL, params],
      () => getBoardList(params)
    );
    const [currentBoard, setCurrentBoard] = useState<BoardFull>();
    const [currentColumn, setCurrentColumn] = useState<ColumnBoardFull>();
    const { mutateAsync: createMutation, isLoading: isLoadingCreate } =
      useCreateSimpleCardMutation();
    const { mutateAsync: mutateChange } = useUpdateCardMutation();
    const [isMember, setIsMember] = useState(true);
    const [isCheckItem, setIsCheckItem] = useState(true);
    const [isLabel, setIsLabel] = useState(true);
    const [numberInColumn, setNumberInColumn] = useState<
      string | number | null
    >(1);
    const [title, setTitle] = useState(card?.title);
    const [boardId, setBoardId] = useState(card?.column?.boardId);
    const [columnId, setColumnId] = useState(card?.column?.id);
    const navigate = useNavigate();

    const boardOptions = useMemo(() => {
      const result = [];
      if (data) {
        const boardByWorkplace = groupBy(data, 'workplace.title');
        // eslint-disable-next-line guard-for-in
        for (const boardByWorkplaceElement in boardByWorkplace) {
          result.push({
            label: boardByWorkplaceElement,
            options: boardByWorkplace[boardByWorkplaceElement].map((board) => ({
              label: board.title,
              value: board.id,
              body: board,
            })),
          });
        }
      }
      return result;
    }, [data]);

    const onFinish = (values: Create) => {
      type === 'copy' ? onCreate(values) : onChange(values);
    };

    const onCreate = ({ title, columnId, numberInColumn }: Create) => {
      const createValue: CreateCard = {
        title,
        columnId,
        numberInColumn: numberInColumn - 1,
        memberIdList: isMember
          ? card?.accountToTask.map((acc) => acc.accountId)
          : undefined,
        description: card?.description,
        checklistItems: isCheckItem
          ? card?.checklist?.checklistItems.map((item) => ({
              title: item.title,
              isCompleted: item.isCompleted,
            }))
          : undefined,
        labelList: isLabel
          ? card?.labelToTask.map((label) => label.labelId)
          : undefined,
        backgroundColor: card?.backgroundColor,
        backgroundUrl: card?.backgroundUrl ?? undefined,
        startDate: card?.startDate,
        isCompleted: card?.isCompleted,
        authorId: profile?.id,
        archived: card?.archived,
        isTemplate: card?.isTemplate,
        completeDate: card?.completeDate ?? undefined,
        fileList: card?.fileList?.map((file) => file.id),
        stickerList: card?.stickerToTask.map((sticker) => ({
          ...sticker,
          id: undefined,
          taskId: undefined,
        })),
      };
      createMutation(createValue).then((res) => {
        navigate(`/board/${currentBoard?.id}/card/${res?.id}`);
        setOpen(false);
      });
    };

    const onChange = (values: Create) => {
      mutateChange([
        card!.id,
        {
          columnId: values.columnId,
          numberInColumn: values.numberInColumn - 1,
        },
      ]).then(() => {
        navigate(`/board/${currentBoard?.id}/card/${card?.id}`);
        setOpen(false);
      });
    };

    useEffect(() => {
      if (data && !currentBoard && card) {
        const board = data.find((board) => board.id === +boardIdParam!);
        const column = board?.columnList?.find(
          (column) => column.id === card!.column.id
        );
        setCurrentBoard(board);
        setCurrentColumn(column);
        setNumberInColumn(
          type === 'copy' ? column!._count.task + 1 : column!._count.task
        );
      }
    }, [data, card]);

    const columnOptions = useMemo(
      () =>
        currentBoard?.columnList?.map((column) => ({
          label: column.title,
          value: column.id,
          body: column,
        })),
      [currentBoard]
    );

    const rules = [{ required: true, message: 'Поле не может быть пустым' }];

    const fields = [
      {
        name: 'numberInColumn',
        value: numberInColumn,
      },
      { name: 'title', value: title },
      { name: 'boardId', value: boardId },
      { name: 'columnId', value: columnId },
    ];

    const checkPosition = (column: ColumnBoardFull) => {
      return type === 'copy'
        ? column._count.task + 1
        : column.id === card?.columnId
        ? column._count.task
        : column._count.task + 1;
    };

    return (
      <Skeleton loading={isLoading}>
        <Form layout={'vertical'} onFinish={onFinish} fields={fields}>
          <Row gutter={8}>
            {type === 'copy' && (
              <Col span={24}>
                <Form.Item
                  label={'Наименование'}
                  initialValue={card?.title}
                  name={'title'}
                  rules={rules}
                >
                  <TextArea
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                label={'Доска'}
                initialValue={card?.column?.boardId}
                name={'boardId'}
                rules={rules}
              >
                <Select
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={setBoardId}
                  options={boardOptions}
                  onSelect={(_, option: any) => {
                    setCurrentBoard(option.body);
                    setColumnId(option.body.columnList[0]?.id);
                    setCurrentColumn(option.body.columnList[0]);
                    setNumberInColumn(
                      option.body.columnList[0]?._count.task !== undefined
                        ? checkPosition(option.body.columnList[0])
                        : null
                    );
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={'Колонка'}
                initialValue={card?.column?.id}
                name={'columnId'}
                rules={rules}
              >
                <Select
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={setColumnId}
                  options={columnOptions}
                  onSelect={(_, option) => {
                    setCurrentColumn(option.body);
                    setNumberInColumn(checkPosition(option.body));
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={'Позиция'}
                rules={rules}
                name={'numberInColumn'}
              >
                <InputNumber
                  onKeyDown={(e) => e.stopPropagation()}
                  max={currentColumn ? checkPosition(currentColumn) : 1}
                  min={1}
                  style={{ width: '100%' }}
                  onChange={setNumberInColumn}
                />
              </Form.Item>
            </Col>
            {!!(
              card?.labelToTask?.length ||
              card?.checklist?.checklistItems?.length ||
              card?.accountToTask?.length
            ) &&
              type === 'copy' && (
                <Col>
                  <ChecklistInCreateCard
                    setIsLabel={setIsLabel}
                    setIsMember={setIsMember}
                    setIsCheckItem={setIsCheckItem}
                    card={card}
                  />
                </Col>
              )}
            <Form.Item>
              <Button
                htmlType={'submit'}
                type={'primary'}
                loading={isLoadingCreate}
              >
                {type === 'copy' ? 'Копировать' : 'Переместить'}
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Skeleton>
    );
  }
);
FormCopyChange.displayName = 'FormCopy';
export default FormCopyChange;
