import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  InputRef,
  MenuProps,
  Progress,
  Row,
  Space,
  Typography,
} from 'antd';
import {
  CheckSquareOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Card, ChecklistItem, CreateChecklistItem } from '../type';
import { CARD_URL, deleteChecklist, deleteChecklistItem } from '../api';
import { isMobile } from 'react-device-detect';
import { useCreateChecklistItem } from '../helpers/useCreateChecklistItem';
import { useUpdateChecklistMutation } from '../helpers/useUpdateChecklistMutation';
import { useDeleteBondMutation } from '../helpers/useDeleteBondMutation';

const Checklist = React.memo(({ card }: { card: Card }) => {
  const inputRef = useRef<InputRef>(null);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState<number>(0);
  const queryKey = [CARD_URL, 'once', card.id.toString()];

  const { mutate: createChecklistItemMutate, isLoading } =
    useCreateChecklistItem(queryKey);

  const { mutateAsync: updateChecklistMutate } =
    useUpdateChecklistMutation(queryKey);

  const { mutate: deleteCheckItemMutation } = useDeleteBondMutation(
    queryKey,
    deleteChecklistItem,
    (old, newData) => ({
      ...old,
      // @ts-ignore
      checklist: {
        ...old.checklist,
        checklistItems: old.checklist!.checklistItems.filter(
          (item) => item.id !== newData
        ),
      },
    }),
    (index) => ({
      checklist: {
        ...card.checklist,
        checklistItems: card.checklist?.checklistItems.filter(
          (item) => item.id !== index
        ),
      },
    })
  );

  const { mutate: deleteChecklistMutate, isLoading: isLoadingDelete } =
    useDeleteBondMutation(
      queryKey,
      deleteChecklist,
      (oldData) => ({
        ...oldData,
        checklist: undefined,
      }),
      () => ({
        checklist: undefined,
      })
    );

  useEffect(() => {
    if (isEdit) {
      inputRef.current?.focus({ cursor: 'end' });
    }
  }, [isEdit]);

  const menuItems = (id: number): MenuProps['items'] => [
    {
      label: 'Редактировать',
      key: '1',
      icon: <EditOutlined />,
      onClick: () => setIsEdit(id),
    },
    {
      label: 'Удалить',
      key: '2',
      icon: <DeleteOutlined />,
      onClick: () => deleteCheckItemMutation(id),
    },
  ];

  const onFinish = (values: Omit<CreateChecklistItem, 'checklistId'>) => {
    createChecklistItemMutate({
      ...values,
      checklistId: card.checklist!.id,
    });
  };

  const onEditFinish = (values: any, id: number) => {
    updateChecklistMutate([id, values]).then(() => setIsEdit(0));
  };

  const getPercent = (checkListItemList: ChecklistItem[]) => {
    const isCompleteCount =
      checkListItemList.filter((item) => item.isCompleted)?.length ?? 0;
    if (isCompleteCount === 0) return 0;
    return +((isCompleteCount / checkListItemList.length) * 100).toFixed(2);
  };

  return (
    <ChecklistWrapper>
      <Row justify={'space-between'}>
        <Typography.Title level={5}>
          <CheckSquareOutlined style={{ marginRight: 8 }} />
          Чек-лист
        </Typography.Title>
        <Button
          size={'small'}
          onClick={() => deleteChecklistMutate(card.checklist!.id)}
          loading={isLoadingDelete || !card.checklist?.id}
        >
          Удалить
        </Button>
      </Row>
      <Space direction={'vertical'} style={{ width: '100%' }}>
        {!!card.checklist?.checklistItems?.length && (
          <Progress percent={getPercent(card.checklist?.checklistItems)} />
        )}
        {card.checklist?.checklistItems?.map((item) => (
          <Row wrap={false} justify={'space-between'} key={item.id}>
            <Space>
              <Checkbox
                key={item.id}
                defaultChecked={item.isCompleted}
                onChange={(e) =>
                  updateChecklistMutate([
                    item.id,
                    {
                      checklistId: item.checklistId,
                      isCompleted: e.target.checked,
                    },
                  ])
                }
              />
              {!(isEdit === item.id) ? (
                <Typography.Text delete={item.isCompleted}>
                  {item.title}
                </Typography.Text>
              ) : (
                <Form
                  style={{ width: 500 }}
                  onFinish={(values) => onEditFinish(values, item.id)}
                >
                  <Form.Item name={'title'} noStyle>
                    <Input
                      defaultValue={item.title}
                      ref={inputRef}
                      onBlur={() => setIsEdit(0)}
                    />
                  </Form.Item>
                </Form>
              )}
            </Space>
            <Dropdown trigger={['click']} menu={{ items: menuItems(item.id) }}>
              <Button icon={<MoreOutlined />} type={'text'} size={'small'} />
            </Dropdown>
          </Row>
        ))}
        {isAdd ? (
          <Form onFinish={onFinish}>
            <Form.Item
              name={'title'}
              rules={[{ message: 'Поле не может быть пустым', required: true }]}
            >
              <Input
                onKeyDown={(e) => e.stopPropagation()}
                autoFocus
                placeholder={'Введите описание подзадачи'}
              />
            </Form.Item>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                Добавить
              </Button>
              <Button onClick={() => setIsAdd(false)}>Отменить</Button>
            </Space>
          </Form>
        ) : (
          <Button
            loading={isLoading || !card.checklist?.id}
            style={{ marginTop: 8 }}
            onClick={() => {
              setIsAdd(true);
            }}
          >
            Добавить элемент
          </Button>
        )}
      </Space>
    </ChecklistWrapper>
  );
});
Checklist.displayName = 'Checklist';
export default Checklist;

const ChecklistWrapper = styled.div`
  margin-left: ${isMobile ? 0 : '24px'};
`;
