import React, { useEffect, useState } from 'react';
import { Card } from '../type';
import styled from 'styled-components';
import { Button, Space, theme, Typography } from 'antd';
import { AlignLeftOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import MdEditorTb from '../../../utils/Copmonents/MdEditorTb';
import MdViewerTb from '../../../utils/Copmonents/MdViewerTb';
import { useCardUpdateOnce } from '../helpers/useCardUpdateOnce';
import { useGetWorkplaceId, useRole } from '../../../utils/helpers/useRole';

const { useToken } = theme;

const Description = React.memo(
  ({ description }: { description: Card['description'] }) => {
    const [isEdit, setIsEdit] = useState(false);
    const { token } = useToken();
    const { id } = useParams();
    // @ts-ignore
    const [descriptionEdit, setDescriptionEdit] = useState<string>();

    const cardUpdate = useCardUpdateOnce(id);

    const workplaceId = useGetWorkplaceId();
    const role = useRole(workplaceId ?? 0);

    useEffect(() => {
      window.document.addEventListener('mousedown', () => setIsEdit(false));
    }, []);

    const onFinish = () => {
      cardUpdate
        .mutateAsync({
          description: descriptionEdit,
        })
        .then(() => setIsEdit(false));
    };

    const onClickDescription = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (role !== 'user') setIsEdit(true);
    };
    return (
      <DescriptionWrapper onMouseDown={(e) => e.stopPropagation()}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={5}>
            <AlignLeftOutlined style={{ marginRight: 8 }} />
            Описание
          </Typography.Title>
          {!isEdit && role !== 'user' && (
            <Button size={'small'} onClick={() => setIsEdit(true)}>
              Изменить
            </Button>
          )}
        </div>
        {isEdit ? (
          <Space direction={'vertical'} style={{ width: '100%' }}>
            <MdEditorTb
              value={descriptionEdit ?? description}
              onChange={setDescriptionEdit}
            />
            <Space>
              <Button onClick={() => onFinish()} type={'primary'}>
                Сохранить
              </Button>
              <Button onClick={() => setIsEdit(false)}>Отмена</Button>
            </Space>
          </Space>
        ) : description ? (
          <MdViewerTb source={descriptionEdit ?? description} />
        ) : (
          <Text bgColor={token.colorBgLayout} onClick={onClickDescription}>
            {role === 'user'
              ? 'Описание отсутствует'
              : 'Добавить более подробное описание...'}
          </Text>
        )}
      </DescriptionWrapper>
    );
  }
);

const DescriptionWrapper = styled.div`
  margin-left: ${isMobile ? 0 : '24px'};
`;

const Text = styled.div<{ bgColor: string }>`
  padding: 8px;
  height: 40px;
  border-radius: 4px;
  background-color: ${(props) => props.bgColor};
  cursor: text;
`;
Description.displayName = 'Description';
export default Description;
