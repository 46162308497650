import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Button,
  Checkbox,
  ColorPicker,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Space,
  TimePicker,
  Tooltip,
  Upload,
  UploadProps,
} from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ACCOUNT_ON_WORKPLACE_URL,
  getAccountOnWorkplaceList,
} from '../../Workspace/Home/components/api';
import { Board } from '../../Workspace/Board/types';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { AccountOnWorkplace } from '../../Workspace/types';
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { colorPreset } from '../../../utils/helpers/colorPreset';
import {
  ACCOUNT_EVENT_URL,
  createAccountToEvent,
  createEvent,
  deleteAccountToEvent,
  EVENT_URL,
  updateEvent,
} from '../api';
import { CreateEvent, EventCalendar } from '../type';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../store/rootReducer';
import { ProfileType } from '../../Authorization/AuthorizationTypes';
import { useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { downloadFile } from '../helpers/downloadFile';
import { deleteFile } from '../../../utils/helpers/deleteFile';
import { isMobile } from 'react-device-detect';
import MdEditorTb from '../../../utils/Copmonents/MdEditorTb';
import { KeycloakApp } from '../../../utils/Keycloak/Keycloak';

const formatDate = (
  value: string | undefined,
  format?: string
): typeof value => {
  return value ? dayjs(value).format(format ?? '') : undefined;
};

const formatDayJS = (value: string | undefined): dayjs.Dayjs | null => {
  return value ? dayjs(value) : null;
};

const FormEvent = React.memo(
  ({
    onCancel,
    date,
    eventId,
  }: {
    onCancel: Function;
    date?: string;
    eventId?: number;
  }) => {
    const rules = [{ required: true, message: 'Поле не должно быть пустым' }];
    const { boardId: id } = useParams();
    const queryClient = useQueryClient();
    const board = queryClient.getQueryData<Board>([BOARD_URL, id]);
    const event = queryClient.getQueryData<EventCalendar>([EVENT_URL, eventId]);
    const [fileListId, setFileListId] = useState<number[]>();
    const [isRepeat, setIsRepeat] = useState(!!event?.repeatEvent);
    const [color, setColor] = useState(event?.color ?? '#3867D6');
    const [description, setDescription] = useState<string | undefined>(
      event?.description
    );
    const [title, setTitle] = useState(event?.title);
    const [startDate, setStartDate] = useState(
      formatDayJS(date ?? event?.startDate)
    );
    const [endDate, setEndDate] = useState(formatDayJS(event?.endDate));
    const [startTime, setStartTime] = useState(formatDayJS(event?.startTime));
    const [endTime, setEndTime] = useState(formatDayJS(event?.endTime));
    const [memberList, setMemberList] = useState(
      event?.memberList.map((mem) => mem.account.id) ?? []
    );
    const [isDescription, setIsDescription] = useState(false);
    const { data: accountList } = useQuery<AccountOnWorkplace[]>(
      [ACCOUNT_ON_WORKPLACE_URL],
      () => getAccountOnWorkplaceList(board!.workplaceId)
    );

    const { mutate: createAccountBondMutation, isLoading: isLoadingCreateAcc } =
      useMutation([ACCOUNT_EVENT_URL, eventId], createAccountToEvent);
    const { mutate: deleteAccountBondMutation, isLoading: isLoadingDeleteAcc } =
      useMutation([ACCOUNT_EVENT_URL, eventId], deleteAccountToEvent);

    const profile: ProfileType | null = useAppSelector(
      (state) => state.authReducer.profile
    );

    const { mutateAsync: createEventMutate, isLoading } = useMutation(
      [EVENT_URL],
      createEvent,
      {
        onSuccess: async () => {
          await queryClient.refetchQueries([EVENT_URL, { boardId: id }]);
        },
      }
    );

    const { mutateAsync: updateEventMutate, isLoading: isLoadingUpdate } =
      useMutation(
        [EVENT_URL, eventId],
        (values: CreateEvent) => updateEvent(eventId!, values),
        {
          onSuccess: async (data) => {
            queryClient.setQueryData([EVENT_URL, eventId], () => data);
            await queryClient.refetchQueries([EVENT_URL, { boardId: id }]);
          },
        }
      );

    const options = useMemo(
      () =>
        accountList?.map(({ account: member }) => ({
          label: (
            <Space>
              <Avatar src={member.avatarUrl} size={20} />
              <span>{member.personFullName}</span>
            </Space>
          ),
          value: member.id,
        })),
      [accountList]
    );

    const repeatOption = [
      { label: 'день', value: 'день' },
      { label: 'неделя', value: 'неделя' },
      { label: 'месяц', value: 'месяц' },
      { label: 'год', value: 'год' },
    ];

    const uploadProps: UploadProps = {
      name: 'file',
      action: `${process.env.REACT_APP_PRODUCTION_URL}/api/filesystem`,
      headers: {
        authorization: `Bearer ${KeycloakApp.token}`,
      },
      defaultFileList:
        event?.fileList?.map((file) => ({
          uid: file.id.toString(),
          name: file.fileName,
          url: `${process.env.REACT_APP_PRODUCTION_URL}/api/filesystem/${file.id}`,
          type: file.contentType,
        })) ?? [],
      onChange(info) {
        if (info.file.status === 'done') {
          setFileListId((prevState) => [
            ...(prevState ?? []),
            info.file.response.data.id,
          ]);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      onRemove: (file) => {
        deleteFile(file.uid);
      },
      beforeUpload,
    };

    /**
     * @return {Promise}
     */
    async function beforeUpload() {
      if (KeycloakApp?.isTokenExpired(5)) {
        await KeycloakApp.updateToken(5).then(() => {
          uploadProps!.headers!.authorization! = `Bearer ${KeycloakApp.token}`;
        });
      }
    }

    const onFinish = (values: CreateEvent) => {
      const createValues: CreateEvent = {
        ...values,
        fileList: fileListId,
        startTime: formatDate(values.startTime, ''),
        endTime: formatDate(values.endTime, ''),
        boardId: board!.id,
        startDate: formatDate(values.startDate, '')!,
        endDate: formatDate(values.endDate, ''),
        authorId: profile!.id,
        color,
        description,
      };
      eventId
        ? updateEventMutate({ ...createValues, memberList: undefined }).then(
            () => onCancel()
          )
        : createEventMutate(createValues).then(() => onCancel());
    };

    const clickOut = useCallback(() => {
      setIsDescription(false);
    }, []);

    useEffect(() => {
      document.addEventListener('mousedown', clickOut);
      return () => document.removeEventListener('mousedown', clickOut);
    }, []);

    const fields = [
      { name: 'title', value: title },
      { name: 'description', value: description },
      { name: 'endDate', value: endDate },
      { name: 'startDate', value: startDate },
      { name: 'startTime', value: startTime },
      { name: 'endTime', value: endTime },
      { name: 'memberList', value: memberList },
      { name: 'color', value: color },
    ];

    return (
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        onFinish={onFinish}
        fields={fields}
      >
        <Form.Item label={'Заголовок'} rules={rules} name={'title'}>
          <Input
            onChange={(e) => setTitle(e.target.value)}
            placeholder={'Введите' + ' заголовок'}
          />
        </Form.Item>
        <Form.Item label={'Описание'}>
          {!isDescription && !description ? (
            <Form.Item name={'description'} noStyle>
              <TextArea
                value={description}
                rows={3}
                onMouseDown={(e) => e.stopPropagation()}
                placeholder={'Введите' + ' описание'}
                onClick={() => setIsDescription(true)}
              />
            </Form.Item>
          ) : (
            <MdEditorTb value={description} onChange={setDescription} />
          )}
        </Form.Item>
        <Form.Item label={isMobile ? undefined : 'Дата начала'} rules={rules}>
          <Form.Item
            name={'startDate'}
            rules={rules}
            label={!isMobile ? undefined : 'Дата начала'}
            style={{
              display: 'inline-block',
              width: isMobile ? 'calc(50%)' : 'calc(50% - 51px)',
              marginBottom: 0,
            }}
          >
            <DatePicker
              format={'DD.MM.YYYY'}
              style={{ width: 'calc(100% - 12px)' }}
              onChange={setStartDate}
            />
          </Form.Item>
          <Form.Item
            name={'startTime'}
            style={{
              display: 'inline-block',
              width: isMobile ? 'calc(50%)' : '59.8%',
              marginBottom: 0,
            }}
            label={'Время начала'}
            labelCol={{ span: 12 }}
          >
            <TimePicker onChange={setStartTime} />
          </Form.Item>
        </Form.Item>
        <Form.Item label={isMobile ? undefined : 'Дата окончания'}>
          <Form.Item
            style={{
              display: 'inline-block',
              width: isMobile ? 'calc(50%)' : 'calc(50% - 51px)',
              marginBottom: 0,
            }}
            name={'endDate'}
            label={!isMobile ? undefined : 'Дата окончания'}
          >
            <DatePicker
              format={'DD.MM.YYYY'}
              style={{ width: 'calc(100% - 12px)' }}
              onChange={setEndDate}
            />
          </Form.Item>
          <Form.Item
            name={'endTime'}
            style={{
              display: 'inline-block',
              width: isMobile ? 'calc(50%)' : '59.8%',
              marginBottom: 0,
            }}
            label={'Время окончания'}
            labelCol={{ span: 12 }}
          >
            <TimePicker onChange={setEndTime} />
          </Form.Item>
        </Form.Item>
        <Form.Item label={'Участники'} name={'memberList'}>
          <Select
            mode={'multiple'}
            options={options}
            onSelect={(e) =>
              eventId
                ? createAccountBondMutation({
                    eventId: eventId,
                    memberIdList: [e as number],
                  })
                : false
            }
            disabled={isLoadingDeleteAcc || isLoadingCreateAcc}
            loading={isLoadingDeleteAcc || isLoadingCreateAcc}
            onDeselect={(e) =>
              eventId ? deleteAccountBondMutation(e as number) : false
            }
            onChange={setMemberList}
          />
        </Form.Item>
        <Form.Item label={'Повтор'}>
          <Checkbox
            onChange={(e) => setIsRepeat(e.target.checked)}
            checked={isRepeat}
          />
        </Form.Item>
        {isRepeat && (
          <>
            <Form.Item label={'Повторять каждые'}>
              <Space.Compact>
                <Form.Item name={['repeatEvent', 'repeatCount']} noStyle>
                  <InputNumber
                    parser={(value) => (value ? Math.ceil(+value) : 0)}
                    min={1}
                    placeholder={'1'}
                  />
                </Form.Item>
                <Form.Item name={['repeatEvent', 'repeatType']} noStyle>
                  <Select options={repeatOption} />
                </Form.Item>
              </Space.Compact>
            </Form.Item>
            <Form.Item
              label={'Кол-во повторов'}
              name={'repeatEvent'}
              initialValue={event?.repeatEvent}
            >
              <Form.Item name={['repeatEvent', 'cycles']} noStyle>
                <InputNumber
                  min={1}
                  parser={(value) => (value ? Math.ceil(+value) : 0)}
                />
              </Form.Item>
              <Tooltip
                title={
                  'Повторы прекратятся после указанного количества' +
                  ' повторов. Оставьте пустым для бесконечного' +
                  ' количества' +
                  ' повторов.'
                }
              >
                <QuestionCircleOutlined
                  style={{ cursor: 'pointer', marginLeft: 8 }}
                />
              </Tooltip>
            </Form.Item>
          </>
        )}
        <Form.Item name={'color'} label={'Цвет события'}>
          <ColorPicker
            presets={colorPreset}
            onChange={(_, hex) => setColor(hex)}
          />
        </Form.Item>
        <Form.Item label={'Файлы'}>
          <Upload {...uploadProps} onPreview={downloadFile}>
            <Button icon={<UploadOutlined />}>Нажмите для загрузки</Button>
          </Upload>
        </Form.Item>
        <Button
          htmlType={'submit'}
          type={'primary'}
          loading={isLoading || isLoadingUpdate}
        >
          {eventId ? 'Сохранить изменения' : 'Создать событие'}
        </Button>
      </Form>
    );
  }
);
FormEvent.displayName = 'FormEvent';
export default FormEvent;
