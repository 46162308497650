import { useMutation, useQueryClient } from 'react-query';
import { CARD_URL, deleteCardById } from '../api';
import { Card } from '../type';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';
import { produce } from 'immer';
import { Board } from '../../Workspace/Board/types';
import { getCurrentColumnAndCard } from './getCurrentColumnAndCard';

export const useDeleteTemplateMutation = (
  queryKey: any[],
  queryKeyInCol: any[]
) => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();

  return useMutation(queryKey, deleteCardById, {
    onMutate: async (data) => {
      await queryClient.cancelQueries([CARD_URL]);
      queryClient.setQueryData(queryKey, (oldData: any) => {
        return oldData.filter((card: Card) => card.id !== data);
      });
      queryClient.setQueryData<Board>([BOARD_URL, boardId], (oldData) => {
        return produce(oldData!, (draft) => {
          const { currentColumn } = getCurrentColumnAndCard(draft, data);
          currentColumn.task = currentColumn.task.filter(
            (card) => card.id !== data
          );
        });
      });
      return queryClient.getQueryData(queryKey);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
      await queryClient.invalidateQueries(queryKeyInCol);
    },
  });
};
