import { Draft } from 'immer';
import { Board } from '../../Workspace/Board/types';

export const getCurrentColumnAndCard = (
  draft: Draft<Board>,
  cardId: number
) => {
  const currentCard = draft.columnList
    .flatMap((col) => col.task)
    .find((card) => card.id === cardId)!;
  const currentColumn = draft.columnList.find(
    (col) => col.id === currentCard.columnId
  )!;

  return { currentCard, currentColumn };
};
