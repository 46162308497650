import React from 'react';
import { useQueryClient } from 'react-query';
import { CARD_URL, deleteAccountToTask } from '../api';
import { Card } from '../type';
import { ACCOUNT_ON_WORKPLACE_URL } from '../../Workspace/Home/components/api';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { Board } from '../../Workspace/Board/types';
import { useParams } from 'react-router-dom';
import { useAccountToTaskCreateMutation } from '../helpers/useAccountToTaskCreateMutation';
import { useDeleteBondMutation } from '../helpers/useDeleteBondMutation';
import AddMemberView from './AddMemberView';

const AddMemberForm = React.memo(
  ({ card, cancelAdd }: { card?: Card; cancelAdd: () => void }) => {
    const { id, boardId } = useParams();
    const queryClient = useQueryClient();
    const queryKey = [CARD_URL, 'once', id!];
    const board = queryClient.getQueryData<Board>([BOARD_URL, boardId]);
    const membersMutation = useAccountToTaskCreateMutation(queryKey, [
      ACCOUNT_ON_WORKPLACE_URL,
      board?.workplaceId.toString(),
    ]);

    const membersMutationDelete = useDeleteBondMutation(
      queryKey,
      deleteAccountToTask,
      // @ts-ignore
      (oldData, newData) => {
        if (oldData) {
          return {
            ...oldData,
            accountToTask: oldData.accountToTask.filter(
              (acc) => acc.id !== newData
            ),
          };
        }
        return {};
      },
      (index) => ({
        accountToTask: card?.accountToTask.filter((acc) => acc.id !== index),
      })
    );

    return (
      <AddMemberView
        membersMutation={membersMutation}
        membersMutationDelete={membersMutationDelete}
        card={card}
        cancelAdd={cancelAdd}
      />
    );
  }
);
AddMemberForm.displayName = 'AddMemberForm';
export default AddMemberForm;
