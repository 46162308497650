import API from '../../../../utils/API/API';
import { BoardCreate, BoardUpdate } from '../../types';

export const BOARD_URL = 'api/boards';
export const BOARD_URL_FULL = 'api/boards/full';

export const createBoard = (value: BoardCreate) =>
  API.post(BOARD_URL, value).then((res) => res.data.data);

export const updateBoard = (boardId: number, value: BoardUpdate) =>
  API.put(`${BOARD_URL}/${boardId}`, value);

export const getBoardById = (id: string) =>
  API.get(`${BOARD_URL}/${id}`).then((res) => res.data.data);

export const getBoardList = (params: { accountId?: number }) =>
  API.get(BOARD_URL_FULL, { params }).then((res) => res.data.data);

export const deleteBoard = (boardId: number) =>
  API.delete(`${BOARD_URL}/${boardId}`);
