import API from '../../utils/API/API';
import { CreateComment, UpdateComment } from './type';

export const COMMENT_URL = 'api/comment-to-task';

export const createComment = (values: CreateComment) =>
  API.post(COMMENT_URL, values).then((res) => res.data.data);

export const getCommentList = (taskId: number) =>
  API.get(`${COMMENT_URL}?taskId=${taskId}`).then(
    (res) => res.data.data.content
  );

export const updateComment = (id: number, values: UpdateComment) =>
  API.put(`${COMMENT_URL}/${id}`, values).then((res) => res.data.data);

export const deleteComment = (id: number) => API.delete(`${COMMENT_URL}/${id}`);
