import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Modal,
  Popover,
  Result,
  Row,
  Skeleton,
  Space,
  Tag,
  theme,
  Typography,
  Watermark,
} from 'antd';
import Description from './Description';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { CARD_URL, getCardById } from '../api';
import { Card } from '../type';
import Menu from './Menu';
import { HomeOutlined, PlusOutlined } from '@ant-design/icons';
import AddMemberForm from './AddMemberForm';
import AddLabels from './AddLabels';
import DateForm from './DateForm';
import dayjs from 'dayjs';
import CreateComment from './CreateComment';
import CommentList from './CommentList';
import Subcard from './Subcard';
import { AxiosError } from 'axios';
import ErrorComponent from '../../../utils/Copmonents/ErrorComponent';
import { useCardUpdateOnce } from '../helpers/useCardUpdateOnce';
import FileList from './FileList';
import { isDesktop, isMobile } from 'react-device-detect';
import GitlabInfo from './GitlabInfo';
import StickerElement from '../../BoardLayout/components/StickerElement';
import { BOARD_URL, BOARD_URL_FULL } from '../../Workspace/Board/api/BoardApi';
import { useHotkeyFunction } from '../helpers/useHotkeyFunction';
import Title from './Title';
import { Board } from '../../Workspace/Board/types';
import { useGetWorkplaceId, useRole } from '../../../utils/helpers/useRole';
import { useAppSelector } from '../../../store/rootReducer';
import Checklist from './Checklist';
import AvatarCard from './AvatarCard';
import CardViewWrapper from '../style/CardViewWrapper';

const { useToken } = theme;

const getDateInfo = (end: Date | string, isCompleted = false) => {
  if (isCompleted) return { color: 'green', text: 'Выполнена' };
  const now = dayjs(dayjs(), 'YYYY-MM-DD');
  const formatEnd = dayjs(end, 'YYYY-MM-DD');
  if (now.diff(formatEnd, 'days') > 0) return { color: 'red', text: 'Истекла' };
  if (dayjs(formatEnd).diff(now, 'days') < 2)
    return { color: 'yellow', text: 'Скоро истекает' };
};

const marginLeft = isMobile ? undefined : 28;

const CardView = React.memo(() => {
  const { id, boardId } = useParams();
  const queryClient = useQueryClient();
  const [modalView, setModalView] = useState(true);
  const [isAdd, setIsAdd] = useState(false);
  const [isOpenAddMembers, setIsOpenAddMembers] = useState(false);
  const placeholderCard = queryClient
    .getQueryData<Board>([BOARD_URL, boardId])
    ?.columnList.flatMap((col) => col.task)
    .find((el) => el.id === +id!);

  const { data, error, isLoading, status } = useQuery<Card, AxiosError>(
    [CARD_URL, 'once', id],
    () => getCardById(id!),
    {
      retry: (_, error) => error?.response?.status !== 403,
      placeholderData: () => placeholderCard,
    }
  );
  const { token } = useToken();
  const navigate = useNavigate();
  const cardUpdate = useCardUpdateOnce(data?.id?.toString());
  const [isLabelOpen, setIsLabelOpen] = useState(false);
  const [isOpenDate, setIsOpenDate] = useState(false);
  const { inArchive, navigateCard } = useHotkeyFunction(data);
  const onCanselModal = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setModalView(false);
  };

  const workplaceId = useGetWorkplaceId();
  const role = useRole(workplaceId ?? 0);
  const { profile } = useAppSelector((state) => state.authReducer);

  if (status === 'error') {
    if (error?.response?.status === 403) {
      navigate('/');
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', inArchive);
    document.addEventListener('keydown', navigateCard);

    return () => {
      document.removeEventListener('keydown', inArchive);
      document.removeEventListener('keydown', navigateCard);
    };
  }, [data]);

  const dateInfo =
    data?.completeDate && getDateInfo(data.completeDate, data.isCompleted);

  return (
    <Modal
      open={modalView}
      footer={false}
      width={800}
      onCancel={onCanselModal}
      destroyOnClose={true}
      zIndex={40}
      afterClose={() => {
        navigate(
          '..',
          queryClient.getQueryData([CARD_URL, 'once', id])
            ? undefined
            : { replace: true }
        );
        queryClient.removeQueries([BOARD_URL_FULL]);
      }}
      maskClosable={false}
    >
      {status === 'error' ? (
        <Result
          status="500"
          title="500"
          subTitle="Извините, произошла непредвиденная ошибка"
          extra={
            <Button type="primary" onClick={() => setModalView(false)}>
              Назад
            </Button>
          }
        />
      ) : (
        <ErrorComponent>
          <Watermark
            content={data?.archived ? 'В архиве' : undefined}
            font={{ color: 'rgba(0,0,0,.2)' }}
          >
            <Skeleton
              loading={isLoading}
              active
              style={{ marginLeft: 28 }}
              paragraph={{ rows: 1, width: [200] }}
            >
              {(data?.backgroundUrl ||
                data?.backgroundColor ||
                !!data?.stickerToTask?.length) && (
                <CardViewWrapper
                  $backgroundImage={data?.backgroundUrl}
                  $isMobile={isMobile}
                  $backgroundColor={data.backgroundColor}
                  $tokenColorBgLayout={token.colorBgLayout}
                >
                  {data?.stickerToTask?.map((stiker) => (
                    <StickerElement
                      key={stiker.id}
                      sticker={stiker}
                      columnId={data.columnId}
                      cardView={true}
                    />
                  ))}
                </CardViewWrapper>
              )}
              {data?.isTemplate && (
                <Alert
                  style={{ marginBottom: 16 }}
                  message={
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      Шаблон карточки
                    </Typography.Title>
                  }
                  type="info"
                />
              )}
              <Row gutter={16}>
                <Col lg={18} md={24}>
                  <Title data={data} />
                  <Typography.Paragraph style={{ marginLeft }}>
                    в колонке{' '}
                    {
                      <Typography.Text underline>
                        {data?.column?.title}
                      </Typography.Text>
                    }
                  </Typography.Paragraph>
                </Col>
                <Col
                  lg={6}
                  md={24}
                  style={{
                    textAlign: 'right',
                    paddingTop:
                      (data?.stickerToTask?.length ||
                        data?.backgroundColor ||
                        data?.backgroundUrl) &&
                      isDesktop
                        ? undefined
                        : 16,
                  }}
                >
                  <Typography.Text
                    type={'secondary'}
                    style={{
                      fontSize: '0.85em',
                    }}
                  >
                    Дата создания:{' '}
                    {data
                      ? dayjs(data.creationTime).format('DD.MM.YYYY HH:mm')
                      : ''}
                  </Typography.Text>
                </Col>
              </Row>
            </Skeleton>
            <Row gutter={16}>
              <Col lg={18} style={{ marginTop: 8 }} md={24}>
                <Skeleton
                  loading={isLoading}
                  active
                  style={{ marginTop: 8, padding: '0 28px' }}
                  paragraph={{ rows: 4 }}
                >
                  <Space
                    direction={'vertical'}
                    style={{ width: '100%' }}
                    size={20}
                  >
                    {(!!data?.accountToTask?.length ||
                      !!data?.labelToTask?.length) && (
                      <div style={{ marginLeft, display: 'flex', gap: 12 }}>
                        {!!data?.accountToTask?.length && (
                          <div>
                            <Typography.Paragraph strong>
                              Участники
                            </Typography.Paragraph>
                            <Space>
                              {data?.accountToTask.map((account) => (
                                <AvatarCard
                                  account={account.account}
                                  key={account.id}
                                />
                              ))}
                              {role !== 'user' && (
                                <Popover
                                  placement={'bottom'}
                                  trigger={'click'}
                                  arrow={false}
                                  destroyTooltipOnHide
                                  open={isOpenAddMembers}
                                  onOpenChange={setIsOpenAddMembers}
                                  content={
                                    <AddMemberForm
                                      card={data!}
                                      cancelAdd={() =>
                                        setIsOpenAddMembers(false)
                                      }
                                    />
                                  }
                                >
                                  <Button
                                    shape={'circle'}
                                    icon={<PlusOutlined />}
                                  />
                                </Popover>
                              )}
                            </Space>
                          </div>
                        )}
                        {!!data?.labelToTask?.length && (
                          <div>
                            <Typography.Paragraph strong>
                              Метки
                            </Typography.Paragraph>
                            <Space wrap>
                              {data.labelToTask.map((labelContainer) => (
                                <Tag
                                  key={labelContainer.id}
                                  color={labelContainer.label.color}
                                  style={{ margin: 0 }}
                                >
                                  {labelContainer.label.title}
                                </Tag>
                              ))}
                              {role !== 'user' && (
                                <Popover
                                  placement={'bottom'}
                                  trigger={'click'}
                                  arrow={false}
                                  open={isLabelOpen}
                                  onOpenChange={setIsLabelOpen}
                                  content={
                                    <AddLabels
                                      card={data!}
                                      setIsLabelOpen={setIsLabelOpen}
                                    />
                                  }
                                >
                                  <Button icon={<PlusOutlined />} />
                                </Popover>
                              )}
                            </Space>
                          </div>
                        )}
                      </div>
                    )}
                    {data?.completeDate && (
                      <div
                        style={{
                          margin: `8px 0 8px ${isMobile ? 0 : '28px'}`,
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 4,
                        }}
                      >
                        <Typography.Text strong>Дата </Typography.Text>
                        <Checkbox
                          defaultChecked={data.isCompleted}
                          style={{ margin: '0 8px' }}
                          disabled={
                            data?.accountToTask.find(
                              (acc) => acc.account.id === profile?.id
                            )
                              ? false
                              : role === 'user'
                          }
                          onChange={(e) => {
                            cardUpdate.mutate({
                              isCompleted: e.target.checked,
                            });
                          }}
                        />
                        <Popover
                          placement={'bottom'}
                          trigger={'click'}
                          arrow={false}
                          open={role !== 'user' ? isOpenDate : false}
                          onOpenChange={setIsOpenDate}
                          content={
                            <DateForm
                              card={data!}
                              onCancel={() => setIsOpenDate(false)}
                            />
                          }
                        >
                          <Tag color={'blue'} style={{ cursor: 'pointer' }}>
                            {dayjs(data.completeDate).format('DD.MM.YYYY')}
                          </Tag>
                        </Popover>
                        {dateInfo && (
                          <Tag color={dateInfo.color}>{dateInfo.text}</Tag>
                        )}
                      </div>
                    )}
                    {!!data?.parentTaskId && (
                      <div style={{ marginLeft: isMobile ? 0 : '24px' }}>
                        <Row justify={'space-between'}>
                          <Typography.Title level={5}>
                            <HomeOutlined style={{ marginRight: 8 }} />
                            Родительская задача
                          </Typography.Title>
                        </Row>
                        <Link
                          to={`/board/${boardId}/card/${data.parentTaskId}`}
                        >
                          {data?.parentTask?.title ?? ''}
                        </Link>
                      </div>
                    )}
                    {!!(isAdd || data?.subTaskList?.length) && (
                      <Subcard card={data!} isAdd={isAdd} setIsAdd={setIsAdd} />
                    )}
                    {data?.checklist && <Checklist card={data!} />}
                    {!isLoading && (
                      <Description description={data?.description} />
                    )}
                    {!isLoading &&
                      (!!data?.gitlabMergeRequestList?.length ||
                        !!data?.gitlabCommit?.length) && (
                        <GitlabInfo card={data!} />
                      )}
                    {!!data?.fileList?.length && <FileList data={data} />}
                    {!isLoading && <CreateComment card={data!} />}
                    <CommentList />
                  </Space>{' '}
                </Skeleton>
              </Col>

              <Col lg={6} md={24}>
                <Skeleton loading={isLoading} active paragraph={{ rows: 5 }}>
                  {role !== 'user' && (
                    <Menu
                      card={data!}
                      setIsAddCard={setIsAdd}
                      setModalView={setModalView}
                    />
                  )}
                </Skeleton>
              </Col>
            </Row>
          </Watermark>
        </ErrorComponent>
      )}
    </Modal>
  );
});
CardView.displayName = 'CardView';
export default CardView;
