import axios from 'axios';
import { KeycloakApp } from '../Keycloak/Keycloak';

declare module 'axios' {
  export interface AxiosRequestConfig {
    notNotification?: boolean;
  }
}

const API = axios.create({
  baseURL: process.env.REACT_APP_PRODUCTION_URL,
});

API.interceptors.request.use(
  async function (config) {
    if (KeycloakApp?.isTokenExpired(5)) {
      await KeycloakApp.updateToken(5);
    }
    const AUTH_TOKEN = KeycloakApp.token;
    // @ts-ignore
    config.headers = {
      Authorization: `Bearer ${AUTH_TOKEN}`,
    };
    if (config.url) {
      config.url = config.url?.replaceAll('+', '%2B');
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      KeycloakApp.login();
    }

    return Promise.reject(error);
  }
);

export default API;
