import { ExplorerData } from '../type';

export const sortCallback = (a: ExplorerData, b: ExplorerData) => {
  const sortName = a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
  if (a.type === 'directory' && b.type === 'directory') {
    return sortName;
  }
  if (a.type === 'directory') {
    return -1;
  }
  if (b.type === 'file' && a.type === 'file') {
    return sortName;
  }
  if (b.type === 'file') {
    return 1;
  }
  return 0;
};
