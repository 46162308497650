import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getWorkplaceList, WORKPLACE_URL } from './api/WorkplaceApi';
import {
  Button,
  Drawer,
  GlobalToken,
  Modal,
  Row,
  Space,
  Spin,
  theme,
  Typography,
} from 'antd';
import { Workplace } from '../types';
import FormBoard from '../../Layout/components/FormBoard';
import { Link } from 'react-router-dom';
import { SettingOutlined, TeamOutlined } from '@ant-design/icons';
import MembersView from './components/MembersView';
import SettingView from './components/SettingView';
import { isDesktop, isMobile } from 'react-device-detect';
import styled from 'styled-components';

const { useToken } = theme;

const Home = React.memo(() => {
  const { isLoading, data } = useQuery<Workplace[]>([WORKPLACE_URL], () =>
    getWorkplaceList()
  );
  const [isCreateBoard, setIsCreateBoard] = useState(false);
  const [workplace, setIsWorkplace] = useState<Workplace>();
  const { token } = useToken();
  const [isMemberView, setIsMemberView] = useState(false);
  const [isSettingView, setIsSettingView] = useState(false);

  const onClickMembers = (workplace: Workplace) => {
    setIsMemberView(true);
    setIsWorkplace(workplace);
  };

  const onClickSetting = (workplace: Workplace) => {
    setIsSettingView(true);
    setIsWorkplace(workplace);
  };

  const onClickCreate = (workplace: Workplace) => {
    setIsCreateBoard(true);
    setIsWorkplace(workplace);
  };

  return (
    <Spin spinning={isLoading}>
      <div
        style={{
          margin: isDesktop ? '40px' : '20px',
        }}
      >
        <Typography.Title level={4}>ВАШИ РАБОЧИЕ ПРОСТРАНСТВА</Typography.Title>
        {data?.map((workplace) => {
          const role = workplace.accountsOnWorkplaces?.[0]?.role;
          return (
            <div
              style={{
                maxWidth: isMobile ? undefined : 'fit-content',
                marginBottom: 20,
              }}
              key={workplace.id}
            >
              <Row
                align={'middle'}
                style={{ marginBottom: 8 }}
                justify={'space-between'}
              >
                <Typography.Title level={5}>{workplace.title}</Typography.Title>
                <Space style={{ marginLeft: isMobile ? 0 : 200 }}>
                  <Button
                    icon={<TeamOutlined />}
                    onClick={() => onClickMembers(workplace)}
                  >
                    {isMobile ? '' : 'Участники'}
                  </Button>
                  {role !== 'user' && (
                    <Button
                      icon={<SettingOutlined />}
                      onClick={() => onClickSetting(workplace)}
                    >
                      {isMobile ? '' : 'Настройки'}
                    </Button>
                  )}
                </Space>
              </Row>
              <div
                className={'board-list'}
                style={{ overflowX: 'auto', overflowY: 'hidden' }}
              >
                <Space>
                  {workplace.boardList?.map((board) => (
                    <BoardWrapper
                      $token={token}
                      key={board.id}
                      to={`/board/${board.id}`}
                      style={{
                        backgroundImage: board.backgroundUrl
                          ? `url(${board.backgroundUrl})`
                          : '',
                        backgroundColor:
                          board.backgroundColor ?? token.colorBgMask,
                      }}
                    >
                      <Typography.Title
                        level={5}
                        style={{ color: 'white', padding: '8px 8px 0' }}
                      >
                        {board.title}
                      </Typography.Title>
                      <div
                        className={'description'}
                        style={{ color: token.colorWhite }}
                      >
                        {board.description ?? 'Описание отсутствует'}
                      </div>
                    </BoardWrapper>
                  ))}
                  {role === 'lead' && (
                    <div
                      className="board-card"
                      style={{
                        backgroundColor: token.colorBgContainer,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onClick={() => onClickCreate(workplace)}
                    >
                      <span>Создать доску</span>
                    </div>
                  )}
                </Space>
              </div>
            </div>
          );
        })}
      </div>
      <Drawer
        open={isMemberView}
        size={'large'}
        title={'Список участников'}
        destroyOnClose={true}
        onClose={() => setIsMemberView(false)}
      >
        <MembersView workplace={workplace} />
      </Drawer>
      <Drawer
        open={isSettingView}
        title={'Настройки'}
        destroyOnClose={true}
        onClose={() => setIsSettingView(false)}
      >
        <SettingView
          workplace={workplace!}
          onCancel={() => setIsSettingView(false)}
        />
      </Drawer>
      <Modal
        open={isCreateBoard}
        destroyOnClose={true}
        onCancel={() => setIsCreateBoard(false)}
        footer={false}
      >
        <FormBoard
          onCancel={() => setIsCreateBoard(false)}
          workplaceId={workplace?.id}
        />
      </Modal>
    </Spin>
  );
});
Home.displayName = 'Home';

const BoardWrapper = styled(Link)<{ $token: GlobalToken }>`
  width: 210px;
  height: 140px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  display: block;
  &:hover {
    & .description {
      bottom: -40px;
    }
  }
  & .description {
    position: absolute;
    width: 100%;
    font-style: italic;
    height: 100%;
    bottom: -100%;
    transition: 0.8s all;
    background-color: rgba(37, 38, 40, 0.21);
    background-image: radial-gradient(
      circle at 25%,
      hsla(0, 0%, 100%, 0.2),
      rgba(50, 50, 50, 0.2) 80%
    );
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
    padding: 8px;
    font-size: 0.8em;
  }
`;

export default Home;
