import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import { editNotification, NOTIFICATION_URL } from '../api';
import { ResponseBody } from '../../../../GlobalTypes';
import { Notification } from '../type';
import dayjs from 'dayjs';
import { loggedIn } from '../../../store/reducers/AuthReducer';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/rootReducer';

export const useNotificationMutation = (
  isView: boolean,
  formatData: Notification[] | undefined
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { profile } = useAppSelector((state) => state.authReducer);

  return useMutation([NOTIFICATION_URL], editNotification, {
    onMutate: (data) => {
      const sum = data.isView ? -data.idList.length : +data.idList.length;

      const prevValues = queryClient.getQueryData<
        InfiniteData<ResponseBody<Notification[]>>
      >([NOTIFICATION_URL, isView]);

      queryClient.setQueryData<InfiniteData<ResponseBody<Notification[]>>>(
        [NOTIFICATION_URL, isView],
        // @ts-ignore
        (oldData) => {
          // список уведомлений без разделения на страницы
          const formatContent = oldData?.pages
            .map((page) =>
              page.content.map((el, index) => ({
                ...el,
                meta: page.meta,
                index,
              }))
            )
            .flat();

          // копия всего списка страниц
          let copyPages = structuredClone(oldData!.pages);

          // мутирование всех уведомлений в списке мутации
          if (data.idList.length === 1) {
            data.idList.forEach((notificationId) => {
              const currentNotification = formatContent?.find(
                (content) => content.id === notificationId
              );

              // копия страницы на который текущее уведомление
              const copyPage = structuredClone(
                // @ts-ignore
                oldData!.pages[
                  currentNotification!.meta.currentPage - 1
                ] as ResponseBody<Notification[]>[]
              );

              if (isView) {
                // замена уведомление на мутацию
                copyPage.content[currentNotification!.index] = {
                  ...currentNotification,
                  meta: undefined,
                  index: undefined,
                  viewDate: data.isView ? dayjs().toDate() : null,
                };
              } else {
                copyPage.content = copyPage.content.filter(
                  (el: any) => el.id !== notificationId
                );
              }

              // замена страницы на новую с мутацией уведомления
              copyPages[currentNotification!.meta.currentPage - 1] = copyPage;
            });
          } else {
            copyPages = [{ content: [], meta: { currentPage: 1 } }];
          }
          return { ...oldData, pages: copyPages };
        }
      );

      if (
        data.isView &&
        !isView &&
        queryClient.getQueryData<InfiniteData<ResponseBody<Notification[]>>>([
          NOTIFICATION_URL,
          true,
        ])
      )
        queryClient.setQueryData<InfiniteData<ResponseBody<Notification[]>>>(
          [NOTIFICATION_URL, true],
          // @ts-ignore
          (oldData) => {
            // список уведомлений без разделения на страницы
            const formatContent = oldData?.pages
              .map((page) =>
                page.content.map((el, index) => ({
                  ...el,
                  meta: page.meta,
                  index,
                }))
              )
              .flat();

            // копия всего списка страниц
            const copyPages = structuredClone(oldData!.pages);

            // мутирование всех уведомлений в списке мутации
            data.idList.forEach((notificationId) => {
              const currentNotification = formatContent?.find(
                (content) => content.id === notificationId
              );

              // копия страницы на который текущее уведомление
              const copyPage = structuredClone(
                // @ts-ignore
                oldData!.pages[
                  currentNotification!.meta.currentPage - 1
                ] as ResponseBody<Notification[]>[]
              );

              // замена уведомление на мутацию
              copyPage.content[currentNotification!.index] = {
                ...currentNotification,
                meta: undefined,
                index: undefined,
                viewDate: data.isView ? dayjs().toDate() : null,
              };

              // замена страницы на новую с мутацией уведомления
              copyPages[currentNotification!.meta.currentPage - 1] = copyPage;
            });
            return { ...oldData, pages: copyPages };
          }
        );

      dispatch(
        loggedIn({
          ...profile!,
          _count: { notification: (profile!._count.notification ?? 0) + sum },
        })
      );

      return prevValues;
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData([NOTIFICATION_URL, isView], () => context);
    },
    onSettled: async (data, error, variables) => {
      if (!isView && (formatData?.length === 1 || variables.idList.length > 1))
        await queryClient.invalidateQueries([NOTIFICATION_URL, isView]);
    },
  });
};
