import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import { ProfileOutlined } from '@ant-design/icons';
import { Card } from '../type';
import { useCardUpdateOnce } from '../helpers/useCardUpdateOnce';
import { useGetWorkplaceId, useRole } from '../../../utils/helpers/useRole';

const Title = React.memo(({ data }: { data: Card | undefined }) => {
  const cardUpdate = useCardUpdateOnce(data?.id.toString());
  const [isEdit, setIsEdit] = useState(false);
  const inputRef = useRef<any>(null);
  const workplaceId = useGetWorkplaceId();
  const role = useRole(workplaceId ?? 0);

  useEffect(() => {
    if (isEdit) {
      inputRef.current?.focus({ cursor: 'end' });
    }
  }, [isEdit]);

  return isEdit ? (
    <Form
      onFinish={(values: { title: string }) => {
        cardUpdate.mutate(values);
        setIsEdit(false);
      }}
    >
      <Form.Item noStyle name={'title'} initialValue={data?.title}>
        <Input
          ref={inputRef}
          onBlur={() => setIsEdit(false)}
          autoFocus
          onKeyDown={(e) => e.stopPropagation()}
        />
      </Form.Item>
    </Form>
  ) : (
    <Typography.Title
      editable={
        role !== 'user' && {
          text: data?.title,
          onStart: () => {
            setIsEdit(true);
          },
          onChange: (title) => cardUpdate.mutate({ title }),
        }
      }
      level={4}
    >
      {!isMobile && <ProfileOutlined style={{ marginRight: 8 }} />}
      <Typography.Text style={{ fontSize: '1em' }} type={'secondary'}>
        {data?.serialNumber}
      </Typography.Text>{' '}
      {data?.title}
    </Typography.Title>
  );
});
Title.displayName = 'Title';
export default Title;
