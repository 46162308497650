import React, { useEffect, useState } from 'react';
import BoardLayout from '../BoardLayout/BoardLayout';
import { Modal, Spin } from 'antd';
import FormEvent from './components/FormEvent';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayjs from 'dayjs';
import rrulePlugin from '@fullcalendar/rrule';
import { useQuery } from 'react-query';
import { EVENT_URL, getEventList } from './api';
import { useNavigate, useParams } from 'react-router-dom';
import { DatesSetArg } from '@fullcalendar/core';
import { isMobile } from 'react-device-detect';
import { useGetWorkplaceId, useRole } from '../../utils/helpers/useRole';

const Calendar = React.memo(() => {
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [date, setDate] = useState<string>();
  const [dateSet, setDateSet] = useState<DatesSetArg>();
  const currentDate = [dateSet?.startStr, dateSet?.endStr];
  const { boardId } = useParams();
  const params = { boardId: +boardId!, date: currentDate.join() };
  const {
    data: eventList,
    refetch,
    isLoading,
  } = useQuery([EVENT_URL, { boardId }], () => getEventList(params), {
    enabled: false,
  });
  const navigate = useNavigate();
  const workplaceId = useGetWorkplaceId();
  const role = useRole(workplaceId ?? 0);

  const onCancel = () => setIsCreateOpen(false);

  const onSelectDate = (date: string) => {
    setIsCreateOpen(true);
    setDate(date);
  };

  useEffect(() => {
    if (dateSet) {
      refetch();
    }
  }, [dateSet]);

  return (
    <BoardLayout>
      <div style={{ margin: '20px auto 0', maxWidth: 1500 }}>
        <div style={{ backgroundColor: 'white', padding: 20, borderRadius: 4 }}>
          <Spin spinning={isLoading}>
            <FullCalendar
              plugins={[
                dayGridPlugin,
                interactionPlugin,
                rrulePlugin,
                timeGridPlugin,
              ]}
              initialView="dayGridMonth"
              selectable={true}
              timeZone={'local'}
              select={
                role === 'lead'
                  ? (info) => onSelectDate(dayjs(info.start).format())
                  : undefined
              }
              eventClick={(e) => navigate(`${e.event.id}`)}
              locale={'ru'}
              events={eventList ?? []}
              height={isMobile ? 'calc(100dvh - 190px)' : 'calc(100vh - 190px)'}
              allDayText={'Весь день'}
              datesSet={(dateInfo) => {
                setDateSet(dateInfo);
              }}
              buttonHints={{ prev: 'пред. $0', next: 'след. $0' }}
              buttonText={{
                today: 'сегодня',
                month: 'месяц',
                week: 'неделя',
              }}
              headerToolbar={{
                left: isMobile ? 'prev,next' : 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek',
              }}
            />
          </Spin>
        </div>
      </div>
      <Modal
        title={'Создание события'}
        open={isCreateOpen}
        onCancel={onCancel}
        footer={false}
        destroyOnClose={true}
        width={700}
      >
        <FormEvent date={date!} onCancel={onCancel} />
      </Modal>
    </BoardLayout>
  );
});
Calendar.displayName = 'Calendar';
export default Calendar;
