import { useMutation, useQueryClient } from 'react-query';
import { UpdateCard } from '../../Card/type';
import { updateCard } from '../../Card/api';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';
import { Board } from '../../Workspace/Board/types';
import { produce } from 'immer';

export const useArchiveUpdate = (id: string) => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();

  return useMutation(
    ['archive', id],
    (args: [number, UpdateCard]) => updateCard(...args),
    {
      onMutate: async (data) => {
        await queryClient.cancelQueries([BOARD_URL]);
        const prevData = queryClient.getQueryData<any>(['archive', id]);
        const card = prevData?.pages
          .map((el: { content: any }) => el.content)
          .flat()
          .find((card: { id: number }) => card.id === data[0]);
        queryClient.setQueryData<Board>(
          [BOARD_URL, boardId],
          // @ts-ignore
          (oldData) => {
            return produce(oldData!, (draft) => {
              const currentCol = draft.columnList.find(
                (col) => col.id === card.columnId
              )!;
              currentCol.task.push({ ...card, archived: false });

              currentCol.task.sort(
                (a, b) => a!.numberInColumn - b!.numberInColumn
              );
            });
          }
        );
        return prevData;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['archive', id]);
      },
      onError: async () => {
        await queryClient.invalidateQueries([BOARD_URL, boardId]);
      },
    }
  );
};
