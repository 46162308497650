import React from 'react';
import { Alert, Button } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';

const ErrorComponent = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    return (
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <Alert
            type="error"
            banner
            message={'Ошибка'}
            description={error.message}
            action={
              <Button
                size="small"
                type="primary"
                danger
                onClick={resetErrorBoundary}
              >
                {'Попробовать снова'}
              </Button>
            }
          />
        )}
      >
        {children}
      </ErrorBoundary>
    );
  }
);
ErrorComponent.displayName = 'ErrorComponent';
export default ErrorComponent;
