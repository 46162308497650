import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { CARD_URL, updateCard } from '../api';
import { Card, UpdateCard } from '../type';
import { Board } from '../../Workspace/Board/types';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { produce } from 'immer';
import { notification } from 'antd';

export const useEditSubCard = (queryKey: any[]) => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();

  return useMutation(
    queryKey,
    ([cardId, value]: [number, UpdateCard]) => updateCard(cardId, value),
    {
      onMutate: async (variables) => {
        const cardData = queryClient.getQueryData<Card>(queryKey);
        const [cardId, updateBody] = variables;
        const boardData = queryClient.getQueryData<Board>([
          BOARD_URL,
          boardId,
        ])!;
        const column = boardData.columnList.find(
          (col) => col.id === updateBody.columnId
        )!;
        const cardList = boardData.columnList.flatMap((col) => col.task);

        await queryClient.cancelQueries([CARD_URL]);
        queryClient.setQueryData<Card>(
          queryKey,
          // @ts-ignore
          (old) => {
            return produce(old!, (draft) => {
              const subTask = draft.subTaskList.find(
                (task) => task.id === cardId
              )!;
              subTask.column = { ...column };
              subTask.isCompleted = column.status === 'COMPLETE';
            });
          }
        );

        queryClient.setQueryData<Board>(
          [BOARD_URL, boardId],
          // @ts-ignore
          (old) => {
            return produce(old!, (draft) => {
              const currentSubTask = cardList.find(
                (card) => card.id === cardId
              )!;
              const oldCol = draft.columnList.find(
                (col) => col.id === currentSubTask.columnId
              )!;
              const cardIndex = oldCol.task.findIndex(
                (task) => task.id === cardId
              );

              oldCol.task.splice(cardIndex, 1);
              draft.columnList
                .find((col) => col.id === updateBody.columnId)!
                .task.push(currentSubTask);
            });
          }
        );

        return cardData;
      },
      onError: async (error, variables, context) => {
        notification.error({ message: 'Ошибка' });
        queryClient.setQueryData<Card>(queryKey, () => context!);
        await queryClient.invalidateQueries([BOARD_URL, boardId]);
      },
      onSuccess: (data: Card, variables, context) => {
        queryClient.setQueryData<Card>(queryKey, (old) => {
          return produce(old!, (draft) => {
            const currentCard = draft.subTaskList.find(
              (task) => task.id === data.id
            )!;
            Object.assign(currentCard, data);
          });
        });
        queryClient.setQueryData<Board>([BOARD_URL, boardId], (oldData) => {
          return produce(oldData!, (draft) => {
            const currentCard = draft.columnList
              .flatMap((col) => col.task)
              .find((card) => card.id === variables[0])!;
            Object.assign(currentCard, data);
          });
        });
      },
    }
  );
};
