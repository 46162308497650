import { useMutation, useQueryClient } from 'react-query';
import { CARD_URL, deleteCardById } from '../api';
import { useParams } from 'react-router-dom';
import React from 'react';

export const useDeleteCard = (
  setModalView: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { boardId, id } = useParams();
  const queryClient = useQueryClient();

  return useMutation(['archive', boardId], deleteCardById, {
    onMutate: () => {
      setModalView(false);
    },
    onSettled: async () => {
      queryClient.removeQueries([CARD_URL, 'once', id]);
      await queryClient.invalidateQueries(['archive', boardId]);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
