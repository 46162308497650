import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  setCoordinateImg,
  setCoordinates,
} from '../../../store/reducers/Sticker';
import { debounce } from 'lodash';
import { createPortal } from 'react-dom';
import { useQueryClient } from 'react-query';
import { useAppSelector } from '../../../store/rootReducer';
import { useParams } from 'react-router-dom';
import { Board } from '../../Workspace/Board/types';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { produce } from 'immer';
import { getCurrentColumnAndCard } from '../../Card/helpers/getCurrentColumnAndCard';

const ImageSticker = React.memo(
  ({
    sticker,
    isHide,
    cardId,
  }: {
    sticker: any;
    isHide: boolean;
    cardId: string | null | undefined;
  }) => {
    const imgRef = useRef<HTMLImageElement>(null);
    const { boardId } = useParams();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { topImg, leftImg } = useAppSelector((state) => state.stickerReducer);

    const onDrag = debounce(
      (
        e: React.MouseEvent<HTMLImageElement, MouseEvent>,
        rect: DOMRect | undefined
      ) => {
        if (rect) {
          const top = e.clientY - rect.y;
          const left = e.clientX - rect.x;
          dispatch(setCoordinateImg({ top, left }));
        }
      },
      500
    );

    const setStickerPosition = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      if (cardId) {
        const cardWrapperRect = document
          .getElementById(cardId!)
          ?.getBoundingClientRect();
        const queryKeyFirst = [BOARD_URL, boardId];

        const numCardId = +cardId.replaceAll('cardSticker', '');

        const top = e.clientY - cardWrapperRect!.y - topImg!;
        const left = e.clientX - cardWrapperRect!.x - leftImg!;

        dispatch(setCoordinates({ top, left }));

        queryClient.setQueryData<Board>(
          queryKeyFirst,
          // @ts-ignore
          (oldData) => {
            return produce(oldData!, (draft) => {
              const { currentCard } = getCurrentColumnAndCard(draft, numCardId);

              currentCard.stickerToTask.push({
                top,
                left,
                rotate: 0,
                imageUrl: sticker.images.fixed_height_downsampled.url,
                taskId: numCardId,
                id: 0,
              });
            });
          }
        );
      }
    };

    return !isHide ? (
      <img
        alt={''}
        src={sticker.images.fixed_height_downsampled.url}
        height={64}
        width={64}
        ref={imgRef}
        onPointerMove={(e) =>
          onDrag(e, imgRef.current?.getBoundingClientRect())
        }
      />
    ) : (
      <>
        {createPortal(
          <img
            alt={''}
            src={sticker.images.fixed_height_downsampled.url}
            height={64}
            width={64}
            onMouseEnter={(e) => {
              setStickerPosition(e);
            }}
            style={{
              position: 'fixed',
              top: imgRef.current?.getBoundingClientRect().top,
              left: imgRef.current?.getBoundingClientRect().left,
            }}
          />,
          document.body
        )}
      </>
    );
  }
);
ImageSticker.displayName = 'ImageSticker';
export default ImageSticker;
