import { useMutation, useQueryClient } from 'react-query';
import { createCard } from '../api';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';
import { Board } from '../../Workspace/Board/types';
import { produce } from 'immer';
import { Card } from '../type';

export const useCreateSimpleCardMutation = () => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();
  const queryKey = [BOARD_URL, boardId];

  return useMutation(queryKey, createCard, {
    onMutate: async (data) => {
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueryData<Board>(queryKey, (oldData: any) => {
        return produce<Board>(oldData!, (draft) => {
          const index = draft.columnList.findIndex(
            (col) => col.id === data.columnId
          )!;

          if (index > -1) {
            // @ts-ignore
            draft.columnList[index].task.push({ ...data, id: 0 });
          }
        });
      });
      return queryClient.getQueryData<Board>(queryKey);
    },
    onError: async (error, variables, context) => {
      queryClient.setQueryData<Board>(queryKey, () => context!);
    },
    onSuccess: (data: Card) => {
      queryClient.setQueryData<Board>(queryKey, (oldData) => {
        return produce<Board>(oldData!, (draft) => {
          const index = draft.columnList.findIndex(
            (col) => col.id === data.columnId
          )!;

          if (index > -1) {
            draft.columnList[index].task.splice(-1);
            draft.columnList[index].task.push(data);
          }
        });
      });
    },
  });
};
