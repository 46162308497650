import { useMutation, useQueryClient } from 'react-query';
import { COMMENT_URL, deleteComment } from '../CommentApi';
import { Card, CommentTask } from '../type';
import { CARD_URL } from '../api';
import { notification } from 'antd';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { Board } from '../../Workspace/Board/types';
import { produce } from 'immer';
import { useParams } from 'react-router-dom';

export const useDeleteCommentMutation = (queryKey: any[]) => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();
  return useMutation(queryKey, deleteComment, {
    onMutate: async (data) => {
      const commentToCard = queryClient.getQueryData<CommentTask[]>(queryKey);
      const cardData = queryClient.getQueryData<Card>([
        CARD_URL,
        'once',
        queryKey.slice(-1)[0],
      ]);

      await queryClient.cancelQueries([COMMENT_URL]);
      queryClient.setQueryData<CommentTask[]>(
        queryKey,
        // @ts-ignore
        (oldData) => {
          if (oldData) {
            return oldData.filter((comment) => comment.id !== data);
          }
          return [];
        }
      );

      queryClient.setQueryData<Board>(
        [BOARD_URL, boardId],
        // @ts-ignore
        (oldData) => {
          if (oldData) {
            return produce(oldData, (draft) => {
              const currentCard = draft.columnList
                .flatMap((col) => col.task)
                .find((card) => card.id === cardData?.id)!;

              currentCard.comments.splice(-1);
            });
          }
          return [];
        }
      );

      return commentToCard;
    },
    onError: async (error, variables, context) => {
      notification.error({ message: 'Ошибка' });
      queryClient.setQueryData<CommentTask[]>(queryKey, () => context!);
      await queryClient.invalidateQueries([BOARD_URL, boardId]);
    },
  });
};
