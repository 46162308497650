import API from './API';

export const GIPHY_TREND_URL = 'api/giphy/trend';
export const GIPHY_SEARCH_URL = 'api/giphy/search';

export const getTrendSticker = (params?: { limit?: number; offset?: number }) =>
  API.get(GIPHY_TREND_URL, params ? { params } : {}).then((res) => res.data);

export const getSearchSticker = (params?: {
  limit?: number;
  offset?: number;
  q?: string;
}) =>
  API.get(GIPHY_SEARCH_URL, params ? { params } : {}).then((res) => res.data);
