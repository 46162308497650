import React from 'react';
import { CARD_URL, deleteAccountToTask } from '../api';
import { Card } from '../type';
import { useParams } from 'react-router-dom';
import { useDeleteBondMutation } from '../helpers/useDeleteBondMutation';
import { produce } from 'immer';
import AddMemberView from './AddMemberView';
import { useAddAccountSubCard } from '../helpers/useAddAccountSubCard';
import { ACCOUNT_ON_WORKPLACE_URL } from '../../Workspace/Home/components/api';
import { useQueryClient } from 'react-query';
import { Board } from '../../Workspace/Board/types';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';

const AddMemberSubCard = React.memo(
  ({
    card,
    cancelAdd,
  }: {
    card?: Pick<Card, 'id' | 'accountToTask'>;
    cancelAdd: () => void;
  }) => {
    const { id, boardId } = useParams();
    const queryClient = useQueryClient();
    const board = queryClient.getQueryData<Board>([BOARD_URL, boardId]);
    const queryKey = [CARD_URL, 'once', id ?? ''];

    const membersMutation = useAddAccountSubCard(queryKey, [
      ACCOUNT_ON_WORKPLACE_URL,
      board?.workplaceId.toString(),
    ]);

    const membersMutationDelete = useDeleteBondMutation(
      queryKey,
      deleteAccountToTask,
      // @ts-ignore
      (oldData, newData) => {
        if (oldData) {
          return produce(oldData, (draft) => {
            const currentCard = draft.subTaskList.find(
              (task) => task.id === card!.id
            )!;
            const indexBond = currentCard.accountToTask.findIndex(
              (acc: any) => acc.id === newData
            );
            currentCard.accountToTask.splice(indexBond, 1);
          });
        }
        return {};
      },
      (index) => ({
        accountToTask: card?.accountToTask.filter((acc) => acc.id !== index),
      }),
      card!.id
    );

    return (
      <AddMemberView
        cancelAdd={cancelAdd}
        membersMutation={membersMutation}
        membersMutationDelete={membersMutationDelete}
        card={card}
      />
    );
  }
);
AddMemberSubCard.displayName = 'AddMemberSubCard';
export default AddMemberSubCard;
