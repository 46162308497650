import React from 'react';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ChangeCodeMirrorLanguage,
  codeBlockPlugin,
  codeMirrorPlugin,
  CodeToggle,
  ConditionalContents,
  CreateLink,
  headingsPlugin,
  imagePlugin,
  InsertCodeBlock,
  InsertImage,
  InsertTable,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
// import { RealmPluginInitializer } from '@mdxeditor/editor/dist/gurx';
import API from '../API/API';
import { notification } from 'antd';

const MdEditorTb = React.memo(
  ({
    value,
    onChange,
  }: {
    value: string | undefined;
    onChange: (any: any) => void;
  }) => {
    const plugins: React.ComponentProps<any>['plugins'] = [
      headingsPlugin(),
      listsPlugin(),
      quotePlugin(),
      markdownShortcutPlugin(),
      thematicBreakPlugin(),
      tablePlugin(),
      linkPlugin(),
      linkDialogPlugin(),
      markdownShortcutPlugin(),
      codeBlockPlugin({ defaultCodeBlockLanguage: 'js' }),
      codeMirrorPlugin({
        codeBlockLanguages: {
          ts: 'TypeScript',
          js: 'JavaScript',
          jsx: 'React',
          java: 'Java',
          sql: 'SQL',
          json: 'JSON',
        },
      }),
      toolbarPlugin({
        toolbarContents: () => (
          <>
            <ConditionalContents
              options={[
                {
                  when: (editor) => editor?.editorType === 'codeblock',
                  contents: () => <ChangeCodeMirrorLanguage />,
                },
                {
                  fallback: () => (
                    <>
                      <BlockTypeSelect />
                      <BoldItalicUnderlineToggles />
                      <CreateLink />
                      <CodeToggle />
                      <InsertCodeBlock /> <ListsToggle />
                      <InsertImage />
                      <InsertTable />
                    </>
                  ),
                },
              ]}
            />
          </>
        ),
      }),
      imagePlugin({
        imageUploadHandler: (image: File) => {
          const formData = new FormData();
          formData.append('file', image);
          if (!image.type.includes('image')) {
            notification.error({
              message: 'Файл должен быть изображением',
            });
            return new Promise((resolve, reject) => {
              reject(new Error('error'));
            });
          }
          if (image.size >= 1024 * 1024) {
            notification.error({
              message: 'Размер файла не может превышать 1MB',
            });

            return new Promise((resolve, reject) => {
              reject(new Error('error'));
            });
          }
          return API.post('api/filesystem', formData).then(
            (res) =>
              `${
                process.env.REACT_APP_PRODUCTION_URL
              }${res.data.data.folder.replace('files', '')}/${
                res.data.data.fileName
              }`
          );
        },
      }),
    ];

    return (
      <div onKeyDown={(e) => e.stopPropagation()}>
        <MDXEditor
          markdown={value ? value.replaceAll('<!---->', '') : ''}
          onChange={onChange}
          plugins={plugins}
          autoFocus={{ defaultSelection: 'rootEnd' }}
        />
      </div>
    );
  }
);

MdEditorTb.displayName = 'MdEditorTb';

export default MdEditorTb;
