import { ExplorerData } from '../type';

export const findObjectByRelativePath = (
  obj: ExplorerData,
  relativePath: string
): ExplorerData | null => {
  if (obj.relativePath === relativePath) {
    return obj;
  }

  if (obj.children) {
    for (let i = 0; i < obj.children.length; i++) {
      const child = obj.children[i];
      const foundObject = findObjectByRelativePath(child, relativePath);
      if (foundObject) {
        return foundObject;
      }
    }
  }

  return null;
};
