import API from '../../../utils/API/API';
import { saveFile } from '../../../utils/helpers/saveFile';

export const downloadFile = async (file: {
  name: string;
  uid: string | number;
}) => {
  const fileData = await API.get(`api/filesystem/${file.uid}`, {
    responseType: 'blob',
  }).then((res) => {
    return res.data;
  });
  saveFile(fileData, file.name);
};
