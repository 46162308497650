import React, { useState } from 'react';
import { Button, ColorPicker, Form, Input, Typography } from 'antd';
import styled from 'styled-components';
import { useGenericMutation } from '../../../utils/hooks/useGenericMutate';
import {
  CARD_URL,
  createLabel,
  deleteLabel,
  LABEL_URL,
  updateLabel,
} from '../api';
import { Label, LabelCreate } from '../type';
import { colorPreset } from '../../../utils/helpers/colorPreset';
import { useQueryClient } from 'react-query';

const CreateLabel = React.memo(
  ({ onCancel, label }: { onCancel?: Function; label?: Label }) => {
    const [color, setColor] = useState(label?.color ?? '#E3E8ED');
    const boardId = +location.pathname.split('/')[2];
    const [title, setTitle] = useState<string | undefined>(label?.title);
    const mutateLabel = useGenericMutation<Label[], any>(
      createLabel,
      [LABEL_URL, boardId.toString()],
      (oldData, newData) => [...oldData, newData]
    );
    const queryClient = useQueryClient();
    const { mutateAsync: updateLabelMutate } = useGenericMutation<Label[], any>(
      (values) => updateLabel(label!.id, values),
      [LABEL_URL, boardId.toString()],
      (oldData, newData) => {
        const copyOldData = Array.from(oldData);
        const idxEdit = copyOldData.findIndex(
          (labelItem) => labelItem.id === label?.id
        );
        copyOldData[idxEdit] = { ...copyOldData[idxEdit], ...newData };
        return copyOldData;
      }
    );

    const { mutateAsync: deleteLabelMutation } = useGenericMutation<
      Label[],
      any
    >(deleteLabel, [LABEL_URL, boardId.toString()], (oldData, newData) =>
      oldData.filter((label) => label.id !== newData)
    );

    const onFinishForm = (values: Omit<LabelCreate, 'boardId'>) => {
      if (label) {
        updateLabelMutate(values).then(async () => {
          await queryClient.invalidateQueries([CARD_URL, 'list']);
        });
      } else {
        mutateLabel
          .mutateAsync({ ...values, boardId })
          .then(() => onCancel?.());
      }
    };
    const fields = [
      { name: 'color', value: color },
      { name: 'title', value: title },
    ];
    return (
      <Form onFinish={onFinishForm} fields={fields}>
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          {label ? label.title : 'Создание метки'}
        </Typography.Title>
        <Form.Item>
          <LabelPreview $bgColor={color}>{title}</LabelPreview>
        </Form.Item>
        <Form.Item name={'title'} rules={[{ required: true, message: '' }]}>
          <Input
            onKeyDown={(e) => e.stopPropagation()}
            placeholder={'Введите название метки'}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Item>
        <Form.Item name={'color'}>
          <ColorPicker
            presets={colorPreset}
            onChange={(_, hex) => setColor(hex)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            style={{ width: '100%' }}
            htmlType={'submit'}
            onClick={(e) => e.stopPropagation()}
          >
            Сохранить
          </Button>
        </Form.Item>
        {label && (
          <Form.Item>
            <Button
              style={{ width: '100%' }}
              danger
              onClick={(e) => {
                e.stopPropagation();
                deleteLabelMutation(label.id).then(async () => {
                  await queryClient.invalidateQueries([CARD_URL, 'list']);
                });
              }}
            >
              Удалить метку
            </Button>
          </Form.Item>
        )}
      </Form>
    );
  }
);
CreateLabel.displayName = 'CreateLabel';
export default CreateLabel;

const LabelPreview = styled.div<{ $bgColor: string }>`
  background-color: ${(props) => props.$bgColor};
  border-radius: 4px;
  padding: 2px 8px;
  width: calc(100% - 16px);
  height: 20px;
  color: white;
`;
