import React, { useState } from 'react';
import { Button, Popover, Select, Space, Tag, Typography } from 'antd';
import { Card, Label } from '../type';
import { useQuery, useQueryClient } from 'react-query';
import {
  CARD_URL,
  deleteLabelToTask,
  getLabelList,
  LABEL_TO_TASK_URL,
  LABEL_URL,
} from '../api';
import { PopoverProps } from 'antd/es/popover';
import CreateLabel from './CreateLabel';
import { useLabelToTaskCreateMutation } from '../helpers/useLabelToTaskCreateMutation';
import { useParams } from 'react-router-dom';
import { useDeleteBondMutation } from '../helpers/useDeleteBondMutation';
import { useGetWorkplaceId, useRole } from '../../../utils/helpers/useRole';

const AddLabels = React.memo(
  ({ card, setIsLabelOpen }: { card?: Card; setIsLabelOpen: Function }) => {
    const boardId = +location.pathname.split('/')[2];
    const [isOpenCreate, setIsOpenCreate] = useState(false);
    const { data } = useQuery<Label[]>([LABEL_URL, boardId.toString()], () =>
      getLabelList(boardId)
    );
    const queryClient = useQueryClient();
    const { id } = useParams();
    const queryKey = [CARD_URL, 'once', id!];
    const state = queryClient.getQueryState([
      LABEL_TO_TASK_URL,
      card?.id.toString(),
    ]);
    const workplaceId = useGetWorkplaceId();
    const role = useRole(workplaceId ?? 0);

    const labelMutate = useLabelToTaskCreateMutation(queryKey, [
      LABEL_URL,
      boardId.toString(),
    ]);

    const labelDeleteMutation = useDeleteBondMutation(
      queryKey,
      deleteLabelToTask,
      // @ts-ignore
      (oldData, newData) => {
        if (oldData) {
          return {
            ...oldData,
            labelToTask: oldData.labelToTask.filter(
              (lab) => lab.id !== newData
            ),
          };
        }
        return {};
      },
      (index) => ({
        labelToTask: card?.labelToTask.filter((lab) => lab.id !== index),
      })
    );

    const onDeleteLabel = (id: number) => {
      const currentLabel = card?.labelToTask.find(
        (labelContainer) => labelContainer.labelId === id
      );
      currentLabel && labelDeleteMutation.mutate(currentLabel.id);
    };

    const popoverProps: Partial<PopoverProps> = {
      placement: 'top',
      trigger: 'click',
      arrow: false,
    };
    const options = data?.map((label) => ({
      value: label.id,
      label: (
        <Tag key={label.id} color={label.color}>
          {label.title}
        </Tag>
      ),
    }));
    return (
      <Space direction={'vertical'} style={{ width: 250 }}>
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          Метки
        </Typography.Title>
        <Select
          showSearch={true}
          onKeyDown={(e) => e.stopPropagation()}
          mode={'multiple'}
          defaultValue={card?.labelToTask.map(
            (labelContainer) => labelContainer.labelId
          )}
          tagRender={(props: any) => {
            return (
              <Tag color={props.label?.props?.color}>
                {props.label?.props?.children}
              </Tag>
            );
          }}
          loading={state?.isFetching}
          disabled={state?.isFetching}
          options={options}
          placeholder={'Искать метки'}
          style={{ width: '100%' }}
          onSelect={(id) =>
            labelMutate.mutate({ taskId: card!.id, labelId: id })
          }
          onDeselect={onDeleteLabel}
        />
        {role === 'lead' && (
          <Popover
            {...popoverProps}
            open={isOpenCreate}
            content={
              <CreateLabel
                onCancel={() => {
                  setIsOpenCreate(false);
                  setIsLabelOpen(true);
                }}
              />
            }
            onOpenChange={(visible) => {
              setIsOpenCreate(visible);
              setIsLabelOpen(false);
            }}
          >
            <Button style={{ width: '100%' }}>Создать метку</Button>
          </Popover>
        )}
      </Space>
    );
  }
);
AddLabels.displayName = 'AddLabels';
export default AddLabels;
