import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { ResponseBody } from '../../../../GlobalTypes';
import { deleteCardById, getCardListNoCol } from '../../Card/api';
import { Card } from '../../Card/type';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Form, Input, Space, Spin } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { DeleteOutlined, RollbackOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { useArchiveUpdate } from '../helpers/useArchiveUpdate';
import { isMobile } from 'react-device-detect';
import SimpleCard from '../../Card/components/SimpleCard';
import { useGetWorkplaceId, useRole } from '../../../utils/helpers/useRole';

const Archive = React.memo(() => {
  const { boardId: id } = useParams();
  const [params, setParams] = useState<{
    archived: boolean;
    size: number;
    title?: string;
    boardId: string;
    sort?: string;
  }>({ archived: true, size: 30, boardId: id!, sort: 'updateTime,desc' });
  const { data, isFetching, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery<ResponseBody<Card[]>>(
      ['archive', id],
      ({ pageParam = 1 }) => getCardListNoCol({ page: pageParam, ...params }),
      {
        getNextPageParam: (lastPage) =>
          lastPage.meta.currentPage < lastPage.meta.totalPages
            ? lastPage.meta.currentPage + 1
            : undefined,
      }
    );
  const queryClient = useQueryClient();

  const { mutate } = useArchiveUpdate(id!);

  const workplaceId = useGetWorkplaceId();
  const role = useRole(workplaceId ?? 0);

  const { mutate: deleteMutation, isLoading } = useMutation(
    ['archive', id],
    deleteCardById,
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(['archive', id]);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [params]);

  const searchHandler = debounce((value: string) => {
    setParams({ archived: true, size: 30, title: value ?? '', boardId: id! });
  }, 300);

  return (
    <>
      <Spin spinning={isFetching} />
      <Form>
        <Form.Item name={'title'}>
          <Input
            onChange={(e) => searchHandler(e.target.value)}
            placeholder={'Введите наименование карточки'}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </Form.Item>
      </Form>
      <InfiniteScroll
        next={fetchNextPage}
        hasMore={!!hasNextPage}
        className={'infinite-scroll'}
        loader={<Spin />}
        height={isMobile ? 'calc(100dvh - 300px)' : 'calc(100vh - 300px)'}
        dataLength={data?.pages.map((v) => v.content).flat().length ?? 30}
      >
        <Space style={{ width: '100%' }} direction={'vertical'}>
          {data?.pages
            .map((v) => v.content)
            .flat()
            .map((card) => (
              <div key={card.id}>
                <Link
                  to={`card/${card?.id}`}
                  style={{ color: 'inherit', display: 'block' }}
                >
                  <SimpleCard card={card} />
                </Link>
                <Space style={{ marginTop: 8 }}>
                  <Button
                    size={'small'}
                    type={'text'}
                    loading={isLoading}
                    disabled={isFetching}
                    onClick={() => mutate([card.id, { archived: false }])}
                  >
                    <span style={{ fontSize: '0.8em' }}>
                      <RollbackOutlined /> Вернуть на стол
                    </span>
                  </Button>
                  {role !== 'user' && (
                    <Button
                      size={'small'}
                      type={'text'}
                      danger
                      loading={isLoading}
                      disabled={isFetching}
                      onClick={() => deleteMutation(card.id)}
                    >
                      <span style={{ fontSize: '0.8em' }}>
                        <DeleteOutlined /> Удалить
                      </span>
                    </Button>
                  )}
                </Space>
              </div>
            ))}
        </Space>
      </InfiniteScroll>
    </>
  );
});
Archive.displayName = 'Archive';
export default Archive;
