import { useAppSelector } from '../../../store/rootReducer';
import { useMutation, useQueryClient } from 'react-query';
import { WORKPLACE_URL } from '../../Workspace/Home/api/WorkplaceApi';
import { Workplace } from '../../Workspace/types';
import { notification } from 'antd';
import { BOARD_URL, deleteBoard } from '../../Workspace/Board/api/BoardApi';
import { Board } from '../../Workspace/Board/types';

export const useDeleteBoardMutation = (boardId: string) => {
  const queryClient = useQueryClient();
  const { profile } = useAppSelector((state) => state.authReducer);
  return useMutation([BOARD_URL, boardId], deleteBoard, {
    onMutate: async () => {
      await queryClient.cancelQueries([BOARD_URL]);
      const prevValues = queryClient.getQueryData([
        WORKPLACE_URL,
        { profileId: profile?.id },
      ]);
      const boardData = queryClient.getQueryData<Board>([BOARD_URL, boardId]);

      queryClient.setQueryData<Workplace[]>(
        [WORKPLACE_URL, { profileId: profile?.id }],
        // @ts-ignore
        (oldData) => {
          const currentWorkplace = oldData?.findIndex(
            (workplace) => workplace.id === boardData?.workplaceId
          );

          const copyData = Array.from(oldData ?? []);
          if (currentWorkplace !== undefined) {
            copyData[currentWorkplace] = {
              ...copyData[currentWorkplace],
              boardList: copyData[currentWorkplace].boardList.filter(
                (board) => board.id !== +boardId
              ),
            };
          }
          return copyData;
        }
      );

      return prevValues;
    },
    onError: (error, variables, context) => {
      notification.error({
        message: 'Ошибка при удалении доски',
      });
      queryClient.setQueryData<Workplace>(
        [WORKPLACE_URL, { profileId: profile?.id }],
        // @ts-ignore
        () => context
      );
    },
  });
};
