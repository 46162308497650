import dayjs from 'dayjs';
import { Card } from '../../Card/type';
import { FilterList } from '../../BoardLayout/BoardLayout';

const filterByKeyWord = (keyWord: string, card: Card) => {
  const cardText =
    card.labelToTask
      .map((label) => label.label.title)
      .join('')
      .toLowerCase() +
    card.title.toLowerCase() +
    card.serialNumber +
    card.accountToTask
      .map((account) => account.account.personFullName.toLowerCase())
      .join('');
  return cardText.includes(keyWord.toLowerCase().trim());
};

export const getDataColumn = (cardList: Card[], filterList: FilterList) => {
  return cardList.filter((card) => {
    const accountList = card.accountToTask?.map((acc) => acc.accountId) ?? [];
    const result = [];
    const labelToTask = card.labelToTask?.map((label) => label.labelId) ?? [];

    const dateDiff = card.completeDate
      ? dayjs(card.completeDate, 'YYYY-MM-DD').diff(
          dayjs(dayjs(), 'YYYY-MM-DD'),
          'days'
        )
      : undefined;
    // eslint-disable-next-line guard-for-in
    for (const filterListKey in filterList) {
      if (
        filterListKey === 'accountId' &&
        accountList.includes(filterList!.accountId!)
      ) {
        result.push(true);
      }
      if (filterListKey === 'noAccount' && !accountList.length) {
        result.push(true);
      }
      if (
        filterListKey === 'twoDay' &&
        dateDiff !== undefined &&
        dateDiff <= 2 &&
        dateDiff >= 0 &&
        !card.isCompleted
      ) {
        result.push(true);
      }
      if (filterListKey === 'overdue' && dateDiff && dateDiff < 0) {
        result.push(true);
      }
      if (filterListKey === 'noDate' && !card.completeDate) {
        result.push(true);
      }
      if (filterListKey === 'completed' && card.isCompleted) {
        result.push(true);
      }
      if (
        filterListKey === 'label' &&
        filterList[filterListKey]?.some((r) => labelToTask.includes(r))
      ) {
        result.push(true);
      }
      if (
        filterListKey === 'keyWord' &&
        filterByKeyWord(filterList[filterListKey]!, card)
      ) {
        result.push(true);
      }
    }

    return result.length || !Object.keys(filterList).length;
  });
};
