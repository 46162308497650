import React from 'react';
import { UpdateWorkplace, Workplace } from '../../types';
import { Button, Form, Input, Modal, Space, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useQuery } from 'react-query';
import { getWorkplace, WORKPLACE_URL } from '../api/WorkplaceApi';
import { useWorkplaceMutation } from '../helpers/useWorkplaceMutation';
import { useDeleteWorkplaceMutation } from '../helpers/useDeleteWorkspace';
import { useRole } from '../../../../utils/helpers/useRole';

const SettingView = React.memo(
  ({ workplace, onCancel }: { workplace: Workplace; onCancel: Function }) => {
    const { data, isLoading } = useQuery<Workplace>(
      [WORKPLACE_URL, workplace.id.toString()],
      () => getWorkplace(workplace.id)
    );
    const { mutateAsync } = useWorkplaceMutation(workplace?.id.toString());
    const { mutate: deleteMutation } = useDeleteWorkplaceMutation(
      workplace.id.toString()
    );
    const onFinish = (values: UpdateWorkplace) => {
      mutateAsync([workplace.id, values]).then(() => onCancel());
    };

    const [modal, contextHolder] = Modal.useModal();
    const role = useRole(workplace.id ?? 0);

    const onDelete = () => {
      modal.confirm({
        title: 'Вы точно хотите удалить рабочее пространство?',
        content:
          'При удалении рабочего пространства будут удалены все его элементы, включая доски, колонки и задачи, которые были привязаны к нему.',
        onOk: () => deleteMutation(workplace.id),
      });
    };

    return (
      <Spin spinning={isLoading}>
        <Form layout={'vertical'} onFinish={onFinish}>
          <Form.Item
            name={'title'}
            initialValue={data?.title ?? workplace.title}
            label={'Заголовок'}
          >
            <Input
              bordered={false}
              placeholder={'Введите заголовок рабочего пространства'}
            />
          </Form.Item>
          <Form.Item
            name={'description'}
            initialValue={data?.description ?? workplace.description}
            label={'Описание'}
          >
            <TextArea
              bordered={false}
              placeholder={'Введите описание рабочего пространства'}
              rows={4}
            />
          </Form.Item>
          <Space>
            <Button type={'primary'} htmlType={'submit'}>
              Сохранить
            </Button>
            {role === 'lead' && (
              <Button danger onClick={onDelete}>
                Удалить рабочее пространство
              </Button>
            )}
          </Space>
        </Form>
        {contextHolder}
      </Spin>
    );
  }
);
SettingView.displayName = 'SettingView';
export default SettingView;
