import React, { useState } from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { Image } from 'antd';
import { Link } from 'react-router-dom';

const MdViewerTb = React.memo((viewerProps: { source: string | undefined }) => {
  const [srcImg, setSrcImg] = useState<string>();
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target instanceof HTMLImageElement) {
      setSrcImg(e.target.src);
    }
  };

  const checkLink = (href: string | undefined) =>
    href?.includes(`${process.env.REACT_APP_URL}`);

  return (
    <div onClick={onClick}>
      <MarkdownPreview
        source={viewerProps.source}
        style={{ whiteSpace: 'inherit', overflow: 'auto' }}
        wrapperElement={{ 'data-color-mode': 'light' }}
        components={{
          a: ({ href, children }) =>
            checkLink(href) ? (
              <Link to={href!}>{children}</Link>
            ) : (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            ),
        }}
      />
      {srcImg && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: !!srcImg,
            onVisibleChange: (visible) => !visible && setSrcImg(''),
          }}
          src={srcImg}
        />
      )}
    </div>
  );
});

MdViewerTb.displayName = 'MdViewerTb';

export default MdViewerTb;
