import React from 'react';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';

const DropdownMenu: React.FC<{
  open: boolean;
  pos: { x: number; y: number };
  items: MenuProps['items'];
}> = ({ open, pos, items }) => (
  <Dropdown
    menu={{ items }}
    open={open}
    overlayStyle={{ left: `${pos.x}px`, top: `${pos.y}px` }}
  >
    <></>
  </Dropdown>
);

export default DropdownMenu;
