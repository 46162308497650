import API from '../../utils/API/API';
import {
  AccountToTaskCreate,
  Card,
  CreateCard,
  CreateChecklistItem,
  LabelCreate,
  LabelToTaskCreate,
  UpdateCard,
  UpdateChecklistItem,
} from './type';

export const CARD_URL = 'api/tasks';
export const MASS_ARCHIVE_TASK = 'api/tasks/mass-archive';
export const ACCOUNT_TO_TASK_URL = 'api/account-to-task';
export const LABEL_URL = 'api/labels';
export const LABEL_TO_TASK_URL = 'api/label-to-task';
export const CHECKLIST_URL = 'api/checklists';
export const CHECKLIST_ITEMS_URL = 'api/checklist-items';

export const createCard = (value: CreateCard) =>
  API.post(CARD_URL, value).then((res) => res.data.data);

export const updateCard = (id: number, value: UpdateCard) =>
  API.put(`${CARD_URL}/${id}`, value).then((res) => res.data.data);

export const massArchiveCard = (columnId: number) =>
  API.patch(MASS_ARCHIVE_TASK, { columnId }).then((res) => res.data.data);

export const getCardById = (id?: string): Promise<Card> =>
  API.get(`${CARD_URL}/${id}`).then((res) => res.data.data);

export const deleteCardById = (id: number): Promise<Card> =>
  API.delete(`${CARD_URL}/${id}`).then((res) => res.data.data);

export const getCardList = (columnId: number) =>
  API.get(`${CARD_URL}?columnId=${columnId}&size=999&archived=false`).then(
    (res) => res.data.data.content
  );

export const getCardListNoCol = (
  params: {
    page?: number;
    size?: number;
    archived?: boolean;
    boardId?: string;
    accountId?: number;
    notComplete?: boolean;
    isTemplate?: boolean;
  },
  contentOnly?: boolean
) =>
  API.get(CARD_URL, { params }).then((res) => {
    return contentOnly ? res.data.data.content : res.data.data;
  });

export const createAccountToTask = (value: AccountToTaskCreate) =>
  API.post(ACCOUNT_TO_TASK_URL, value).then((res) => res.data.data);

export const deleteAccountToTask = (id: number) =>
  API.delete(`${ACCOUNT_TO_TASK_URL}/${id}`);

export const getLabelList = (boardId: number) =>
  API.get(`${LABEL_URL}?boardId=${boardId}&size=999`).then(
    (res) => res.data.data.content ?? res.data.data
  );

export const createLabel = (values: LabelCreate) => API.post(LABEL_URL, values);

export const updateLabel = (labelId: number, values: LabelCreate) =>
  API.put(`${LABEL_URL}/${labelId}`, values);

export const deleteLabel = (labelId: number) =>
  API.delete(`${LABEL_URL}/${labelId}`);

export const getLabelToTask = (taskId: number) =>
  API.get(`${LABEL_TO_TASK_URL}?taskId=${taskId}`).then((res) => res.data.data);

export const addLabelToTask = (values: LabelToTaskCreate) =>
  API.post(LABEL_TO_TASK_URL, values).then((res) => res.data.data);

export const deleteLabelToTask = (id: number) =>
  API.delete(`${LABEL_TO_TASK_URL}/${id}`);

export const createCheckList = (taskId: number) =>
  API.post(CHECKLIST_URL, { taskId, checklistItems: [] }).then(
    (res) => res.data.data
  );

export const getChecklistItemsList = (checklistId: number) =>
  API.get(`${CHECKLIST_ITEMS_URL}?checklistId=${checklistId}`).then(
    (res) => res.data.data
  );

export const createChecklistItem = (values: CreateChecklistItem) =>
  API.post(CHECKLIST_ITEMS_URL, values).then((res) => res.data.data);

export const updateChecklistItem = (
  checklistId: number,
  values: UpdateChecklistItem
) =>
  API.put(`${CHECKLIST_ITEMS_URL}/${checklistId}`, values).then(
    (res) => res.data.data
  );

export const deleteChecklistItem = (checklistItemId: number) =>
  API.delete(`${CHECKLIST_ITEMS_URL}/${checklistItemId}`);

export const deleteChecklist = (checklistId: number) =>
  API.delete(`${CHECKLIST_URL}/${checklistId}`);
