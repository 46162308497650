import React, { createContext, useState } from 'react';
import {
  Badge,
  Button,
  Drawer,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import {
  AppstoreOutlined,
  CalendarOutlined,
  FileOutlined,
  FilterOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  SlidersOutlined,
} from '@ant-design/icons';
import { Header as HeaderA } from 'antd/es/layout/layout';
import { useQuery, useQueryClient } from 'react-query';
import { Board as BoardType } from '../Workspace/Board/types';
import { BOARD_URL, getBoardById } from '../Workspace/Board/api/BoardApi';
import styled from 'styled-components';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUpdateBoard } from './helpers/useUpdateBoard';
import MenuBoard from './components/MenuBoard';
import FiltersBoard from './components/FiltersBoard';
import { AxiosError } from 'axios';
import ErrorComponent from '../../utils/Copmonents/ErrorComponent';
import Menu from './components/Menu';
import { isDesktop, isMobile } from 'react-device-detect';
import { DragDropContext } from 'react-beautiful-dnd';
import { useOnDragEnd } from './helpers/useOnDragEnd';

export type FilterList = {
  accountId?: number;
  noAccount?: boolean;
  overdue?: boolean;
  noDate?: boolean;
  twoDay?: boolean;
  label?: number[];
  completed?: boolean;
  keyWord?: string;
};

export const FilterContext = createContext<{
  filterList: FilterList;
  setFilterList: React.Dispatch<React.SetStateAction<FilterList>> | null;
}>({
  filterList: {},
  setFilterList: null,
});

const BoardLayout = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    // eslint-disable-next-line no-unused-vars
    const [_, pathname, id] = location.pathname.split('/');
    const [isOpenSetting, setIsOpenSetting] = useState(false);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {
      data: boardData,
      isLoading,
      status,
      error,
    } = useQuery<BoardType, AxiosError>(
      [BOARD_URL, id.toString()],
      () => getBoardById(id.toString()),
      {
        retry: (_, error) => error?.response?.status !== 403,
        refetchOnWindowFocus: () => !location.pathname.includes('files'),
      }
    );
    const boardList =
      boardData?.workplace?.boardList ??
      queryClient.getQueriesData<BoardType>([BOARD_URL])?.[0][1]?.workplace
        .boardList;

    if (status === 'error') {
      if (error?.response?.status === 403) {
        navigate('/');
      }
    }

    const onDragEnd = useOnDragEnd();

    const [view, setView] = useState(pathname);
    const { mutate: mutateUpdateBoard } = useUpdateBoard();
    const [filterList, setFilterList] = useState<FilterList>({});

    const onChangeView = (value: string) => {
      setView(value);
      navigate(`/${value}/${id}`);
    };

    const options = [
      {
        value: 'board',
        label: (
          <>
            <AppstoreOutlined style={{ marginRight: 8 }} />{' '}
            {isMobile ? '' : 'Доска'}
          </>
        ),
      },
      {
        value: 'gantt',
        label: (
          <>
            <SlidersOutlined style={{ marginRight: 8 }} />
            {isMobile ? '' : 'Диаграмма'}
          </>
        ),
      },
      {
        value: 'calendar',
        label: (
          <>
            <CalendarOutlined style={{ marginRight: 8 }} />
            {isMobile ? '' : 'Календарь'}
          </>
        ),
      },
      {
        value: 'files',
        label: (
          <>
            <FileOutlined style={{ marginRight: 8 }} />
            {isMobile ? '' : 'Файлы'}
          </>
        ),
      },
    ];

    const SettingButtonList = (
      <Space
        style={{
          width: isDesktop ? 220 : undefined,
        }}
      >
        <Select
          value={view}
          onChange={onChangeView}
          style={{ width: 150 }}
          options={options}
        />
        {view === 'board' && (
          <Tooltip title={'Фильтры'}>
            <Badge count={Object.keys(filterList).length} size={'small'}>
              <Button
                style={{ color: 'white' }}
                type={'text'}
                icon={<FilterOutlined />}
                onClick={() => setIsOpenFilters(true)}
              />
            </Badge>
          </Tooltip>
        )}
        <Button
          style={{ color: 'white' }}
          type={'text'}
          icon={<SettingOutlined />}
          onClick={() => setIsOpenSetting(true)}
        />
      </Space>
    );
    const tabs =
      boardList?.map((board) => {
        return {
          label: board.title,
          key: board.id.toString(),
          children: null,
        };
      }) ?? [];
    return (
      <FilterContext.Provider value={{ filterList, setFilterList }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Spin spinning={isLoading}>
            <div
              className={'board-tabs'}
              style={{
                margin: 'auto',
                height: isMobile ? 'calc(100dvh - 48px)' : 'calc(100vh - 48px)',
                backgroundImage: boardData?.backgroundUrl
                  ? `url(${boardData.backgroundUrl})`
                  : '',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                overflowY: 'hidden',
                backgroundColor: boardData?.backgroundColor ?? '',
              }}
            >
              {isDesktop ? (
                <TabContainer>
                  <Tabs
                    onChange={(key) => navigate(`/board/${key}`)}
                    activeKey={id}
                    type="card"
                    style={{ border: 'none', width: 'calc(100% - 228px)' }}
                    tabBarStyle={{
                      margin: 0,
                      height: 40,
                      color: 'white',
                      border: 'none',
                    }}
                    items={tabs}
                  />
                  {SettingButtonList}
                </TabContainer>
              ) : (
                <Header>
                  <Row gutter={20} align={'middle'} justify={'space-between'}>
                    <Space>
                      {isMobile && (
                        <Button
                          type={'text'}
                          style={{ color: 'white' }}
                          icon={<MenuUnfoldOutlined />}
                          onClick={() => setIsOpenMenu(true)}
                        />
                      )}
                      <Typography.Title
                        level={4}
                        style={{ color: 'white', marginBottom: 0 }}
                        editable={{
                          onChange: (title) => mutateUpdateBoard({ title }),
                          icon: <></>,
                          triggerType: ['text'],
                        }}
                      >
                        {boardData?.title}
                      </Typography.Title>
                    </Space>
                    {SettingButtonList}
                  </Row>
                </Header>
              )}
              <ErrorComponent>{children}</ErrorComponent>
              <Outlet />
            </div>
            <MenuBoard
              isOpenSetting={isOpenSetting}
              setIsOpenSetting={setIsOpenSetting}
            />
            {view === 'board' && (
              <FiltersBoard
                isOpen={isOpenFilters}
                setIsOpen={setIsOpenFilters}
              />
            )}
            <Drawer
              open={isOpenMenu}
              onClose={() => setIsOpenMenu(false)}
              getContainer={false}
              placement={'left'}
              title={'Меню'}
            >
              <Menu boardData={boardData} />
            </Drawer>
          </Spin>
        </DragDropContext>
      </FilterContext.Provider>
    );
  }
);
const Header = styled(HeaderA)`
  background-color: rgba(0, 21, 41, 0.32);
  backdrop-filter: blur(14px);
  height: ${isMobile ? 'auto' : '40px'};
  line-height: 40px;
`;

const TabContainer = styled.div`
  background-color: rgba(0, 21, 41, 0.32);
  backdrop-filter: blur(14px);
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 0;
  border-bottom: 1px solid white;
`;

BoardLayout.displayName = 'BoardLayout';
export default BoardLayout;
