import API from '../../../../utils/API/API';
import { CreateWorkplace, UpdateWorkplace } from '../../types';

export const WORKPLACE_URL = 'api/workplaces';

export const getWorkplaceList = () =>
  API.get(`${WORKPLACE_URL}?size=999`).then((res) => res.data.data.content);

export const updateWorkplace = (workplaceId: number, values: UpdateWorkplace) =>
  API.put(`${WORKPLACE_URL}/${workplaceId}`, values).then(
    (res) => res.data.data
  );

export const deleteWorkplace = (workplaceId: number) =>
  API.delete(`${WORKPLACE_URL}/${workplaceId}`);

export const getWorkplace = (workplaceId: number) =>
  API.get(`${WORKPLACE_URL}/${workplaceId}`).then((res) => res.data.data);

export const createWorkplace = (values: CreateWorkplace) =>
  API.post(WORKPLACE_URL, values);
