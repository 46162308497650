import React, { useState } from 'react';
import {
  Button,
  ColorPicker,
  Drawer,
  Form,
  message,
  notification,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import { useQueryClient } from 'react-query';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { Board } from '../../Workspace/Board/types';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { colorPreset } from '../../../utils/helpers/colorPreset';
import { useUpdateBoard } from '../helpers/useUpdateBoard';
import { Link, useParams } from 'react-router-dom';
import { RcFile } from 'antd/es/upload';
import { KeycloakApp } from '../../../utils/Keycloak/Keycloak';
import { getUrlImage } from '../../../utils/helpers/getUrlImage';
import SearchBackground from './SearchBackground';

const FormBackground = React.memo(() => {
  const queryClient = useQueryClient();
  const { boardId: id } = useParams();
  const board = queryClient.getQueryData<Board>([BOARD_URL, id!]);
  const { mutate: mutateUpdateBoard } = useUpdateBoard();
  const [color, setColor] = useState<string>(board?.backgroundColor ?? '#fff');
  const [isSearch, setIsSearch] = useState(false);

  const props: UploadProps = {
    name: 'file',
    action: `${process.env.REACT_APP_PRODUCTION_URL}/api/filesystem`,
    headers: {
      authorization: `Bearer ${KeycloakApp.token}`,
    },
    accept: 'image/png, image/jpeg',
    maxCount: 1,
    beforeUpload,
    onChange(info) {
      if (info.file.status === 'done') {
        mutateUpdateBoard({
          backgroundUrl: getUrlImage(info.file.response.data),
        });
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  /**
   * @param {RcFile} file
   * @return {Promise}
   */
  async function beforeUpload(file: RcFile) {
    const isLt2M = file.size / 1024 / 1024 < 1;

    if (KeycloakApp?.isTokenExpired(5)) {
      await KeycloakApp.updateToken(5).then(() => {
        props!.headers!.authorization! = `Bearer ${KeycloakApp.token}`;
      });
    }
    if (!isLt2M) {
      notification['error']({
        message: `Изображение должно быть меньше 1MB!`,
      });
    }
    return isLt2M || Upload.LIST_IGNORE;
  }

  const changeBackgroundColor = (open: boolean) => {
    if (!open && color !== board?.backgroundColor)
      mutateUpdateBoard({ backgroundColor: color, backgroundUrl: '' });
  };

  return (
    <>
      <Form>
        <Form.Item>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>
              Загрузить свое изображение
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item label={'Цвет'}>
          <ColorPicker
            presets={colorPreset}
            defaultValue={color}
            onOpenChange={changeBackgroundColor}
            onChange={(_, hex) => setColor(hex)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type={'link'}
            icon={<SearchOutlined />}
            onClick={() => setIsSearch(true)}
          >
            Поиск фотографий Unsplash
          </Button>
        </Form.Item>
      </Form>
      <Drawer
        open={isSearch}
        onClose={() => setIsSearch(false)}
        mask={false}
        bodyStyle={{ padding: '24px 16px 24px 24px' }}
        title={
          <>
            Поиск фотографий
            <Typography.Text />{' '}
            <Link
              to={
                'https://unsplash.com/?utm_source=Yambook&utm_medium=referral'
              }
            >
              Unsplash
            </Link>
          </>
        }
      >
        <SearchBackground mutate={mutateUpdateBoard} />
      </Drawer>
    </>
  );
});
FormBackground.displayName = 'FormBackground';
export default FormBackground;
