import { useMutation, useQueryClient } from 'react-query';
import { CARD_URL, createChecklistItem } from '../api';
import { Card } from '../type';
import { notification } from 'antd';
import { Board } from '../../Workspace/Board/types';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';
import { produce } from 'immer';
import { getCurrentColumnAndCard } from './getCurrentColumnAndCard';

export const useCreateChecklistItem = (queryKey: any[]) => {
  const queryClient = useQueryClient();
  const { boardId, id } = useParams();

  return useMutation(queryKey, createChecklistItem, {
    onMutate: async (data) => {
      const cardData = queryClient.getQueryData<Card>(queryKey);

      await queryClient.cancelQueries([CARD_URL]);
      queryClient.setQueryData<Card>(
        queryKey,
        // @ts-ignore
        (oldData) => {
          return produce(oldData!, (draft) => {
            if (draft.checklist?.checklistItems) {
              draft.checklist?.checklistItems.push({
                title: data.title,
                checklistId: data.checklistId,
                isCompleted: false,
                id: 0,
              });
            }
          });
        }
      );

      queryClient.setQueryData<Board>(
        [BOARD_URL, boardId],
        // @ts-ignore
        (oldData) => {
          return produce(oldData!, (draft) => {
            const currentCard = draft.columnList
              .flatMap((col) => col.task)
              .find((card) => card.id === +id!)!;

            if (currentCard.checklist)
              currentCard.checklist.checklistItems.push({
                title: data.title,
                checklistId: data.checklistId,
                isCompleted: false,
                id: 0,
              });
          });
        }
      );

      return cardData;
    },
    onError: async (error, variables, context) => {
      notification.error({ message: 'Ошибка' });
      queryClient.setQueryData<Card>(queryKey, () => context!);
    },
    onSuccess: async (data) => {
      queryClient.setQueryData<Card>(queryKey, (oldData) => {
        return {
          ...oldData!,
          checklist: {
            ...oldData!.checklist!,
            checklistItems: [
              ...oldData!.checklist!.checklistItems.slice(0, -1),
              ...data,
            ],
          },
        };
      });

      queryClient.setQueryData<Board>([BOARD_URL, boardId], (oldData) => {
        return produce(oldData!, (draft) => {
          const { currentCard } = getCurrentColumnAndCard(draft, +id!);

          currentCard.checklist!.checklistItems.splice(-data.length);
          currentCard.checklist!.checklistItems.push(...data);
        });
      });
    },
  });
};
