import { useMutation, useQueryClient } from 'react-query';
import { CARD_URL, updateCard } from '../../../Card/api';
import { UpdateCard } from '../../../Card/type';
import { useParams } from 'react-router-dom';
import { BOARD_URL } from '../api/BoardApi';
import { produce } from 'immer';
import { Board } from '../types';

export const useUpdateCardMutation = () => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();
  return useMutation(
    [BOARD_URL, boardId],
    (values: [number, UpdateCard]) => updateCard(...values),
    {
      onMutate: (data) => {
        const [cardId, value] = data;

        queryClient.setQueryData<Board>([BOARD_URL, boardId], (old) => {
          return produce(old!, (draft) => {
            const sourceColumn = draft.columnList.find((col) => {
              const taskIdList = col.task.map((task) => task.id);
              return taskIdList.includes(cardId);
            });
            const targetColumn =
              draft.columnList.find((col) => col.id === value.columnId) ??
              sourceColumn;
            const currentCard = sourceColumn!.task.find(
              (card) => card.id === cardId
            )!;
            sourceColumn!.task = sourceColumn!.task.filter(
              (task) => task.id !== cardId
            );
            if (
              currentCard.parentTaskId &&
              targetColumn?.status === 'COMPLETE'
            ) {
              const parentCard = draft.columnList
                .flatMap((col) => col.task)
                ?.find((task) => task.id === currentCard.parentTaskId);
              if (parentCard) {
                const subTask = parentCard.subTaskList.find(
                  (task) => task.id === currentCard.id
                );
                if (subTask) {
                  subTask.column = { ...targetColumn };
                  subTask.isCompleted = true;
                }
              }
            }
            targetColumn?.task.splice(value.numberInColumn!, 0, {
              ...currentCard,
              isCompleted: sourceColumn!.status === 'COMPLETE',
            });
          });
        });
      },
      onError: async () => {
        await queryClient.invalidateQueries({ queryKey: [BOARD_URL, boardId] });
      },
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries({ queryKey: [CARD_URL] });
        queryClient.setQueryData<Board>([BOARD_URL, boardId], (old) => {
          return produce(old!, (draft) => {
            const currentCard = draft.columnList
              .find((col) => col.id === variables[1].columnId)
              ?.task.find((card) => card.id === variables[0]);

            if (currentCard) {
              Object.assign(currentCard, data);
              // поменять статус выполнения подзадачи
              if (currentCard.parentTaskId) {
                const parentCard = draft.columnList
                  .flatMap((col) => col.task)
                  .find((card) => card.id === currentCard.parentTaskId);

                if (parentCard) {
                  const subTask = parentCard.subTaskList.find(
                    (card) => card.id === currentCard.id
                  );
                  if (subTask) {
                    Object.assign(subTask, data);
                  }
                }
              }
            }
          });
        });
      },
    }
  );
};
