import React, { useEffect, useState } from 'react';
import { Form, Input, Skeleton, Space } from 'antd';
import { useQuery } from 'react-query';
import {
  getSearchSticker,
  getTrendSticker,
  GIPHY_SEARCH_URL,
  GIPHY_TREND_URL,
} from '../../../utils/API/apiGhiphy';
import { debounce } from 'lodash';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ImageSticker from './ImageSticker';

const Stickers = React.memo(() => {
  const { data: stickerList, isLoading } = useQuery(
    [GIPHY_TREND_URL],
    () => getTrendSticker(),
    { refetchOnWindowFocus: false }
  );
  const [params, setParams] = useState<{
    q: string;
  }>({ q: '' });

  const {
    refetch,
    data: searchSticker,
    isLoading: seacrhLoading,
  } = useQuery([GIPHY_SEARCH_URL], () => getSearchSticker(params), {
    enabled: false,
  });
  useEffect(() => {
    if (params.q) refetch();
  }, [params]);

  const onSearchSticker = debounce((q: string) => {
    setParams({ q });
  }, 300);

  const currentStickerList = searchSticker ?? stickerList;

  return (
    <>
      <img src={'/PoweredBy.png'} height={15} style={{ marginBottom: 8 }} />
      <Form>
        <Form.Item name={'q'}>
          <Input
            onKeyDown={(e) => e.stopPropagation()}
            placeholder={'Искать стикеры'}
            onChange={(e) => onSearchSticker(e.target.value)}
          />
        </Form.Item>
      </Form>
      <Skeleton loading={isLoading || seacrhLoading}>
        <Droppable
          droppableId={'sticker'}
          isDropDisabled={true}
          type={'STICKER'}
        >
          {(providedCard) => (
            <div {...providedCard.droppableProps} ref={providedCard.innerRef}>
              <Space wrap>
                {currentStickerList?.data.data.map(
                  (sticker: any, index: number) => (
                    <Draggable
                      draggableId={sticker.images.fixed_height_downsampled.url}
                      index={index}
                      key={sticker?.id}
                    >
                      {(provided, snapshot) => (
                        <>
                          <div
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              transform:
                                !snapshot.isDragging &&
                                snapshot.combineTargetFor !==
                                  sticker.images.fixed_height_downsampled.url
                                  ? undefined
                                  : provided.draggableProps.style?.transform,
                            }}
                            ref={provided.innerRef}
                          >
                            <ImageSticker
                              sticker={sticker}
                              isHide={snapshot.isDropAnimating}
                              cardId={snapshot.draggingOver}
                            />
                          </div>
                          {snapshot.isDragging && (
                            <img
                              alt={''}
                              src={sticker.images.fixed_height_downsampled.url}
                              height={64}
                              width={64}
                              style={{ display: 'block' }}
                            />
                          )}
                        </>
                      )}
                    </Draggable>
                  )
                )}
              </Space>
            </div>
          )}
        </Droppable>
      </Skeleton>
    </>
  );
});

Stickers.displayName = 'Stickers';
export default Stickers;
