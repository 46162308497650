import { useQuery, useQueryClient } from 'react-query';
import {
  ACCOUNT_ON_WORKPLACE_ROLE_URL,
  getAccountRole,
} from '../../components/Workspace/Home/components/api';
import { useAppSelector } from '../../store/rootReducer';
import { AccountOnWorkplace } from '../../components/Authorization/AuthorizationTypes';
import { Board } from '../../components/Workspace/Board/types';
import { BOARD_URL } from '../../components/Workspace/Board/api/BoardApi';
import { useLocation, useParams } from 'react-router-dom';

export const useRole = (workplaceId: number) => {
  const { profile } = useAppSelector((state) => state.authReducer);
  const params = { accountId: profile?.id ?? 0, workplaceId };
  const location = useLocation();
  const { data } = useQuery<AccountOnWorkplace>(
    [ACCOUNT_ON_WORKPLACE_ROLE_URL, params],
    () => getAccountRole(params),
    {
      refetchOnWindowFocus: () => !location.pathname.includes('files'),
      enabled: !!profile?.id && !!workplaceId,
    }
  );

  return data?.role ?? 'user';
};

export const useGetWorkplaceId = () => {
  const { boardId } = useParams();
  const queryClient = useQueryClient();
  const board = queryClient.getQueryData<Board>([BOARD_URL, boardId]);

  return board?.workplaceId;
};
