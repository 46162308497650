import React, { useMemo, useState } from 'react';
import {
  Button,
  ColorPicker,
  Form,
  Input,
  message,
  notification,
  Select,
  Space,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import { useQuery, useQueryClient } from 'react-query';
import {
  getWorkplaceList,
  WORKPLACE_URL,
} from '../../Workspace/Home/api/WorkplaceApi';
import { BoardCreate, Workplace } from '../../Workspace/types';
import { UploadOutlined } from '@ant-design/icons';
import { BOARD_URL, createBoard } from '../../Workspace/Board/api/BoardApi';
import { useAppSelector } from '../../../store/rootReducer';
import TextArea from 'antd/es/input/TextArea';
import { useGenericMutation } from '../../../utils/hooks/useGenericMutate';
import { Board } from '../../Workspace/Board/types';
import { KeycloakApp } from '../../../utils/Keycloak/Keycloak';
import { RcFile } from 'antd/es/upload';

const FormBoard = React.memo(
  ({ onCancel, workplaceId }: { onCancel: Function; workplaceId?: number }) => {
    const [backgroundUrl, setBackgroundUrl] = useState<string>();
    const [backgroundColor, setBackgroundColor] = useState<string>('#A5B1C2');
    const boardMutation = useGenericMutation(createBoard, [BOARD_URL]);
    const { profile } = useAppSelector((state) => state.authReducer);
    const queryKey = [WORKPLACE_URL, { profileId: profile?.id }];
    const { data: workplaceList, isLoading } = useQuery<Workplace[]>(
      queryKey,
      () => getWorkplaceList()
    );
    const queryClient = useQueryClient();

    const workplaceOptions = useMemo(
      () =>
        workplaceList
          ? workplaceList
              .filter(
                (workplace) => workplace.accountsOnWorkplaces[0].role === 'lead'
              )
              .map((workplace) => ({
                label: workplace.title,
                value: workplace.id,
              }))
          : [],
      [workplaceList]
    );

    const props: UploadProps = {
      name: 'file',
      action: `${process.env.REACT_APP_PRODUCTION_URL}/api/filesystem`,
      headers: {
        authorization: `Bearer ${KeycloakApp.token}`,
      },
      accept: 'image/png, image/jpeg',
      onChange(info) {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          setBackgroundUrl(
            `${info.file.response.data.folder}/${info.file.response.data.fileName}`.replace(
              'files',
              ''
            )
          );
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      beforeUpload,
    };

    /**
     * @param {RcFile} file
     * @return {Promise}
     */
    async function beforeUpload(file: RcFile) {
      const isLt2M = file.size / 1024 / 1024 < 1;

      if (KeycloakApp?.isTokenExpired(5)) {
        await KeycloakApp.updateToken(5).then(() => {
          props!.headers!.authorization! = `Bearer ${KeycloakApp.token}`;
        });
      }
      if (!isLt2M) {
        notification['error']({
          message: `Изображение должно быть меньше 1MB!`,
        });
      }
      return isLt2M || Upload.LIST_IGNORE;
    }

    const onFinish = (values: BoardCreate) => {
      boardMutation
        .mutateAsync({ ...values, authorId: profile!.id, backgroundUrl })
        // @ts-ignore
        .then((res: Board) => {
          onCancel();
          queryClient.setQueryData<Workplace[]>([WORKPLACE_URL], (old) => {
            const copy = Array.from(old ?? []);
            const workplaceId = copy.findIndex(
              (workplace) => workplace.id === res.workplaceId
            );
            copy[workplaceId] = {
              ...copy[workplaceId],
              boardList: [...copy[workplaceId].boardList, res],
            };
            return copy;
          });

          return res;
        });
    };

    const fields = [{ name: 'backgroundColor', value: backgroundColor }];

    return (
      <Space direction={'vertical'} style={{ width: '100%' }}>
        <Typography.Title type={'secondary'} level={5}>
          Создать доску
        </Typography.Title>
        <div
          style={{
            margin: 'auto',
            width: 187,
            height: 100,
            backgroundImage: backgroundUrl,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            padding: 4,
            borderRadius: 4,
            backgroundColor: backgroundColor,
          }}
        >
          <div
            style={{
              width: 187,
              height: 100,
              background:
                'url(https://trello.com/assets/14cda5dc635d1f13bc48.svg)' +
                ' no-repeat content-box',
            }}
          />
        </div>
        <Form fields={fields} layout={'vertical'} onFinish={onFinish}>
          <Form.Item label={'Цвета фона'} name={'backgroundColor'}>
            <ColorPicker onChange={(_, hex) => setBackgroundColor(hex)} />
          </Form.Item>
          <Form.Item name={'backgroundUrl'} label={'Фоновое изображение'}>
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Нажмите для загрузки</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name={'title'}
            rules={[{ message: '', required: true }]}
            label={'Заголовок доски'}
          >
            <Input />
          </Form.Item>
          <Form.Item
            initialValue={workplaceId}
            name={'workplaceId'}
            label={'Рабочее пространство'}
            rules={[{ required: true, message: '' }]}
          >
            <Select loading={isLoading} options={workplaceOptions} />
          </Form.Item>
          <Form.Item name={'description'} label={'Описание'}>
            <TextArea rows={4} />
          </Form.Item>
          <Button
            htmlType={'submit'}
            type={'primary'}
            loading={boardMutation.isLoading}
            style={{ width: '100%' }}
          >
            Создать
          </Button>
        </Form>
      </Space>
    );
  }
);
FormBoard.displayName = 'FormBoard';
export default FormBoard;
