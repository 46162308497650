import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import BoardLayout from '../BoardLayout/BoardLayout';
import {
  Breadcrumb,
  BreadcrumbProps,
  Button,
  Col,
  Form,
  Input,
  InputRef,
  MenuProps,
  Row,
  Space,
  Table,
  Tooltip,
  Upload,
  UploadProps,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { EXPLORER_FILE_URL, EXPLORER_URL, getFiles } from './api/ExplorerApi';
import {
  FileTwoTone,
  FolderAddOutlined,
  FolderTwoTone,
  HomeOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { KeycloakApp } from '../../utils/Keycloak/Keycloak';
import { useExplorerMutate } from './helpers/explorerMutate';
import { DataTableExplorer, ExplorerData } from './type';
import { useCreateFolder } from './helpers/useCreateFolder';
import DropdownMenu from './components/Dropdown';
import API from '../../utils/API/API';
import { saveFile } from '../../utils/helpers/saveFile';
import { useDeleteExplorerItemMutation } from './helpers/useDeleteExplorerItemMutation';
import { useRenameExplorerItem } from './helpers/useRename';
import Search from 'antd/es/input/Search';
import { debounce } from 'lodash';
import { useGetWorkplaceId, useRole } from '../../utils/helpers/useRole';
import { findObjectByRelativePath } from './helpers/findObjectByRelativePath';
import TreeExplorer from './components/TreeExplorer';
import { sortCallback } from './helpers/sortCallback';

const Explorer = React.memo(() => {
  const { boardId } = useParams();
  const [path, setPath] = useState('');
  const initPath = `/${boardId!}`;
  const { data, isLoading: isLoadingData } = useQuery<ExplorerData, AxiosError>(
    [EXPLORER_URL, { path: `/${boardId!}` }],
    () => getFiles({ path: initPath }),
    {
      retry: (_, error) => error?.response?.status !== 403,
      refetchOnWindowFocus: false,
    }
  );
  const [currentRow, setCurrentRow] = useState<DataTableExplorer | null>(null);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });
  const [dataSource, setDataSource] = useState<DataTableExplorer[]>([]);
  const inputRef = useRef<InputRef>(null);
  const { mutate: createFolderMutation } = useCreateFolder(initPath);
  const { mutate: deleteFolderMutation } = useDeleteExplorerItemMutation(
    `/${boardId!}`,
    currentRow?.file.type !== 'directory'
  );
  const { mutateAsync: renameMutation } = useRenameExplorerItem(initPath);
  const workplaceId = useGetWorkplaceId();
  const role = useRole(workplaceId ?? 0);
  const [folderName, setFolderName] = useState('Новая папка');

  const onFinishCreateFolder = (value: string, record: DataTableExplorer) => {
    record.editableType === 'create-folder'
      ? createFolderMutation({
          folderName: value,
          path: `${initPath}/${path}`,
        })
      : renameMutation({
          oldPath: `${initPath}/${record.file.relativePath}`,
          newPath: `${initPath}/${path ? path + '/' : path}${value}`,
        }).then(() => setEditable());
  };

  const columns: ColumnsType<any> = data
    ? [
        {
          title: 'Тип',
          dataIndex: ['file', 'type'],
          align: 'center',
          width: 120,
          render: (value) => {
            switch (value) {
              case 'directory':
                return <FolderTwoTone style={{ fontSize: 20 }} />;
              default:
                return (
                  <FileTwoTone
                    twoToneColor={'#bfbfbf'}
                    style={{ fontSize: 20 }}
                  />
                );
            }
          },
        },
        {
          title: 'Наименование',
          dataIndex: ['file', 'name'],
          align: 'center',
          render: (value, record) =>
            record.editableType ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Form
                  style={{ width: 250 }}
                  fields={[{ name: 'title', value: folderName }]}
                  onFinish={(values) => {
                    onFinishCreateFolder(values.title, record);
                  }}
                >
                  <Form.Item name={'title'} initialValue={value} noStyle>
                    <Input
                      ref={inputRef}
                      onChange={(e) => setFolderName(e.target.value.trim())}
                      onBlur={() => {
                        onFinishCreateFolder(folderName, record);
                      }}
                    />
                  </Form.Item>
                </Form>
              </div>
            ) : (
              value
            ),
        },
        {
          title: 'Размер',
          dataIndex: ['file', 'size'],
          align: 'center',
          width: 120,
          render: (item, record) =>
            record?.file.type === 'directory' ? '' : item,
        },
      ]
    : [];
  const { mutate: uploadFile, isLoading } = useExplorerMutate();

  const itemRender: BreadcrumbProps['itemRender'] = (
    route,
    params,
    routes,
    paths
  ) => {
    const last = paths.length === routes.length - 1;

    const currentPath = `${paths.join('/')}`;

    return last ? (
      <span>{route.title}</span>
    ) : (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          goPath(data!, currentPath);
        }}
      >
        {route.title}
      </div>
    );
  };

  const props: UploadProps = {
    name: 'file',
    headers: {
      authorization: `Bearer ${KeycloakApp.token ?? ''}`,
    },
    beforeUpload: () => false,
    fileList: [],
    multiple: true,
    onChange(info) {
      const data = new FormData();
      data.append(
        'file',
        info.fileList.find((file) => file.name === info.file.name)!
          .originFileObj!
      );
      data.append('path', `${initPath}/${path}`);
      uploadFile(data);
    },
  };

  const breadCrumbItem: BreadcrumbProps['items'] = [
    {
      title: <HomeOutlined />,
    },
    ...(path === ''
      ? []
      : path.split('/').map((el) => ({
          title: el,
          path: el,
        }))),
  ];

  const sortDataSource = (data: DataTableExplorer[]) => {
    return data.sort((a, b) => sortCallback(a.file, b.file));
  };

  useEffect(() => {
    if (path === '') {
      const res = [
        ...(data?.children?.map((el) => ({
          editableType: undefined,
          file: { ...el },
        })) ?? []),
      ];

      setDataSource(sortDataSource(res));
      setPath(data?.relativePath === '.' ? '' : data?.relativePath ?? '');
    } else {
      goPath(data!, path);
    }
  }, [data]);

  useEffect(() => {
    inputRef.current?.focus({
      cursor: 'all',
    });
  }, [dataSource]);

  const createFolder = () => {
    setFolderName('Новая папка');
    setDataSource((prevState) => [
      {
        editableType: 'create-folder',
        file: {
          name: 'Новая папка',
          path: '',
          relativePath: '',
          type: 'directory',
          isSymbolicLink: false,
          sizeInBytes: 0,
          size: '',
        },
      },
      ...prevState,
    ]);
  };

  const setEditable = () => {
    setDataSource((prevState) => {
      const cloneState = prevState.map((el) => ({
        ...el,
        editableType: undefined,
      }));
      const currentRenameRowIndex = cloneState.findIndex(
        (el) => el.file.name === currentRow?.file.name
      );
      if (currentRenameRowIndex >= 0) {
        cloneState.splice(currentRenameRowIndex, 1, {
          ...prevState[currentRenameRowIndex],
          // @ts-ignore
          editableType: 'edit-name',
        });
        setFolderName(prevState[currentRenameRowIndex].file.name);
      }
      return cloneState;
    });
  };

  const items = useMemo(() => {
    const items: MenuProps['items'] = [];

    if (role === 'lead') {
      items.push({
        key: '1',
        label: 'Переименовать',
        onClick: () => setEditable(),
      });
    }

    if (currentRow?.file.type !== 'directory') {
      items.push({
        key: '2',
        label: 'Скачать',
        onClick: () =>
          API.get(EXPLORER_FILE_URL, {
            params: { path: `${initPath}/${currentRow?.file.relativePath}` },
            responseType: 'blob',
          }).then((res) => {
            saveFile(res.data, currentRow!.file.name);
          }),
      });
    }

    if (role === 'lead')
      items.push({
        key: '3',
        label: 'Удалить',
        danger: true,
        onClick: () => {
          deleteFolderMutation({
            path: `${boardId}/${currentRow!.file.relativePath}`,
          });
        },
      });

    return items;
  }, [currentRow]);

  const onSearch = (value: string) => {
    setDataSource(() => {
      return (
        findObjectByRelativePath(data!, path || '.')
          ?.children?.map((el) => ({
            file: el,
            editableType: undefined,
          }))
          .filter((el) =>
            el.file.name.toLowerCase().includes(value.toLowerCase())
          ) ?? []
      );
    });
  };

  const debounceSearch = debounce(onSearch, 300);

  const containerHeight = '100vh - 120px';

  const goPath = useCallback((record: ExplorerData, relativePath: string) => {
    setPath(relativePath);
    setDataSource(() => {
      const res = [
        ...(findObjectByRelativePath(
          record,
          relativePath ? relativePath : '.'
        )?.children?.map((el) => ({
          file: el,
          editableType: undefined,
        })) ?? []),
      ];

      return sortDataSource(res);
    });
  }, []);

  return (
    <BoardLayout>
      <DropdownMenu open={!!currentRow} pos={position} items={items} />
      <Row
        gutter={16}
        style={{
          margin: '16px 64px',
          backgroundColor: 'white',
          borderRadius: 8,
          height: `calc(${containerHeight})`,
        }}
      >
        <Col span={6} style={{ backgroundColor: '#f4faff' }}>
          <TreeExplorer data={data} goPath={goPath} selectKey={path} />
        </Col>
        <Col span={18}>
          <div>
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                margin: '16px',
                alignItems: 'flex-end',
              }}
            >
              <Breadcrumb items={breadCrumbItem} itemRender={itemRender} />
              <Space>
                <Search style={{ width: 300 }} onSearch={debounceSearch} />
                {role === 'lead' && (
                  <Tooltip title={'Загрузить файл'}>
                    <Upload {...props}>
                      <Button loading={isLoading} icon={<UploadOutlined />} />
                    </Upload>
                  </Tooltip>
                )}
                {role === 'lead' && (
                  <Tooltip title={'Создать папку'}>
                    <Button
                      disabled={!!dataSource[0]?.editableType}
                      icon={<FolderAddOutlined />}
                      onClick={createFolder}
                    />
                  </Tooltip>
                )}
              </Space>
            </div>
            <Upload {...props} openFileDialogOnClick={false}>
              <Table
                columns={columns}
                size={'small'}
                dataSource={dataSource}
                pagination={false}
                loading={isLoadingData}
                scroll={{ y: `calc(${containerHeight} - 119px)` }}
                expandable={{ childrenColumnName: 'lol' }}
                onRow={(record: DataTableExplorer) => {
                  return {
                    onDoubleClick: () => {
                      if (
                        record.file.type === 'directory' &&
                        !record.editableType
                      ) {
                        goPath(record.file, record.file.relativePath);
                      }
                    },
                    onContextMenu: (event) => {
                      event.preventDefault();
                      if (!currentRow) {
                        document.addEventListener(
                          `click`,
                          function onClickOutside() {
                            setCurrentRow(null);
                            document.removeEventListener(
                              `click`,
                              onClickOutside
                            );
                          }
                        );
                      }
                      setCurrentRow(record);
                      setPosition({ x: event.clientX, y: event.clientY });
                    },
                  };
                }}
              />
            </Upload>
          </div>
        </Col>
      </Row>
    </BoardLayout>
  );
});
Explorer.displayName = 'Explorer';
export default Explorer;
