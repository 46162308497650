import { createSlice } from '@reduxjs/toolkit';
import { ProfileType } from '../../components/Authorization/AuthorizationTypes';

declare type InitialStateType = {
  loggedIn: boolean;
  profile: ProfileType | null;
  token: string | null;
};
export const authReducer = createSlice({
  name: 'authReducer',
  initialState: <InitialStateType>{
    profile: null,
  },
  reducers: {
    loggedIn: (state, action) => {
      state.profile = action.payload;
    },
  },
});

export const { loggedIn } = authReducer.actions;
