import React from 'react';
import { useQuery } from 'react-query';
import { CARD_URL, getCardListNoCol } from '../../Card/api';
import { useAppSelector } from '../../../store/rootReducer';
import { Empty, Skeleton, Space, Typography } from 'antd';
import { Card } from '../../Card/type';
import CardListItem from '../../Card/components/CardListItem';
import { groupBy } from 'lodash';
import styled from 'styled-components';

const CardListMenu = React.memo(({ closePopup }: { closePopup: Function }) => {
  const { profile } = useAppSelector((state) => state.authReducer);
  const params = {
    accountId: profile?.id,
    notComplete: true,
    size: 200,
    archived: false,
  };
  const { data: cardList, isLoading } = useQuery<{ content: Card[] }>(
    [CARD_URL, params],
    () => getCardListNoCol(params)
  );

  const groupCardList = Object.entries(
    groupBy(cardList?.content, 'column.title')
  );

  return (
    <Skeleton loading={isLoading}>
      <List>
        {groupCardList.length ? (
          groupCardList.map(([columnTitle, cardList], index) => (
            <div key={cardList[index]?.columnId}>
              <Typography.Text strong type={'secondary'}>
                {columnTitle}
              </Typography.Text>
              <Space style={{ width: '100%' }} direction={'vertical'}>
                {cardList.map((card) => (
                  <CardListItem
                    key={card.id}
                    card={card}
                    closePopup={closePopup}
                  />
                ))}
              </Space>
            </div>
          ))
        ) : (
          <Empty />
        )}
      </List>
    </Skeleton>
  );
});

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 8px;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: transparent /* color of the tracking area */
  }
  &::-webkit-scrollbar-thumb{
    background-color: #d9d9d9; /* color of the scroll thumb */
    border-radius: 20px; /
  }
`;

CardListMenu.displayName = 'CardListMenu';
export default CardListMenu;
