import React, { useState } from 'react';
import { Button, Drawer, Space } from 'antd';
import {
  ClusterOutlined,
  FolderOutlined,
  GifOutlined,
  PictureOutlined,
  SettingOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import FormBackground from './FormBackground';
import FormLabel from './FormLabel';
import Archive from './Archive';
import SettingColumn from './SettingColumn';
import Setting from './Setting';
import Stickers from './Stickers';
import { useGetWorkplaceId, useRole } from '../../../utils/helpers/useRole';

const MenuBoard = React.memo(
  ({
    isOpenSetting,
    setIsOpenSetting,
  }: {
    isOpenSetting: boolean;
    setIsOpenSetting: Function;
  }) => {
    const [isChangeBackground, setIsChangeBackground] = useState(false);
    const [isLabelChange, setIsLabelChange] = useState(false);
    const [isArchive, setIsArchive] = useState(false);
    const [settingColumn, setSettingColumn] = useState(false);
    const [isSetting, setIsSetting] = useState(false);
    const [isStickers, setIsStickers] = useState(false);
    const workplaceId = useGetWorkplaceId();
    const role = useRole(workplaceId ?? 0);
    return (
      <>
        <Drawer
          title={'Меню'}
          open={isOpenSetting}
          mask={false}
          onClose={() => setIsOpenSetting(false)}
        >
          {role === 'lead' && (
            <Button
              type={'text'}
              style={{ width: '100%', textAlign: 'left' }}
              onClick={() => setIsChangeBackground(true)}
            >
              <Space>
                <PictureOutlined />
                <span>Сменить фон</span>
              </Space>
            </Button>
          )}
          {role === 'lead' && (
            <Button
              type={'text'}
              style={{ width: '100%', textAlign: 'left' }}
              onClick={() => setIsLabelChange(true)}
            >
              <Space>
                <TagsOutlined />
                <span>Метки</span>
              </Space>
            </Button>
          )}
          <Button
            type={'text'}
            style={{ width: '100%', textAlign: 'left' }}
            onClick={() => setIsArchive(true)}
          >
            <Space>
              <FolderOutlined />
              <span>Архив</span>
            </Space>
          </Button>
          <Button
            type={'text'}
            style={{ width: '100%', textAlign: 'left' }}
            onClick={() => setIsStickers(true)}
          >
            <Space>
              <GifOutlined />
              <span>Стикеры</span>
            </Space>
          </Button>
          {role === 'lead' && (
            <Button
              type={'text'}
              style={{ width: '100%', textAlign: 'left' }}
              onClick={() => setIsSetting(true)}
            >
              <Space>
                <SettingOutlined />
                <span>Настройки</span>
              </Space>
            </Button>
          )}
          {role === 'lead' && (
            <Button
              type={'text'}
              style={{ width: '100%', textAlign: 'left' }}
              onClick={() => setSettingColumn(true)}
            >
              <Space>
                <ClusterOutlined />
                <span>Настройка колонок</span>
              </Space>
            </Button>
          )}
        </Drawer>
        <Drawer
          open={isChangeBackground}
          title={'Сменить фон'}
          mask={false}
          onClose={() => setIsChangeBackground(false)}
          destroyOnClose={true}
          push={false}
        >
          <FormBackground />
        </Drawer>
        <Drawer
          open={isLabelChange}
          title={'Управление метками'}
          onClose={() => setIsLabelChange(false)}
          destroyOnClose={true}
        >
          <FormLabel />
        </Drawer>
        <Drawer
          open={isArchive}
          title={'Архив'}
          onClose={() => setIsArchive(false)}
          destroyOnClose={true}
        >
          <Archive />
        </Drawer>
        <Drawer
          open={settingColumn}
          title={'Настройка колонок'}
          onClose={() => setSettingColumn(false)}
          destroyOnClose={true}
        >
          <SettingColumn />
        </Drawer>
        <Drawer
          open={isSetting}
          title={'Настройка доски'}
          onClose={() => setIsSetting(false)}
          destroyOnClose={true}
        >
          <Setting />
        </Drawer>
        <Drawer
          open={isStickers}
          title={'Стикеры'}
          onClose={() => setIsStickers(false)}
          destroyOnClose={true}
          mask={false}
        >
          <Stickers />
        </Drawer>
      </>
    );
  }
);
MenuBoard.displayName = 'SettingBoard';
export default MenuBoard;
