import { useMutation, useQueryClient } from 'react-query';
import { notification } from 'antd';
import { COLUMN_URL, updateColumn } from '../api';
import { UpdateColumn } from '../types';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { Board } from '../../Workspace/Board/types';

export const useColumnUpdateMutation = (boardId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    [COLUMN_URL, boardId],
    (values: [number, UpdateColumn]) => updateColumn(...values),
    {
      onMutate: async ([columnId, values]) => {
        await queryClient.cancelQueries([BOARD_URL]);
        const prevValue = queryClient.getQueryData<Board>([BOARD_URL, boardId]);
        const columnList = prevValue?.columnList;
        const currColIndex = columnList?.findIndex(
          (col) => col.id === columnId
        );
        const copyColumnList = Array.from(columnList ?? []);
        if (currColIndex !== undefined)
          copyColumnList[currColIndex] = {
            ...copyColumnList[currColIndex],
            ...values,
          };
        queryClient.setQueryData<Board>([BOARD_URL, boardId], (old) => ({
          ...old!,
          columnList: copyColumnList,
        }));
        return prevValue;
      },
      onError: (_, columnId, context: any) => {
        notification.error({ message: 'Ошибка архивирования' });
        queryClient.setQueryData([BOARD_URL, boardId], () => context);
      },
    }
  );
};
