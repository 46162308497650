import { deleteWorkplace, WORKPLACE_URL } from '../api/WorkplaceApi';
import { Workplace } from '../../types';
import { useMutation, useQueryClient } from 'react-query';
import { notification } from 'antd';
import { useAppSelector } from '../../../../store/rootReducer';

export const useDeleteWorkplaceMutation = (workplaceId: string) => {
  const queryClient = useQueryClient();
  const { profile } = useAppSelector((state) => state.authReducer);
  return useMutation([WORKPLACE_URL, workplaceId], deleteWorkplace, {
    onMutate: async () => {
      await queryClient.cancelQueries([WORKPLACE_URL]);
      const prevValues = queryClient.getQueryData([
        WORKPLACE_URL,
        { profileId: profile?.id },
      ]);
      queryClient.setQueryData<Workplace[]>(
        [WORKPLACE_URL, { profileId: profile?.id }],
        // @ts-ignore
        (oldData) =>
          oldData?.filter((workplace) => workplace.id !== +workplaceId)
      );

      return prevValues;
    },
    onError: (error, variables, context) => {
      notification.error({
        message: 'Ошибка при удалении рабочего пространства',
      });
      queryClient.setQueryData<Workplace>(
        [WORKPLACE_URL, { profileId: profile?.id }],
        // @ts-ignore
        () => context
      );
    },
  });
};
