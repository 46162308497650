import { useMutation, useQueryClient } from 'react-query';
import { COLUMN_URL, createColumn } from '../../../Column/ColumnApi';
import { useParams } from 'react-router-dom';
import { Column } from '../../../Column/types';
import { notification } from 'antd';
import { BOARD_URL, BOARD_URL_FULL } from '../api/BoardApi';
import { Board } from '../types';

export const useCreateColumn = () => {
  const { boardId } = useParams();
  const queryClient = useQueryClient();
  return useMutation([BOARD_URL, boardId], createColumn, {
    onMutate: async (data) => {
      await queryClient.cancelQueries([BOARD_URL, boardId]);
      queryClient.setQueryData<Board>([BOARD_URL, boardId], (old) => ({
        ...old!,
        columnList: [
          ...old!.columnList,
          { ...data, id: 0, status: 'IN_PROGRESS', task: [] },
        ],
      }));
      return queryClient.getQueryData<Board>([BOARD_URL, boardId]);
    },
    onSuccess: (data) => {
      queryClient.setQueryData<Board>([BOARD_URL, boardId], (old) => ({
        ...old!,
        columnList: [...old!.columnList.slice(0, -1), data],
      }));
      queryClient.removeQueries([BOARD_URL_FULL]);
    },
    onError: (_, variables, context: any) => {
      notification.error({ message: 'Ошибка при создании колонки' });
      queryClient.setQueryData<Column[]>([COLUMN_URL, boardId], () => context);
    },
  });
};
