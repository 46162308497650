import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from './reducers/AuthReducer';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import store from './store';
import { stickerReducer } from './reducers/Sticker';

const rootReducer = combineReducers({
  authReducer: authReducer.reducer,
  stickerReducer: stickerReducer.reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default rootReducer;
