import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  ACCOUNT_ON_WORKPLACE_URL,
  ACCOUNT_URL,
  createAccountOnWorkplace,
  getAccountOnWorkplaceList,
  updateAccountOnWorkplace,
} from './api';
import {
  Avatar,
  Button,
  Form,
  List,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import {
  AccountRole,
  ProfileType,
} from '../../../Authorization/AuthorizationTypes';
import { useAppSelector } from '../../../../store/rootReducer';
import { Workplace } from '../../types';
import { useOnCustomSelect } from '../../../../utils/hooks/useCustomSelect';
import { UserOutlined } from '@ant-design/icons';
import { useGenericMutation } from '../../../../utils/hooks/useGenericMutate';
import { useDeleteAccountBondMutation } from '../helpers/useDeleteAccountBondMutation';
import { produce } from 'immer';
import { useRole } from '../../../../utils/helpers/useRole';

const getRoleName = (role: AccountRole) => {
  switch (role) {
    case 'lead':
      return 'Лид';
    case 'manager':
      return 'Менеджер';
    default:
      return 'Участник';
  }
};

const MembersView = React.memo(({ workplace }: { workplace?: Workplace }) => {
  const { data, isLoading } = useQuery(
    [ACCOUNT_ON_WORKPLACE_URL, workplace?.id.toString()],
    () => getAccountOnWorkplaceList(workplace!.id)
  );
  const { mutateAsync: createMutate, isLoading: createIsLoading } =
    useGenericMutation<any, any>(
      createAccountOnWorkplace,
      [ACCOUNT_ON_WORKPLACE_URL, workplace!.id.toString()],
      (oldData, newData) => [
        ...oldData,
        ...newData.memberIdList.map((memberId: number) => ({
          account: dataList?.find((account) => account.id === memberId),
        })),
      ]
    );
  const [memberIdList, setMemberIdList] = useState([]);
  const { mutate: deleteMutate, isLoading: deleteIsLoading } =
    useDeleteAccountBondMutation(workplace?.id?.toString());
  const [isInvite, setIsInvite] = useState(false);
  const { profile } = useAppSelector((state) => state.authReducer);
  const ROLE = useRole(workplace?.id ?? 0);
  const { mutateAsync: updateMutation, isLoading: updateLoading } =
    useGenericMutation<any, any>(
      updateAccountOnWorkplace,
      [ACCOUNT_ON_WORKPLACE_URL, workplace!.id.toString()],
      (oldData, newData) => {
        return produce(oldData, (draft: any) => {
          draft.find((bond: any) => bond.id === newData.id).role = newData.role;
        });
      }
    );
  const getExtraBtn = (
    account: ProfileType,
    bondId: number,
    role: AccountRole
  ) => {
    if (ROLE === 'lead') {
      return [
        <Select
          style={{ width: 120 }}
          key={'select'}
          defaultValue={role}
          loading={updateLoading}
          onChange={(role) => updateMutation({ id: bondId, role })}
          options={[
            { label: getRoleName('lead'), value: 'lead' },
            { label: getRoleName('manager'), value: 'manager' },
            { label: getRoleName('user'), value: 'user' },
          ]}
        />,
        account.id === profile?.id && workplace?.authorId !== profile?.id ? (
          <Button
            style={{ width: 100 }}
            onClick={() => deleteMutate(bondId)}
            key={'exit'}
            loading={deleteIsLoading}
          >
            Покинуть
          </Button>
        ) : workplace?.authorId === profile?.id ? (
          <></>
        ) : (
          <Button
            onClick={() => deleteMutate(bondId)}
            style={{ width: 100 }}
            key={'exit'}
            loading={deleteIsLoading || !bondId}
          >
            Выгнать
          </Button>
        ),
      ];
    } else return [<Tag key={'tag'}>{getRoleName(role)}</Tag>];
  };
  const { dataList, searchHandler } = useOnCustomSelect<ProfileType>(
    `${ACCOUNT_URL}?q=`,
    'id'
  );
  const getOptions = () => {
    const accountIdList = data?.map(
      ({ account }: { account: ProfileType }) => account.id
    );
    return dataList
      ?.filter((account) => !accountIdList.includes(account.id))
      .map((account) => ({
        label: (
          <Space>
            <Avatar
              icon={<UserOutlined />}
              src={account.avatarUrl}
              size={'small'}
            />
            <Typography.Title level={5}>
              {account.personFullName}
            </Typography.Title>
          </Space>
        ),
        value: account.id,
      }));
  };

  const options = useMemo(getOptions, [dataList, data]);

  const onFinish = (values: { memberIdList: number[] }) => {
    createMutate({ workplaceId: workplace!.id, ...values }).then(() =>
      setMemberIdList([])
    );
  };

  const fields = [{ name: 'memberIdList', value: memberIdList }];

  return (
    <Spin spinning={isLoading}>
      <List
        itemLayout="horizontal"
        dataSource={data}
        style={{
          maxHeight: 'calc(100vh - 155px)',
          overflow: 'auto',
          marginBottom: 16,
        }}
        renderItem={({
          account,
          id,
          role,
        }: {
          account: ProfileType;
          id: number;
          role: AccountRole;
        }) => (
          <List.Item actions={getExtraBtn(account, id, role ?? 'user')}>
            <List.Item.Meta
              avatar={
                <Avatar icon={<UserOutlined />} src={account.avatarUrl} />
              }
              title={account.personFullName}
            />
          </List.Item>
        )}
      />
      {isInvite ? (
        <Form onFinish={onFinish} fields={fields}>
          <Form.Item name={'memberIdList'}>
            <Select
              style={{ width: '100%' }}
              placeholder={'Введите имя участника'}
              showSearch={true}
              filterOption={false}
              onSearch={searchHandler}
              options={options}
              mode={'multiple'}
              size={'large'}
            />
          </Form.Item>
          <Space direction={'vertical'} style={{ width: '100%' }}>
            <Button
              htmlType={'submit'}
              type={'primary'}
              style={{ width: '100%' }}
            >
              Пригласить
            </Button>
            <Button
              onClick={() => setIsInvite(false)}
              style={{ width: '100%' }}
            >
              Отмена
            </Button>
          </Space>
        </Form>
      ) : (
        ROLE === 'lead' && (
          <Button
            type={'primary'}
            style={{ width: '100%' }}
            onClick={() => setIsInvite(true)}
            loading={createIsLoading}
          >
            Пригласить участников
          </Button>
        )
      )}
    </Spin>
  );
});
MembersView.displayName = 'MembersView';
export default MembersView;
