import React, { useState } from 'react';
import {
  AppstoreOutlined,
  BlockOutlined,
  FileOutlined,
  NotificationOutlined,
  PlusOutlined,
  ProjectOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import {
  Badge,
  Button,
  Dropdown,
  Layout,
  Menu,
  Modal,
  Popover,
  Space,
  theme,
  Typography,
} from 'antd';
import { NavLink, Outlet } from 'react-router-dom';
import FormWorkplace from './components/FormWorkplace';
import FormBoard from './components/FormBoard';
import { isMobile } from 'react-device-detect';
import CardListMenu from './components/CardListMenu';
import { KeycloakApp } from '../../utils/Keycloak/Keycloak';
import { useAppSelector } from '../../store/rootReducer';
import NotificationList from './components/NotificationList';
import styled from 'styled-components';

const { Header, Content } = Layout;

const { useToken } = theme;

const PageLayout = () => {
  const [isCreateWorkplace, setIsCreateWorkplace] = useState(false);
  const [isCreateBoard, setIsCreateBoard] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const { profile } = useAppSelector((state) => state.authReducer);
  const { token } = useToken();

  const createItems: MenuProps['items'] = [
    {
      label: (
        <div>
          <Space>
            <ProjectOutlined /> <span>Создать доску</span>
          </Space>
          <Typography.Paragraph type={'secondary'}>
            Доска представляет собой совокупность карточек, упорядоченных в
            списках. Используйте её для управления проектом, отслеживания или
            организации чего угодно.
          </Typography.Paragraph>
        </div>
      ),
      key: '0',
      onClick: () => setIsCreateBoard(true),
    },
    {
      label: (
        <div>
          <Space>
            <TeamOutlined /> <span>Создать рабочее пространство</span>
          </Space>
          <Typography.Paragraph type={'secondary'}>
            Рабочее пространство представляет собой группу досок и людей. Оно
            поможет организовать работу в компании, внештатную работу, семейные
            дела или отдых с друзьями.
          </Typography.Paragraph>
        </div>
      ),
      key: '1',
      onClick: () => setIsCreateWorkplace(true),
    },
  ];

  const leftMenu: MenuProps['items'] = [
    {
      label: (
        <NavLink to={'/'}>{isMobile ? '' : 'Рабочие пространства'}</NavLink>
      ),
      key: '/',
      icon: <AppstoreOutlined />,
    },
    {
      label: (
        <Popover
          trigger={'click'}
          open={openPopup}
          onOpenChange={setOpenPopup}
          content={<CardListMenu closePopup={() => setOpenPopup(false)} />}
          placement={isMobile ? 'bottom' : 'bottomLeft'}
          overlayStyle={{ width: 300 }}
          style={{ height: 48 }}
          destroyTooltipOnHide={true}
        >
          {isMobile ? (
            <BlockOutlined />
          ) : (
            <div>
              <BlockOutlined style={{ marginRight: 10 }} />
              Мои карточки
            </div>
          )}
        </Popover>
      ),
      key: '/cards',
    },
    {
      label: <NavLink to={'/files/75'}>{isMobile ? '' : 'Файлы'}</NavLink>,
      key: '/',
      icon: <FileOutlined />,
    },
  ];

  const rightMenu: MenuProps['items'] = [
    {
      label: (
        <Popover
          trigger={'click'}
          content={<NotificationList />}
          placement={isMobile ? 'bottom' : 'bottomLeft'}
          style={{ height: 48 }}
          overlayInnerStyle={{ padding: '12px 0', width: 310 }}
        >
          <div style={{ padding: '0 16px' }}>
            <Badge
              count={profile?._count.notification}
              size={'small'}
              style={{ fontSize: '0.75rem' }}
              color={token.colorPrimary}
            >
              <NotificationOutlined />
            </Badge>{' '}
          </div>
        </Popover>
      ),
      style: {
        padding: 0,
      },
      key: '/notification',
    },
    {
      label: <UserOutlined />,
      key: '10',
      children: [
        {
          type: 'group',
          label: 'Учетная запись',
          key: '11',
          children: [
            {
              label: <NavLink to={'/profile'}>Профиль</NavLink>,
              key: 'profile',
            },
            {
              label: 'Выйти',
              key: 'exit',
              onClick: () => {
                KeycloakApp.logout();
              },
            },
          ],
        },
      ],
    },
  ];

  return (
    <Layout className="wrapper" style={{ height: '100%', overflow: 'hidden' }}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 48,
        }}
      >
        <Space>
          <Menu
            selectedKeys={['1']}
            items={leftMenu}
            theme="dark"
            disabledOverflow={true}
            style={{
              height: 48,
              alignItems: 'center',
              lineHeight: '48px',
              display: 'flex',
            }}
            mode={'horizontal'}
          />
          <Dropdown
            menu={{ items: createItems }}
            trigger={['click']}
            overlayStyle={{ width: isMobile ? 250 : 400 }}
            placement={isMobile ? 'bottomCenter' : 'bottomRight'}
          >
            {isMobile ? (
              <Button icon={<PlusOutlined />} type={'primary'} />
            ) : (
              <Button type={'primary'}>Создать</Button>
            )}
          </Dropdown>
        </Space>
        <Menu
          items={rightMenu}
          mode={'horizontal'}
          selectable={false}
          disabledOverflow={true}
          theme="dark"
          style={{ lineHeight: '48px' }}
        />
      </Header>
      <WrapperContent $isMobile={isMobile}>
        <Outlet />
      </WrapperContent>
      <Modal
        open={isCreateWorkplace}
        footer={false}
        width={900}
        destroyOnClose={true}
        onCancel={() => setIsCreateWorkplace(false)}
      >
        <FormWorkplace onClose={() => setIsCreateWorkplace(false)} />
      </Modal>
      <Modal
        open={isCreateBoard}
        footer={false}
        destroyOnClose={true}
        onCancel={() => setIsCreateBoard(false)}
        width={350}
      >
        <FormBoard onCancel={() => setIsCreateBoard(false)} />
      </Modal>
    </Layout>
  );
};

const WrapperContent = styled(Content)<{ $isMobile: boolean }>`
  height: ${isMobile ? 'calc(100dvh - 48px)' : 'calc(100vh - 48px)'};
  overflow-y: auto;
  &::-webkit-scrollbar {
      width: 7px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9; /* color of the scroll thumb */
      border-radius: 20px; /
  }
    &::-webkit-scrollbar-track {
        background: transparent /* color of the tracking area */
    }
`;

export default PageLayout;
