import { CARD_URL, updateCard } from '../api';
import { useMutation, useQueryClient } from 'react-query';
import { Card, Card as CardType, UpdateCard } from '../type';
import { notification } from 'antd';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';
import { Board } from '../../Workspace/Board/types';
import { produce } from 'immer';

export const useCardUpdateOnce = (id?: string, card?: CardType) => {
  const queryClient = useQueryClient();
  const key = [CARD_URL, 'once', id!];
  const { boardId } = useParams();

  return useMutation(key, (values: UpdateCard) => updateCard(+id!, values), {
    onMutate: async (newData) => {
      await queryClient.cancelQueries([CARD_URL]);
      const prevValues = queryClient.getQueryData<Card>(key);
      queryClient.setQueryData<CardType>(
        key,
        // @ts-ignore
        (oldData) => ({
          ...(oldData ?? card),
          ...newData,
          fileList: oldData?.fileList ?? [],
        })
      );

      queryClient.setQueryData<Board>([BOARD_URL, boardId], (oldData) => {
        return produce(oldData!, (draft) => {
          const currentCard = draft.columnList
            .flatMap((col) => col.task)
            .find((card) => card.id === +id!)!;
          const currentColumn = draft.columnList.find(
            (col) => col.id === currentCard?.columnId
          )!;

          if (newData.archived === true) {
            currentColumn.task = currentColumn.task.filter(
              (card) => card.id !== prevValues?.id
            );
          } else {
            if (newData.archived === false) {
              const currentCard = queryClient.getQueryData<Card>(key)!;
              const currentColumn = draft.columnList.find(
                (col) => col.id === currentCard.columnId
              )!;
              currentColumn.task.splice(prevValues!.numberInColumn ?? 0, 0, {
                ...prevValues!,
                ...newData,
              });
            }
          }
        });
      });

      return prevValues;
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(key, () => data);
      if (variables.archived !== true)
        queryClient.setQueryData<Board>([BOARD_URL, boardId], (oldData) => {
          return produce(oldData!, (draft) => {
            const currentCard = draft.columnList
              .flatMap((col) => col.task)
              .find((card) => card.id === +id!)!;

            currentCard && Object.assign(currentCard, data);
          });
        });
    },
    onError: async (error, _, context) => {
      notification.error({ message: 'Ошибка обновления карточки' });
      context
        ? queryClient.setQueryData(key, () => context)
        : await queryClient.invalidateQueries(key);
      await queryClient.invalidateQueries([BOARD_URL, boardId]);
    },
  });
};
