import { useMutation, useQueryClient } from 'react-query';
import { CARD_URL, massArchiveCard } from '../../Card/api';
import { notification } from 'antd';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';
import { produce } from 'immer';
import { Board } from '../../Workspace/Board/types';

export const useMassArchiveMutation = () => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();

  return useMutation([CARD_URL, 'list'], massArchiveCard, {
    onMutate: async (columnId) => {
      await queryClient.cancelQueries([CARD_URL]);
      const prevValue = queryClient.getQueryData([BOARD_URL, boardId]);
      queryClient.setQueryData<Board>([BOARD_URL, boardId], (oldData) => {
        return produce(oldData!, (draft) => {
          const currentColumn = draft.columnList.find(
            (col) => col.id === columnId
          )!;
          currentColumn.task = [];
        });
      });
      return prevValue;
    },
    onError: (_, columnId, context: any) => {
      notification.error({ message: 'Ошибка архивирования' });
      queryClient.setQueryData([BOARD_URL, boardId], () => context);
    },
  });
};
