import { useState } from 'react';

export const useHelpHotkey = () => {
  const [openHelp, setOpenHelp] = useState(false);
  const onSlash = (evt: KeyboardEvent) => {
    if (evt.key === '?') {
      setOpenHelp(true);
    }
  };

  return { openHelp, setOpenHelp, onSlash };
};
