import API from '../../utils/API/API';
import { CreateStickerToTask } from './type';

export const STICKER_TO_TASK = 'api/sticker-to-task';
export const UNSPLASH = 'api/unsplash';

export const createStickerToTask = (value: CreateStickerToTask) =>
  API.post(STICKER_TO_TASK, value).then((res) => res.data.data);

export const deleteStickerToTask = (id: number) =>
  API.delete(`${STICKER_TO_TASK}/${id}`).then((res) => res.data.data);

export const getUnsplashRandomPhoto = (q: string, page: number) =>
  API.get(`${UNSPLASH}/search?q=${q}&page=${page}`).then(
    (res) => res.data.data
  );
