import { useQueryClient } from 'react-query';
import { Board } from '../../Workspace/Board/types';
import { BOARD_URL, updateBoard } from '../../Workspace/Board/api/BoardApi';
import { useGenericMutation } from '../../../utils/hooks/useGenericMutate';
import { BoardUpdate } from '../../Workspace/types';
import { useParams } from 'react-router-dom';

export const useUpdateBoard = () => {
  const queryClient = useQueryClient();
  const { boardId: id } = useParams();
  const board = queryClient.getQueryData<Board>([BOARD_URL, id!]);
  return useGenericMutation<Board, any>(
    (values: BoardUpdate) => updateBoard(board!.id, values),
    [BOARD_URL, id!],
    (oldData, newData) => ({ ...oldData, ...newData })
  );
};
