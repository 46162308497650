import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { useQuery } from 'react-query';
import ruRu from 'antd/es/locale/ru_RU';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { ReactQueryDevtools } from 'react-query/devtools';
import { KeycloakApp } from './utils/Keycloak/Keycloak';
import API from './utils/API/API';
import { loggedIn } from './store/reducers/AuthReducer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PageLayout from './components/Layout/PageLayout';
import { isFirefox } from 'react-device-detect';

dayjs.locale('ru');

/**
 * Точка входа в приложение
 * @constructor
 */
function App() {
  const dispatch = useDispatch();

  const [isAuth, setIsAuth] = useState(!!KeycloakApp?.authenticated);
  const navigate = useNavigate();
  const verify = async () => {
    try {
      const authenticated = await KeycloakApp.init({
        onLoad: 'login-required',
        responseMode: 'query',
        checkLoginIframe: !isFirefox,
      });
      if (authenticated) {
        setIsAuth(true);
      }
    } catch (error) {
      console.error('Failed to initialize adapter:', error);
    }
  };

  useEffect(() => {
    verify();
  }, []);

  const getUserData = () => {
    if (KeycloakApp.authenticated)
      API.get(`api/accounts/keycloak/${KeycloakApp.subject}`).then(
        (response) => {
          if (response.data.data.id) {
            dispatch(loggedIn(response.data.data));
          } else {
            navigate('/registry');
          }
        }
      );
  };

  const {} = useQuery(
    ['api/accounts/keycloak', KeycloakApp.subject],
    getUserData,
    {
      refetchOnWindowFocus: () => !location.pathname.includes('files'),
    }
  );

  return (
    <div className="App">
      <ConfigProvider locale={ruRu}>
        {isAuth && <PageLayout />}
        <ReactQueryDevtools initialIsOpen={false} />
      </ConfigProvider>
    </div>
  );
}

export default App;
