import { useMutation, useQueryClient } from 'react-query';
import { CommentTask } from '../type';
import { notification } from 'antd';
import { COMMENT_URL, createComment } from '../CommentApi';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';
import { Board } from '../../Workspace/Board/types';
import { produce } from 'immer';

export const useCreateCommentMutation = (queryKey: any[]) => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();

  return useMutation(queryKey, createComment, {
    onMutate: async (data) => {
      const commentToCard = queryClient.getQueryData<CommentTask[]>(queryKey);

      await queryClient.cancelQueries([COMMENT_URL]);
      queryClient.setQueryData<CommentTask[]>(
        queryKey,
        // @ts-ignore
        (oldData) => {
          if (oldData) {
            return [data, ...oldData];
          }
          return [];
        }
      );

      queryClient.setQueryData<Board>(
        [BOARD_URL, boardId],
        // @ts-ignore
        (oldData) => {
          if (oldData) {
            return produce(oldData, (draft) => {
              const currentCard = draft.columnList
                .flatMap((col) => col.task)
                .find((card) => card.id === data.taskId)!;

              currentCard.comments.push({ ...data, id: 0 });
            });
          }
          return [];
        }
      );

      return commentToCard;
    },
    onError: async (error, variables, context) => {
      notification.error({ message: 'Ошибка' });
      queryClient.setQueryData<CommentTask[]>(queryKey, () => context!);
      await queryClient.invalidateQueries([BOARD_URL, boardId]);
    },
    onSuccess: async (data) => {
      queryClient.setQueryData<CommentTask[]>(queryKey, (oldData) => [
        data,
        ...(oldData?.slice(1) ?? []),
      ]);
    },
  });
};
