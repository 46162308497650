import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const useGenericMutation = <T, S>(
  func: (data: S) => Promise<AxiosResponse<S>>,
  url: Array<any>,
  updater?: ((oldData: T, newData: S) => T) | undefined
) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, S>(func, {
    onMutate: async (data) => {
      await queryClient.cancelQueries(url);

      const previousData = queryClient.getQueryData(url);

      // @ts-ignore
      queryClient.setQueryData<T>(url, (oldData) => {
        return updater ? updater(oldData!, data) : data;
      });

      return previousData;
    },
    // Если мутация провалилась, использовать контекст, возвращенный `onMutate` для восстановления предыдущего состояния (отката - rollback)
    onError: (err, _, context) => {
      queryClient.setQueryData(url, context);
    },

    onSettled: async () => {
      await queryClient.invalidateQueries(url);
    },
  });
};
