import React, { useState } from 'react';
import { Button, Checkbox, DatePicker, Form, Select, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { Card } from '../type';
import dayjs from 'dayjs';
import { useCardUpdateOnce } from '../helpers/useCardUpdateOnce';

const DateForm = React.memo(
  ({ card, onCancel }: { card: Card; onCancel: Function }) => {
    const { id } = useParams();
    const [isStartDate, setIsStartDate] = useState(!!card.startDate);
    const dateMutate = useCardUpdateOnce(id);
    const onFinish = (values: {
      completeDate?: Date;
      startDate?: Date;
      dueReminder?: number;
    }) => {
      dateMutate
        .mutateAsync({
          completeDate: values.completeDate
            ? dayjs(values.completeDate).format()
            : undefined,
          startDate: values.startDate
            ? dayjs(values.startDate).format()
            : undefined,
          dueReminder: values.dueReminder,
        })
        .then(() => onCancel());
    };

    const optionReminder = [
      { label: 'За 5 минут', value: 5 },
      { label: 'За 10 минут', value: 10 },
      { label: 'За 30 минут', value: 30 },
      { label: 'За час', value: 60 },
      { label: 'За 2 часа', value: 120 },
      { label: 'За день', value: 1440 },
      { label: 'За 2 дня', value: 2880 },
    ];

    return (
      <>
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          Дата
        </Typography.Title>
        <Form onFinish={onFinish} layout={'vertical'}>
          <Checkbox
            checked={isStartDate}
            onChange={(e) => setIsStartDate(e.target.checked)}
            style={{ marginBottom: 16 }}
          >
            Установить дату начала
          </Checkbox>
          <Form.Item
            name={'startDate'}
            initialValue={card.startDate ? dayjs(card.startDate) : undefined}
            label={'Дата начала'}
          >
            <DatePicker
              disabled={!isStartDate}
              placeholder={'Введите дату начала'}
              format={'DD.MM.YYYY HH:mm'}
              showTime={{ defaultValue: dayjs('19:00', 'HH:mm') }}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: '' }]}
            name={'completeDate'}
            label={'Дата завершения'}
            initialValue={
              card.completeDate ? dayjs(card.completeDate) : undefined
            }
          >
            <DatePicker
              placeholder={'Введите дату завершения'}
              showTime={{ defaultValue: dayjs('19:00', 'HH:mm') }}
              format={'DD.MM.YYYY HH:mm'}
            />
          </Form.Item>
          <Form.Item
            name={'dueReminder'}
            label={'Установить напоминание'}
            initialValue={card.dueReminder ?? 2880}
          >
            <Select options={optionReminder} />
          </Form.Item>
          <Button
            type={'primary'}
            htmlType={'submit'}
            style={{ width: '100%' }}
            onClick={(e) => e.stopPropagation()}
          >
            Сохранить
          </Button>
        </Form>
      </>
    );
  }
);
DateForm.displayName = 'DateForm';
export default DateForm;
