import styled from 'styled-components';

interface CardViewWrapperProps {
  $backgroundImage: string | null;
  $isMobile: boolean;
  $backgroundColor: string | undefined;
  $tokenColorBgLayout: string;
}

const CardViewWrapper = styled.div<CardViewWrapperProps>`
  width: 100%;
  height: ${(props) => (props.$isMobile ? '50px' : '100px')};
  position: relative;
  background-image: ${(props) =>
    props.$backgroundImage ? `url(${props.$backgroundImage})` : ''};
  margin: auto auto 8;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${(props) =>
    props.$backgroundColor ?? props.$tokenColorBgLayout};
`;

export default CardViewWrapper;
