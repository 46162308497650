import API from '../../utils/API/API';

export const NOTIFICATION_URL = 'api/notification';

export const getNotificationList = (params: {
  page?: number;
  size?: number;
  isView?: boolean;
}) => API.get(NOTIFICATION_URL, { params }).then((res) => res.data.data);

export const editNotification = (values: {
  idList: number[];
  isView?: boolean;
}) => API.put(NOTIFICATION_URL, values);
