import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Button, Form, Input, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Column from '../../Column/Column';
import BoardLayout from '../../BoardLayout/BoardLayout';
import { useParams } from 'react-router-dom';
import { useCreateColumn } from './helpers/useCreateColumn';
import { isMobile } from 'react-device-detect';
import { BOARD_URL, getBoardById } from './api/BoardApi';
import { Board as BoardType } from './types';
import { useHelpHotkey } from './helpers/useHelpHotkey';
import Hotkeys from './components/Hotkeys';
import { useRole } from '../../../utils/helpers/useRole';

const Board = React.memo(() => {
  const { boardId: id } = useParams();
  const { data: boardData } = useQuery<BoardType>(
    [BOARD_URL, id!.toString()],
    () => getBoardById(id!)
  );

  const [isCreateColumn, setIsCreateColumn] = useState(false);
  const columMutation = useCreateColumn();
  const { openHelp, setOpenHelp, onSlash } = useHelpHotkey();
  const onCreateColumn = (value: { title: string }) => {
    setIsCreateColumn(false);
    columMutation.mutate({
      ...value,
      boardId: +id!,
      serialNumber: boardData!.columnList!.length + 1,
    });
  };

  useEffect(() => {
    document.addEventListener('keydown', onSlash);

    return () => document.removeEventListener('keydown', onSlash);
  }, [openHelp]);

  const onClickBoard = () => {
    if (isCreateColumn) setIsCreateColumn(false);
  };

  const columnList = boardData?.columnList;

  const role = useRole(boardData?.workplaceId ?? 0);

  return (
    <BoardLayout>
      <div
        onClick={onClickBoard}
        className={'board-content'}
        style={{
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        <Space align={'start'} style={{ padding: 20 }}>
          <Droppable
            droppableId="columnList"
            direction={'horizontal'}
            type={'COLUMN'}
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: 'flex',
                  width: 282 * (columnList?.length ?? 0) - 8,
                }}
              >
                {columnList?.map((column, index) => (
                  <Draggable
                    draggableId={column.id?.toString() + 'column'}
                    index={index}
                    key={column.id}
                  >
                    {(providedCol) => (
                      <div
                        {...providedCol.draggableProps}
                        {...providedCol.dragHandleProps}
                        ref={providedCol.innerRef}
                        style={{
                          ...providedCol.draggableProps.style,
                          height: 'min-content',
                        }}
                      >
                        <Column key={column.id} column={column} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {isCreateColumn ? (
            <Space style={{ marginLeft: 4, width: isMobile ? 280 : undefined }}>
              <Form
                onFinish={onCreateColumn}
                onClick={(e) => e.stopPropagation()}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Поле не может быть пустым',
                    },
                  ]}
                  name={'title'}
                >
                  <Input
                    onKeyDown={(e) => e.stopPropagation()}
                    placeholder={'Название' + ' колонки'}
                  />
                </Form.Item>
                <Button type={'primary'} htmlType={'submit'}>
                  Добавить
                </Button>
              </Form>
            </Space>
          ) : (
            role === 'lead' && (
              <Button
                style={{ marginLeft: 4 }}
                onClick={() => setIsCreateColumn(true)}
                loading={columMutation.isLoading}
              >
                <PlusOutlined /> Добавить колонку
              </Button>
            )
          )}
        </Space>
      </div>
      <Hotkeys openHelp={openHelp} setOpenHelp={setOpenHelp} />
    </BoardLayout>
  );
});
Board.displayName = 'Board';
export default Board;
