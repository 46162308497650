import React from 'react';
import { AvatarWrapper, getDateInfo } from '../Card';
import { Avatar, Space, Tag, theme, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import {
  CheckSquareOutlined,
  CommentOutlined,
  UserOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { Card } from '../type';
import TextArea from 'antd/es/input/TextArea';
import StickerElement from '../../BoardLayout/components/StickerElement';
import { isMobile } from 'react-device-detect';

const { useToken } = theme;

const SimpleCard = React.memo(
  ({
    card,
    setTitle,
    outerTitle,
  }: {
    card: Card;
    setTitle?: Function;
    outerTitle?: string;
  }) => {
    const { token } = useToken();

    const checkInfo =
      card?.checklist?.checklistItems &&
      `${
        card.checklist.checklistItems.filter((item) => item.isCompleted).length
      }/${card.checklist.checklistItems.length}`;

    return (
      <CardWrapper $bgColor={token.colorBgLayout}>
        {(card?.backgroundUrl ||
          card?.backgroundColor ||
          !!card?.stickerToTask?.length) && (
          <div
            style={{
              margin: 'auto',
              width: '100%',
              height: isMobile ? 50 : 100,
              position: 'relative',
              backgroundImage: card?.backgroundUrl ?? '',
              marginBottom: 8,
              overflow: 'hidden',
              borderRadius: '8px 8px 0 0',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundColor: card.backgroundColor ?? token.colorBgLayout,
            }}
          >
            {card?.stickerToTask?.map((stiker) => (
              <StickerElement
                key={stiker.id}
                sticker={stiker}
                columnId={card.columnId}
              />
            ))}
          </div>
        )}
        <div style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
          <Space size={'small'} wrap={true}>
            {card?.labelToTask?.map(({ label }) => (
              <Tag key={label.id} color={label.color} style={{ margin: 0 }}>
                {label.title}
              </Tag>
            ))}
          </Space>
          <span>
            <Typography.Text style={{ fontSize: '0.8em' }} type={'secondary'}>
              {card?.serialNumber}{' '}
            </Typography.Text>
            {setTitle ? (
              <TextArea
                rows={2}
                value={outerTitle}
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(e) => setTitle(e.target.value)}
                size={'small'}
                autoFocus
                placeholder={'Введите заголовок карточки'}
              />
            ) : (
              <Typography.Text strong>{card?.title}</Typography.Text>
            )}
          </span>
          <Space size={4}>
            {card?.completeDate && (
              <div>
                <Tooltip
                  title={getDateInfo(card.completeDate, card.isCompleted).text}
                >
                  <Tag
                    color={
                      getDateInfo(card.completeDate, card.isCompleted).color
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    {dayjs(card.completeDate).format('DD.MM.YYYY')}
                  </Tag>
                </Tooltip>
              </div>
            )}
            {!!card?.comments?.length && (
              <Tooltip title={'Комментарии к карточке'}>
                <Typography.Text type={'secondary'}>
                  <CommentOutlined /> {card.comments.length}
                </Typography.Text>
              </Tooltip>
            )}
            {!!card?.checklist?.checklistItems?.length && (
              <Tooltip title={'Статус подзадач'}>
                <Typography.Text type={'secondary'}>
                  <CheckSquareOutlined /> {checkInfo}
                </Typography.Text>
              </Tooltip>
            )}
          </Space>
          {!!card?.accountToTask?.length && (
            <AvatarWrapper>
              {card?.accountToTask.map(({ account }) => (
                <Avatar
                  key={account.id}
                  size={'small'}
                  icon={<UserOutlined />}
                  src={account.avatarUrl}
                />
              ))}
            </AvatarWrapper>
          )}
        </div>
      </CardWrapper>
    );
  }
);

export const CardWrapper = styled.div<{ $bgColor: string }>`
  background-color: ${(props) => props.$bgColor};
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  position: relative;
  box-shadow: 0 5px 5px -5px rgba(83, 108, 128, 0.6);
`;

SimpleCard.displayName = 'SimpleCard';
export default SimpleCard;
