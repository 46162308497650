import React from 'react';
import { Button, Modal, Popover, Space } from 'antd';
import { DeleteOutlined, GitlabOutlined } from '@ant-design/icons';
import Gitlab from './Gitlab';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteBoardMutation } from '../helpers/useDeleteBoardMutation';
import { useAppSelector } from '../../../store/rootReducer';
import { useQueryClient } from 'react-query';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { Board } from '../../Workspace/Board/types';

const Setting = React.memo(() => {
  const { boardId } = useParams();
  const [modal, contextHolder] = Modal.useModal();
  const { mutateAsync: deleteMutation } = useDeleteBoardMutation(boardId ?? '');
  const navigate = useNavigate();
  const { profile } = useAppSelector((state) => state.authReducer);
  const queryClient = useQueryClient();
  const boardData = queryClient.getQueryData<Board>([BOARD_URL, boardId]);

  const onDelete = () => {
    modal.confirm({
      title: 'Вы точно хотите удалить текущую доску?',
      content:
        'При удалении доски будут удалены все ее элементы, включая колонки и' +
        ' задачи, которые были привязаны к ней.',
      onOk: async () =>
        await deleteMutation(+boardId!).then(() =>
          navigate('/', { replace: true })
        ),
    });
  };

  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <Popover trigger={'click'} content={<Gitlab />} placement={'bottom'}>
        <Button type={'text'} block style={{ textAlign: 'left' }}>
          <GitlabOutlined />
          Привязать Gitlab
        </Button>
      </Popover>
      {profile?.id === boardData?.authorId && (
        <Button
          type={'text'}
          block
          danger
          style={{ textAlign: 'left' }}
          onClick={onDelete}
        >
          <DeleteOutlined />
          Удалить доску
        </Button>
      )}
      {contextHolder}
    </Space>
  );
});
Setting.displayName = 'Setting';
export default Setting;
