import { useMutation, useQueryClient } from 'react-query';
import { createFolder, EXPLORER_URL } from '../api/ExplorerApi';
import { ExplorerData } from '../type';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import { produce } from 'immer';
import { findObjectByRelativePath } from './findObjectByRelativePath';

export const useCreateFolder = (path: string) => {
  const mutationKey = [EXPLORER_URL, { path }];
  const queryClient = useQueryClient();

  return useMutation(mutationKey, createFolder, {
    onMutate: (variables) => {
      const prevValues = queryClient.getQueryData<ExplorerData>(mutationKey);
      const relativePath = variables.path.slice(path.length + 1);
      debugger;

      queryClient.setQueryData<ExplorerData>(
        mutationKey,
        // @ts-ignore
        (oldData) => {
          return produce(oldData!, (draft) => {
            const node = findObjectByRelativePath(draft!, relativePath || '.')!;
            node.children = [
              {
                name: variables.folderName,
                path: '',
                relativePath: `${relativePath ? relativePath + '/' : ''}${
                  variables.folderName
                }`,
                type: 'directory',
                isSymbolicLink: false,
                sizeInBytes: 0,
                size: '0 B',
              },
              ...(node.children ?? []),
            ];
          });
        }
      );

      return prevValues;
    },
    onError: async (error: AxiosError<any, any>, _, context) => {
      notification.error({ message: error.response?.data.message });
      queryClient.setQueryData(mutationKey, () => context);
      await queryClient.invalidateQueries(mutationKey);
    },
  });
};
