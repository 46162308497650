import React, { useState } from 'react';
import { Button, Form, notification } from 'antd';
import API from '../../utils/API/API';
import jwtDecode from 'jwt-decode';
import { KeycloakApp } from '../../utils/Keycloak/Keycloak';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loggedIn } from '../../store/reducers/AuthReducer';

const RegistryForm = React.memo(() => {
  const [isLoading, setIsLoading] = useState(false);
  const accountInfo = jwtDecode<any>(KeycloakApp.token!);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFinish = () => {
    setIsLoading(true);
    API.post('api/accounts', {
      email: accountInfo.email,
      personName: accountInfo.given_name,
      personSurname: accountInfo.family_name,
      login: accountInfo.preferred_username,
      keycloakId: accountInfo.sub,
    })
      .then((res) => {
        notification.success({ message: 'Регистрация прошла успешно' });
        dispatch(loggedIn(res.data.data));
        navigate('..');
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <Form onFinish={onFinish}>
      <Button htmlType={'submit'} type={'primary'} loading={isLoading}>
        Создать аккаунт используя id weldnet
      </Button>
    </Form>
  );
});
RegistryForm.displayName = 'RegistryForm';
export default RegistryForm;
