import React from 'react';
import { Card } from '../type';
import { Col, Row, Space, Tag, Typography } from 'antd';
import { GitlabOutlined, PullRequestOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const GitlabInfo = React.memo(({ card }: { card: Card }) => {
  const tagColor = {
    opened: 'blue',
    merged: 'green',
  };
  return (
    <div style={{ marginLeft: 24 }}>
      <Space direction={'vertical'} size={20} style={{ width: '100%' }}>
        {!!card.gitlabMergeRequestList.length && (
          <div>
            <Typography.Title level={5}>
              <PullRequestOutlined style={{ marginRight: 8 }} />
              Merge request
            </Typography.Title>
            <Space direction={'vertical'} style={{ width: '100%' }}>
              {card.gitlabMergeRequestList.map((branch) => {
                const parseBranch = JSON.parse(branch.description);
                const state: 'opened' | 'merged' =
                  parseBranch.object_attributes.state;
                return (
                  <Row key={branch.id} gutter={8}>
                    <Col span={20}>
                      <Link
                        to={parseBranch.object_attributes.url}
                        key={branch.id}
                        target={'_blank'}
                      >
                        {parseBranch.object_attributes.title}
                      </Link>
                    </Col>
                    <Col span={4}>
                      <Tag style={{ marginLeft: 16 }} color={tagColor[state]}>
                        {state}
                      </Tag>
                    </Col>
                  </Row>
                );
              })}
            </Space>
          </div>
        )}
        {!!card.gitlabCommit.length && (
          <div>
            <Typography.Title level={5}>
              <GitlabOutlined style={{ marginRight: 8 }} />
              Commits
            </Typography.Title>
            <Space direction={'vertical'}>
              {card.gitlabCommit.map((branch) => {
                const parseCommit = JSON.parse(branch.commit);
                return (
                  <div key={branch.id}>
                    <Link
                      to={parseCommit.url}
                      key={branch.id}
                      target={'_blank'}
                    >
                      {parseCommit.title}
                    </Link>
                  </div>
                );
              })}
            </Space>
          </div>
        )}
      </Space>
    </div>
  );
});
GitlabInfo.displayName = 'GitlabInfo';
export default GitlabInfo;
