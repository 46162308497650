import { useMutation, useQueryClient } from 'react-query';
import { addLabelToTask, CARD_URL } from '../api';
import { Card, Label } from '../type';
import { notification } from 'antd';
import { Board } from '../../Workspace/Board/types';
import { BOARD_URL } from '../../Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';
import { produce } from 'immer';
import { getCurrentColumnAndCard } from './getCurrentColumnAndCard';

export const useLabelToTaskCreateMutation = (
  queryKey: any[],
  queryKeyLabelList: any[]
) => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();

  return useMutation(queryKey, addLabelToTask, {
    onMutate: async (data) => {
      const labelList = queryClient.getQueryData<Label[]>(queryKeyLabelList);
      const label = labelList!.find((lab) => lab.id === data.labelId);

      const cardData = queryClient.getQueryData<Card>(queryKey);

      await queryClient.cancelQueries([CARD_URL]);
      queryClient.setQueryData<Card>(
        queryKey,
        // @ts-ignore
        (oldData) => {
          if (oldData) {
            return {
              ...oldData,
              labelToTask: [
                ...oldData.labelToTask,
                {
                  labelId: data.labelId,
                  taskId: data.taskId,
                  label: label,
                },
              ],
            };
          }
          return {};
        }
      );

      queryClient.setQueryData<Board>(
        [BOARD_URL, boardId],
        // @ts-ignore
        (oldData) => {
          return produce(oldData!, (draft) => {
            const { currentCard } = getCurrentColumnAndCard(draft, data.taskId);
            currentCard.labelToTask.push({
              labelId: data.labelId,
              taskId: data.taskId,
              label: label!,
              id: 0,
            });
          });
        }
      );

      return cardData;
    },
    onError: async (error, variables, context) => {
      notification.error({ message: 'Ошибка' });
      queryClient.setQueryData<Card>(queryKey, () => context!);
      await queryClient.invalidateQueries([BOARD_URL, boardId]);
    },
    onSuccess: async (data) => {
      queryClient.setQueryData<Card>(queryKey, (oldData) => ({
        ...oldData!,
        labelToTask: [...oldData!.labelToTask.slice(0, -1), ...data],
      }));
      queryClient.setQueryData<Board>(
        [BOARD_URL, boardId],
        // @ts-ignore
        (oldData) => {
          return produce(oldData!, (draft) => {
            const { currentCard } = getCurrentColumnAndCard(
              draft,
              data[0].taskId
            );
            currentCard.labelToTask.splice(-1);
            currentCard.labelToTask.push(...data);
          });
        }
      );
    },
  });
};
