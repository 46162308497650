import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import API from '../API/API';

export const useOnCustomSelect = <T,>(
  link: string,
  idName?: string,
  labelName?: string,
  initialValues?: T & { [prop: string]: any }
) => {
  const [dataList, setDataList] = useState<Array<T> | null>(null);
  const [optionList, setOptionList] = useState<
    Array<{ value: any; label: string }>
  >([]);

  useEffect(() => {
    if (initialValues && labelName && idName)
      setOptionList([
        { value: initialValues[idName], label: initialValues[labelName] },
      ]);
  }, []);

  const onSearch = (value: string) => {
    API.get(`${link}${value}`).then((res) => {
      const rawData: Array<T & { [prop: string]: any }> = res.data.data.content;
      setDataList(rawData);
      if (idName && labelName)
        setOptionList(
          rawData.map((data) => ({
            value: data[idName],
            label: data[labelName],
          }))
        );
    });
  };
  const searchHandler = debounce((value: string) => {
    if (value && value.length) {
      onSearch(value.trim());
    }
  }, 300);

  return { searchHandler, optionList, dataList };
};
