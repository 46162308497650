import React, { useEffect, useState } from 'react';
import { Image, Typography, Upload } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { downloadFile } from '../../Calendar/helpers/downloadFile';
import { FileItem } from '../../../../GlobalTypes';
import { deleteFile } from '../../../utils/helpers/deleteFile';
import { isMobile } from 'react-device-detect';
import { useGetWorkplaceId, useRole } from '../../../utils/helpers/useRole';

const FileList = React.memo(({ data }: { data: { fileList: FileItem[] } }) => {
  const reformatFileList = () =>
    data.fileList.map((file) => ({
      ...file,
      uid: file.id.toString(),
      name: file.fileName,
      url: `${process.env.REACT_APP_PRODUCTION_URL}/api/filesystem/${file.id}`,
    }));
  const [fileList, setFileList] = useState(reformatFileList());

  useEffect(() => {
    setFileList(reformatFileList());
  }, [data?.fileList]);

  const workplaceId = useGetWorkplaceId();
  const role = useRole(workplaceId ?? 0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const onPreview = (file: (typeof fileList)[number]) => {
    if (file.contentType.includes('image')) {
      setPreviewOpen(true);
      setPreviewImage(
        `${process.env.REACT_APP_PRODUCTION_URL}/${file.folder.replace(
          'files/',
          ''
        )}/${file.fileName}`
      );
    } else {
      downloadFile(file);
    }
  };

  return fileList.length ? (
    <div style={{ marginLeft: isMobile ? 0 : 24 }}>
      <Typography.Title level={5}>
        <PaperClipOutlined style={{ marginRight: 8 }} />
        Прикрепленные файлы
      </Typography.Title>
      <Upload
        onRemove={(file) => {
          deleteFile(file.uid).then(() =>
            setFileList(
              fileList.filter((fileItem) => file.uid !== fileItem.uid)
            )
          );
        }}
        showUploadList={{ showRemoveIcon: role !== 'user' }}
        // @ts-ignore
        onPreview={onPreview}
        fileList={fileList}
      />
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </div>
  ) : (
    <></>
  );
});
FileList.displayName = 'FileList';
export default FileList;
